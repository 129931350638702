const ButtonSecondary = ({ text, onClick, active }) => {
    return (
        <button
            style={{
                background: active && 'none' || 'gray',
                borderRadius: 4,
                color: active && '#ECD898' || 'white',
                width: '100%',
                height: 40,
                fontSize: 16,
                border: '2px solid #ECD898',
                cursor: 'pointer'
            }}
            onClick={() => onClick != null && onClick()}>
                {text}
        </button>
    )
}

ButtonSecondary.defaultProps = {
    active: true,
    onClick: null 
}

export default ButtonSecondary