import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FaCheck, FaPhone } from 'react-icons/fa'
import './reservation-confirmation-bottom.css'
import { useHistory } from 'react-router-dom'

const ReservationConfirmationBottom = ({onConfirmation, onCancel}) => {

    const history = useHistory()

    const oc = (value) => {
        // history.push("/reservation-confirmation-finish/" + value)
    }

    return (
        <div className="reservation-confirmation-bottom-container">
            <div className="reservation-confirmation-bottom-container-2" onClick={() => onCancel && onCancel() || oc("cancel")}>
                <div className="reservation-confirmation-bottom-cancel-container">
                    <AiOutlineCloseCircle className="reservation-confirmation-bottom-cancel"/>
                </div>
                <p className="reservation-confirmation-bottom-cancel-label">Tolak</p>
            </div>
            <div className="reservation-confirmation-bottom-container-2" onClick={() => onConfirmation && onConfirmation() || oc("accept")}>
                <div className="reservation-confirmation-bottom-accept-container">
                    <FaCheck className="reservation-confirmation-bottom-accept"/>
                </div>
                <p className="reservation-confirmation-bottom-accept-label">Terima</p>
            </div>
        </div>
    )
}

export default ReservationConfirmationBottom
