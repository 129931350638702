import { message, Popconfirm } from 'antd'
import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { FaWindowClose } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import './gallery-venue-row.css'

const GalleryMenuVenueRow = ({data, deleteData}) => {
    
    function cancel(e) {
        console.log(e);
    }

    return (
        <div className="gallery-venue-row-container">
            <Popconfirm
                title="Hapus data ini?"
                onConfirm={() => deleteData(data)}
                onCancel={cancel}
                okText="Hapus"
                cancelText="Tidak">
                <MdClose className="gallery-venue-row-close"/>
            </Popconfirm>
            <img className="gallery-venue-row-image" src={data.image}/>
        </div>
    )
}

export default GalleryMenuVenueRow
