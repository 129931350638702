import React, { useState, useEffect } from 'react'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import RowInfoEdit from '../../components/rowinfo/RowInfoEdit'
import './profile.css'
import { Link } from 'react-router-dom'
import { FaAward, FaChevronDown, FaChevronUp, FaStar } from 'react-icons/fa'
import { ImSpoonKnife } from 'react-icons/im'
import RowInfoTo from '../../components/rowinfo/RowInfoTo'
import { url, headerBearer, headerBearerJsonRequest, getJsonResponse, headerNoUrlEncodedBearer, ggAlert, ERROR } from '../../service/Service'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import ProductModal from '../../components/selectproductmodal/ProductModal'
import RowInfoDate from '../../components/rowinfo/RowInfoDate'
import RowInfoEditLocation from '../../components/rowinfo/RowInfoEditLocation'
import RowInfoGender from '../../components/rowinfo/RowInfoGender'
import RowInfoEditPassword from '../../components/rowinfo/RowInfoEditPassword'
import { PATH_FAQ } from '../../const/Pathname'

const ProfileUser = () => {

    const [isPersonalDataHidden, setPersonalDataHidden] = useState(false)

    const [userData, setUserData] = useState({})

    const [successGetProfile, setSuccessGetProfile] = useState(false)

    const history = useHistory()

    const [productLoading, setProductLoading] = useState(false)

    const [selectedProduct, setSelectedProduct] = useState(new Map())

    async function getUser() {
        setSuccessGetProfile(false)
        await fetch(url + "auth/user", {headers: headerBearer})
        .then(response => {
            return getJsonResponse(response)
        })
        .then(json => {
            setUserData(json.data)
            var d = new Map()
            json.data.productpilihan.map(function(data, index) {
                d.set(data.id_produk, {id: data.id_produk, name: data.nama})
            })
            setSelectedProduct(d)
            setSuccessGetProfile(true)
            console.log(json)
        })
        .catch(error => {
            console.log(error)
            setSuccessGetProfile(false)
        })
    }

    const logout = () => {
        localStorage.clear()
        history.push("/login")
    }

    const [isShowProductModal, setShowProductModal] = useState(false)

    const onProductCancel = () => {
        setShowProductModal(!isShowProductModal)
    }

    async function onProductOk(items) {
        setProductLoading(true)
        setSelectedProduct(items)
        var arr = []
        for(let [key, value] of items) {
            arr.push(
                {idproductpilihan : value.id + ""}
            )            
        }
        var data = {
            productpilihan: arr
        }

        const requestOptions = {
            method: 'POST',
            headers: headerBearerJsonRequest,
            body: JSON.stringify(data)
        }

        await fetch(url + "updateprodukpilihan", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                console.log(json)
            })
            .catch(error => {
                ggAlert("Error", error, ERROR)
            })
        setProductLoading(false)
        setShowProductModal(!isShowProductModal)
    }

    const showProductModal = () => {
        setShowProductModal(!isShowProductModal)
    }

    const gotoFAQ = () => {
        history.push(PATH_FAQ)
    }

    useEffect(() => {
        getUser()
    }, []);

    return (
        <div className="profile-container">
            <NavbarDetail
                title="PROFILE"/>
            <div className="profile-container-2">
                <div className="profile-container-red">
                    <p className="profile-name">{userData.name && userData.name || "-"}</p>
                    <p className="profile-level"></p>
                </div>

                <div className="profile-menu-container">
                    <Link to="/point-loyalty-history" className="profile-menu-container-2">
                        <FaStar className="profile-menu-icon"/>
                        <p className="profile-menu-name">Poin Loyalty</p>
                    </Link>
                    <Link className="profile-menu-container-2" to="/reservation-history">
                        <ImSpoonKnife className="profile-menu-icon"/>
                        <p className="profile-menu-name">Reservasi</p>
                    </Link>
                    <Link to="/point-community" className="profile-menu-container-2">
                        <FaAward className="profile-menu-icon"/>
                        <p className="profile-menu-name">Poin Komunitas</p>
                    </Link>
                </div>

                {
                    successGetProfile &&
                    <>
                        <div className="profile-personal-data-container">
                            <div className="profile-personal-data-title-container">
                                <p className="profile-personal-data-title">Data Pribadi</p>
                                {
                                    isPersonalDataHidden && 
                                    <FaChevronDown className="profile-personal-up-icon"
                                        onClick={() => setPersonalDataHidden(false)}/>
                                    ||
                                    <FaChevronUp className="profile-personal-up-icon"
                                        onClick={() => setPersonalDataHidden(true)}/>
                                }
                            </div>
                            {
                                isPersonalDataHidden
                                &&
                                <></>
                                || 
                                <div className="profile-personal-data-container-2">
                                    <RowInfoEdit
                                        keyRequest="name"
                                        label="Nama Lengkap"
                                        value={userData.name && userData.name || "-"}/>
                                    <div className="profile-line-2"/>
                                    <RowInfoDate
                                        keyRequest="dob"
                                        label="Tanggal Lahir"
                                        value={userData.dob && moment(userData.dob).format("DD MMM yyyy") || "-"}
                                        currentValueDateFormat={new Date(userData.dob)}/>
                                    <div className="profile-line-2"/>
                                    <RowInfoEdit
                                        keyRequest="nik"
                                        label="NIK"
                                        lengthValidation={16}
                                        value={userData.id_no && userData.id_no || "-"}/>
                                    <div className="profile-line-2"/>
                                    <RowInfoGender
                                        keyRequest="gender"
                                        label="Jenis Kelamin"
                                        value={
                                            userData.gender === "Male" && "Laki-laki" 
                                            || userData.gender === "Female" && "Perempuan" 
                                            || "-"
                                        }/>
                                    <div className="profile-line-2"/>
                                    <RowInfoEdit
                                        label="Alamat Email"
                                        isEditable={false}
                                        value={userData.email && userData.email || "-"}/>
                                    <div className="profile-line-2"/>
                                    <RowInfoEdit
                                        label="Nomor Telepon"
                                        isEditable={false}
                                        value={userData.mobile && userData.mobile || "-"}/>
                                    <div className="profile-line-2"/>
                                    <RowInfoEdit
                                        keyRequest="address"
                                        label="Alamat"
                                        value={userData.address && userData.address || "-"}/>
                                    <div className="profile-line-2"/>
                                    <RowInfoEditLocation
                                        keyRequest="lokasi"
                                        label="Lokasi"
                                        value={userData.city && userData.provinsi && userData.city + " - " + userData.provinsi || "-"}/>
                                    <div className="profile-line-2"/>
                                    <RowInfoEdit
                                        keyRequest="pekerjaan"
                                        label="Pekerjaan"
                                        value={userData.pekerjaan && userData.pekerjaan || "-"}/>
                                    <div className="profile-line-2"/>
                                    <RowInfoEdit
                                        keyRequest="Hobi"
                                        label="Hobi"
                                        value={userData.hobi && userData.hobi || "-"}/>
                                    <div className="profile-line-2"/>
                                </div>
                            }
                        </div>
                        <div className="profile-personal-data-container">
                            {/* <p className="profile-personal-data-title">Data GG</p> */}
                            <RowInfoTo
                                label="Produk Pilihan"
                                onClick={showProductModal}/>
                            <div className="profile-line-2"/>
                            <RowInfoEdit
                                label="Komunitas"
                                isEditable={false}/>
                            <div className="profile-line-2"/>
                        </div>
                        
                        <div className="profile-personal-data-container">
                            <p className="profile-personal-data-title">Lainnya</p>
                            <RowInfoEditPassword
                                keyRequest=""
                                label="Update Password"/>
                            <div className="profile-line-2"/>
                            <RowInfoTo
                                label="FAQ dan Bantuan"
                                onClick={() => gotoFAQ()}/>
                            <div className="profile-line-2"/>
                        </div>
                    </> 
                }
            </div>
            
            <div className="profile-button-container">
                <button className="profile-sign-out-button"
                    onClick={() => logout()}>Keluar</button>
            </div>
            {
                successGetProfile &&
                <ProductModal
                    isShowModal={isShowProductModal}
                    onCancel={() => onProductCancel()}
                    selectedItem={selectedProduct}
                    isLoading={productLoading}
                    onOk={(e) => onProductOk(e)}/>
            }
        </div>
    )
}

export default ProfileUser
