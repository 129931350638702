import { useHistory } from 'react-router-dom'

const ButtonPrev = ({ text }) => {

    const history = useHistory()

    return (
        <button
            onClick={() => history.goBack()}
            style={{
                background: 'none',
                borderRadius: 4,
                color: '#D4AF37',
                width: '100%',
                border: 0,
                fontWeight: 600,
                height: 40,
                cursor: 'pointer'
            }}>
                {text}
        </button>
    )
}

export default ButtonPrev
