import React, { useState } from 'react'
import './user-type.css'
import { FaUser, FaStore, FaChevronUp, FaChevronDown } from 'react-icons/fa'
import InputPrimary from '../input/InputPrimary'

const UserType = ( { gap, isCustomer, isShown, clicked } ) => {

    return (
        <div className="userTypeContainer" 
            style={{marginTop: gap}}>
            <div className="userTypeContainer2">
                <div className="userTypeLabelContainer"
                    onClick={() => {
                        clicked(!isShown)
                    }}>
                    {isCustomer && 
                        <FaUser className="userTypeIcon"/> ||
                        <FaStore className="userTypeIcon"/>
                    }
                    <p className="userTypeLabel">{
                        isCustomer && 
                        "Register as consumer" ||
                        "Register as merchant"
                    }</p>
                    {isShown && 
                        <FaChevronUp className="userTypeIcon"/> || 
                        <FaChevronDown className="userTypeIcon"/>
                    }
                </div>
                { isShown &&
                    <div className="userTypeInputContainer">
                        <InputPrimary
                            placeholder="Phone Number"
                            type="Number"/>
                    </div>
                }
            </div>
        </div>
    )
}

UserType.defaultProps = {
    isCustomer: false,
    gap: 0,
    s: false
}

export default UserType
