import React, { useState, useEffect } from 'react'
import { FaChevronRight, FaEdit, FaPencilAlt, FaPencilRuler, FaRegEdit } from 'react-icons/fa'
import { ERROR, getJsonResponse, ggAlert, headerBearer, headerNoUrlEncodedBearer, url } from '../../service/Service'
import InputPrimary from '../input/InputPrimary'
import './row-info.css'

const RowInfoEdit = ({ keyRequest, label, value, type, isEditable, lengthValidation }) => {

    const [isEditEnabled, setEditEnabled] = useState(false)
    const [defaultValue, setDefaultValue] = useState(value)

    const onChange = (e) => {
        setDefaultValue(e)
    }

    async function updateData() {
        if (lengthValidation && defaultValue.length != lengthValidation) {
            ggAlert("Error", "Mohon maaf No KTP belum sesuai, silahkan mengisi No KTP yg benar", ERROR)
            return
        }

        setEditEnabled(false)
        if (!keyRequest) return
        var formData = new FormData()
        formData.append(keyRequest, defaultValue)

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }

        await fetch(url + "account/setting", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                console.log(json)
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className="rowInfoContainer">
            <div className="rowInfoContainer2">
                <p className="rowInfoLabel">{label}</p>
                {
                    isEditEnabled 
                    && <input
                            className="rowInfoInputContainer" 
                            defaultValue={defaultValue} 
                            type={type}
                            onChange={e => onChange(e.target.value)}/>
                    || value && <p className="rowInfoValue">{defaultValue}</p>
                }
            </div>
            {
                !isEditEnabled && isEditable && <FaPencilAlt className="rowInfoIcon" onClick={() => setEditEnabled(true)}/>
                || isEditable && <button className="rowInfoButton" onClick={() => updateData()}>Simpan</button>
            }
        </div>
    )
}

RowInfoEdit.defaultProps = {
    type: "text",
    isEditable: true
}

export default RowInfoEdit
