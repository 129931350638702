import React from 'react'
import ButtonPrev from '../../components/ButtonPrev'
import ButtonPrimary from '../../components/ButtonPrimary'
import CategoryItem from '../../components/categoryitem/CategoryItem'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import './fill-data-2.css'
import { Link } from 'react-router-dom'

const FillData2 = () => {
    return (
        <div className="parent">
            <NavbarDetail
            title="FILL YOUR DATA (2/2)"/>
            <div className="container">
                <div className="fillData2Container">
                    <CategoryItem
                        datas={
                            [
                                { label: "Gudang Garam Signature Mild", isChecked: false},
                                { label: "Gudang Garam Signature", isChecked: false},
                                { label: "Gudang Garam", isChecked: false},
                                { label: "Teh Pucuk", isChecked: false},
                                { label: "Gula", isChecked: false}
                            ]
                    }/>
                    <div className="fillData2ButtonContainer">
                        <ButtonPrev
                            text="Previous"/>
                        <div className="fillData2ButtonSeparator"/>
                        <Link className="fillData2Button" to="/congratulation">
                            <ButtonPrimary
                                text="Finish"/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FillData2
