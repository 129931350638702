import React, { useState } from 'react'
import { FaCircle, FaCut, FaShapes, FaShoppingBag, FaStar } from 'react-icons/fa'
import { ImSpoonKnife } from 'react-icons/im'
import './notification-menu.css'

const NotificationMenuItem = ({ category, type, onSelected, selected, number }) => {

    return (
        <div className="notification-menu-item-container"
            onClick={() => onSelected(number)}>
            <div 
                className={
                    selected && "notification-menu-item-selected-container-2" 
                    || "notification-menu-item-container-2"
                }>
                {
                    type == "all" && <FaShapes className={selected && "notification-menu-item-selected-icon" || "notification-menu-item-icon"}/>
                    || type == "general" && <FaCircle className={selected && "notification-menu-item-selected-icon" || "notification-menu-item-icon"}/>
                    || type == "poin" && <FaStar className={selected && "notification-menu-item-selected-icon" || "notification-menu-item-icon"}/>
                    || type == "transaction" && <FaShoppingBag className={selected && "notification-menu-item-selected-icon" || "notification-menu-item-icon"}/>
                    || type == "restaurant" && <ImSpoonKnife className={selected && "notification-menu-item-selected-icon" || "notification-menu-item-icon"}/>
                    || type == "redeem" && <FaCut className={selected && "notification-menu-item-selected-icon" || "notification-menu-item-icon"}/>
                }
                <p className={selected && "notification-menu-item-selected-category" || "notification-menu-item-category"}>{category}</p>
            </div>
        </div>
    )
}

export default NotificationMenuItem
