import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import './merchant-history-row.css'
import { Link } from 'react-router-dom'
import { CHANGE_POINT, CHANGE_POINT_USER } from '../../const/Pathname'

const MerchantRow = ({data}) => {
    return (
        <Link to={
                {
                    pathname: CHANGE_POINT_USER,
                    state: {
                        data: data
                    }
                }
            } className="merchant-history-row-container">
            <div className="merchant-history-row-container-2">
                <div className="merchant-history-row-container-3">
                    <p className="merchant-history-row-name">{data.name}</p>
                    <p className="merchant-history-row-address">{data.address + ", " + data.kecamatan + ", " + data.kabupaten_kota}</p>
                    <p 
                        className={
                            data.isHistory &&
                            "merchant-history-row-point-disable"
                            ||
                            "merchant-history-row-point"
                        }>{data.point} poin</p>
                    <p className="merchant-history-row-description">{data.description}</p>
                </div>
                <FaChevronRight className="merchant-history-row-icon"/>
            </div>
            <div className="merchant-history-row-line"/>
        </Link>
    )
}

MerchantRow.defaultProps = {
    name: "",
    item: "",
    point: 0,
    date: "",
    isHistory: false
}

export default MerchantRow
