import React, { useState } from 'react'
import { FaArrowRight, FaChevronDown, FaChevronRight, FaChevronUp } from 'react-icons/fa'
import './faq-row.css'

const FAQRow = ({question, answer}) => {

    const [isShown, setShown] = useState(false)

    const onShowHide = () => {
        setShown(!isShown)
    }

    return (
        <>
            <div className="faq-row-container">
                <div className="faq-row-container-2">
                    <i className="faq-row-dot"/>
                    <p className="faq-row-text">{question}</p>
                    {
                        isShown &&
                        <FaChevronUp onClick={() => onShowHide()} className="faq-row-icon"/>
                        ||
                        <FaChevronDown onClick={() => onShowHide()} className="faq-row-icon"/>
                    }
                </div>
                <p className={isShown && "faq-row-answer" || "faq-row-answer-hide"}>
                    {answer}
                </p>
            </div>
            <div className="faq-line"/>
        </>
    )
}

export default FAQRow
