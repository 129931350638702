import React from 'react'
import ButtonPrimary from '../../components/ButtonPrimary'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import './promo-detail.css'
import { useParams, useHistory } from 'react-router-dom'
import { TYPE_HORECA_LIST, TYPE_EDIT, TYPE_HORECA_LIST_PROMO_RELATED } from '../../const/ParamType'
import { PATH_RESTAURANT_LIST } from '../../const/Pathname'

const PromoDetail = (props) => {

    const { type } = useParams()
    const history = useHistory()
    const {state} = props.location

    const onButtonClick = () => {
        if (type === TYPE_HORECA_LIST) {
            history.push(PATH_RESTAURANT_LIST + "/" + TYPE_HORECA_LIST_PROMO_RELATED)
        } else if (type === TYPE_EDIT) {
            // history.push("/promo")
        }
    }

    return (
        <div className="promo-detail-container">
            <NavbarDetail
                title="RINCIAN PROMO"/>

            <div className="promo-detail-container-2">
                <img className="promo-detail-image" src={state && state.data.image}/>

                <div className="promo-detail-content-container">
                    <p className="promo-detail-title">{state && state.data.title}</p>
                    <p className="promo-detail-expired">{state && state.data.time}</p>
                    <p className="promo-detail-message">{state && state.data.content}</p>
                </div>
            </div>

            {/* <div className="promo-detail-button-container">
                {
                    type === "detail" && <></>
                    ||
                    <ButtonPrimary
                    text={
                        type === TYPE_HORECA_LIST && "Lihat Merchant Terdekat"
                        || type === TYPE_EDIT && "Edit Promo" 
                        || "Lihat Penawaran Lainnya" 
                    }
                    onClick={() => onButtonClick()}/>
                }
            </div> */}
        </div>
    )
}

export default PromoDetail