import { message } from 'antd';
import React from 'react'
import { FaPlus } from 'react-icons/fa'
import GalleryMenuVenueRow from '../../../components/galleryvenuerow/GalleryMenuVenueRow'
import { ERROR, getJsonResponse, ggAlert, headerNoUrlEncodedBearer, url } from '../../../service/Service';
const MenuVenue = ({data, onDelete}) => {

    async function deleteData(e) {
        var formData = new FormData()
        
        formData.append("id_menu", e.id)

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }

        await fetch(url + "horeca/venue/menuhoreca/delete", requestOptions)
            .then(response => getJsonResponse(response))
            .then(json => {
                onDelete()
            })
            .catch(error => ggAlert("Error", error, ERROR))
    }

    return (
        <div className="gallery-venue-container">
            <div className="gallery-venue-container-2">
                {
                    data.map(function(d) {
                        return <GalleryMenuVenueRow
                                data={d}
                                deleteData={deleteData}/>
                    })
                }
            </div>
        </div>
    )
}

export default MenuVenue
