import { Spin } from 'antd'
import React, { useState } from 'react'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import ReservationConfirmationBottom from '../../components/reservationconfirmationbottom/ReservationConfirmationBottom'
import UserCard from '../../components/usercard/UserCard'
import { ERROR, getJsonResponse, ggAlert, headerNoUrlEncodedBearer, url } from '../../service/Service'
import './reservation-confirmation.css'
import { useHistory } from 'react-router-dom'
import ReservationRejectModal from '../../components/modal/ReservationRejectModal'

const ReservationConfirmation = (props) => {

    const {state} = props.location
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const [reasonModalShown, setReasonModalShown] = useState(false)

    async function updateReservation(status, message) {
        setLoading(true)
        var formData = new FormData()
        formData.append("id_reservasi", state.data.id)
        formData.append("status_reservasi", status)
        if (message) {
            formData.append("reason", message)
        }

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }
        await fetch(url + "horeca/konfirmasireservasi", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                var s = status == 2 && "cancel" || "accept"
                history.push({
                    pathname: "/reservation-confirmation-finish/" + s,
                    state: { data: state.data }
                })
            })
            .catch(error => {
                ggAlert("Error", error, ERROR)
            })
        setLoading(false)
    }

    const onConfirmation = () => {
        updateReservation(1, null)
    }

    const onCancel = () => {
        setReasonModalShown(true)
    }

    const onModalClose = ( ) => {
        setReasonModalShown(false)
    }

    const onRejectSubmitted = (e) => {
        setReasonModalShown(false)
        updateReservation(2, e)
    }
    
    return (
        <div className="gg-parent-container">
            <NavbarDetail
                title="RINCIAN RESERVASI"/>
            
            <div className="gg-content-container">
                <UserCard
                    data={{consumen: state && state.data && state.data.name, phone: state && state.data && state.data.phone}}/>

                <p className="reservation-confirmation-label">Pengajuan Reservasi</p>
                <p className="reservation-confirmation-date">{state && state.data && state.data.time}</p>
                <p className="reservation-confirmation-number">{state && state.data && state.data.number} pax</p>
            </div>

            {
                state && state.isActive &&
                <div className="gg-bottom-container">
                    {
                        loading && <Spin/>
                        ||
                        <ReservationConfirmationBottom
                            onConfirmation={() => onConfirmation()}
                            onCancel={() => onCancel()}/>
                    }
                </div>
            }

            <ReservationRejectModal
                isShowModal={reasonModalShown}
                onCancel={() => onModalClose()}
                onOk={(e) => onRejectSubmitted(e)}/>
        </div>
    )
}

export default ReservationConfirmation
