import React, { useState } from 'react'
import './category-item.css'

const CategoryItemCheckBox = ({ label, isChecked }) => {

    const [c, setChecked] = useState(isChecked)

    return (
        <div className="categoryItemCheckBoxContainer">
            <div className="categoryItemCheckBoxContainer2">
                <p className="categoryItemCheckBoxLabel">{label}</p>
                <input type="checkbox" className="categoryItemCheckBox"
                checked={c} onClick={() => setChecked(!c) }/>
            </div>
            <div className="categoryItemCheckBoxLine"/>
        </div>
    )
}

CategoryItemCheckBox.defaultProps = {
    label: 'No label',
    isChecked: false
}

export default CategoryItemCheckBox
