import React from 'react'
import { FaChevronRight, FaEdit, FaPencilAlt, FaPencilRuler, FaRegEdit } from 'react-icons/fa'
import './row-info.css'

const RowInfoTo = ({ label, value, onClick }) => {
    return (
        <div className="rowInfoContainer"
            onClick={() => onClick && onClick()}>
            <div className="rowInfoContainer2">
                <p className="rowInfoLabel">{label}</p>
                {value && <p className="rowInfoValue">{value}</p>}
            </div>
            <FaChevronRight className="rowInfoIcon"/>
        </div>
    )
}

export default RowInfoTo
