import React, { useEffect, useState } from 'react'
import { FaChevronRight, FaMapMarker } from 'react-icons/fa'
import HorizontalList from '../../components/horizontallist/HorizontalList'
import NavbarSearch from '../../components/navbarsearch/NavbarSearch'
import StoreList from '../../components/storelist/StoreList'
import './restaurant-list.css'
import { useParams, useHistory } from 'react-router-dom'
import PromoUse from '../../components/promo/promoused/PromoUse'
import { ERROR, getJsonResponse, ggAlert, headerBearer, headerNoUrlEncodedBearer, INFO, url } from '../../service/Service'
import { TYPE_HORECA_LIST, TYPE_HORECA_LIST_PROMO_RELATED } from '../../const/ParamType'
import { Pagination, Spin } from 'antd'
import EmptyPage from '../../components/emptypage/EmptyPage'
import OutletModal from '../../components/outletmodal/OutletModal'
import useGeolocation from '../../util/useGeolocation'

const RestaurantList = () => {

    const { type } = useParams()
    const history = useHistory()
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [currentLocation, setCurrentLocation] = useState({})
    const [isShowCurrentLocationModal, setShowCurrentLocationModal] = useState(false)
    const [cities, setCities] = useState([])
    const [citiesMap, setCitiesMap] = useState(new Map())
    const [packagePromo, setPackagePromo] = useState([])
    const location = useGeolocation()

    const onCurrentLocationCancel = () => {
        setShowCurrentLocationModal(!isShowCurrentLocationModal)
    }

    const onCurrentLocationOk = (items) => {
        console.log(citiesMap)
        setCurrentLocation({name: items, id: citiesMap.get(items)})
        setCurrentPage(1)
        getOutlet(currentPage, citiesMap.get(items))
        setShowCurrentLocationModal(!isShowCurrentLocationModal)
    }

    const showCurrentLocationModal = () => {
        setShowCurrentLocationModal(!isShowCurrentLocationModal)
    }

    const onSeeMoreClicked = () => {
        history.push({
            pathname: "/promo-all",
            state: {type: "package"}
        })
    }

    const onChange = page => {
        setCurrentPage(page)
        getOutlet(page, currentLocation.id)
      }

    const [items, setItems] = useState([])

    async function getOutletCity() {
        await fetch(url + "operatingunitoutlet", {headers: headerNoUrlEncodedBearer})
            .then(response => getJsonResponse(response))
            .then(json => {
                
                setCities([])

                var cm = new Map()

                json.data.map(function(d, i){
                    setCities(arr => [...arr, d.name])
                    if (!cm.has(d.name)) {
                        cm.set(d.name, d.id)
                    }
                })

                setCitiesMap(cm)


                if (location.loaded && location.coordinates && !currentLocation.id) {
                    getOutlet(currentPage, "")
                    ggAlert("Info", "Daftar restoran/cafe berdasarkan lokasi pada GPS anda", INFO)
                } else {
                    // getOutlet(currentPage, json['data'][0]['id'])
                    // setCurrentLocation(json['data'][0])
                    // alert("Daftar restoran/cafe ini berada di " + json['data'][0]['name'])
                    getOutlet(currentPage, localStorage.getItem("lokasiId"))
                    setCurrentLocation({name: localStorage.getItem("kota"), id: localStorage.getItem("lokasiId")})
                    ggAlert("Info", "Daftar restoran/cafe ini berada di " + localStorage.getItem("kota"), INFO)
                }
            })
            .catch(error => console.log(error))
    }

    async function getPackage() {
        await fetch(url + "paketpromo", {headers: headerNoUrlEncodedBearer})
            .then(response => getJsonResponse(response))
            .then(json => {
                setPackagePromo([])
                json['data']['cpo'].map(function(data) {
                    setPackagePromo(arr => [...arr, {
                        id: data.id,
                        image: data.image,
                        content: data.mekanisme_progam,
                        name: data.program_name,
                        start: data.start_program,
                        end: data.end_program
                    }])
                })

                console.log(packagePromo)
            })
            .catch(error => console.log(error))
    }

    async function getOutlet(page, id) {
        setLoading(true)
        await fetch(
            url + "Outlet/Horeca?page=" + page + "&idcity=" + id + "&latitude="+ location.coordinates.lat +"&longitude=" + location.coordinates.lng,
            {headers: headerBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setItems(json['data']['data'])
                setTotalPage(json['data']['last_page'] * 10)
            })
            .catch(error => console.log(error))
        setLoading(false)
    }

    async function searchOutlet(search) {
        if (search === "") {
            if (location.loaded && location.coordinates && !currentLocation.id) {
                getOutlet(currentPage, "")    
            } else {
                getOutlet(currentPage, currentLocation.id)
            }
            return
        }
        setLoading(true)
        await fetch(url + "Outlet/search/"+ search +"/Horeca", {headers: headerNoUrlEncodedBearer})
            .then(response => getJsonResponse(response))
            .then(json => {
                setItems(json['data']['data'])
                setTotalPage(json['data']['last_page'] * 10)
            })
            .catch(error => console.log(error))
        setLoading(false)
    }

    useEffect(() => {
        // setTimeout(() => {
        //     getOutletCity()
        //     getPackage()
        // }, 5000)
        getOutletCity()
        getPackage()
    }, [])

    return (
        <div className="restaurant-list-container">
            <NavbarSearch placeholder="Cari Restoran/Cafe..."
                onSearch={(e) => searchOutlet(e)}/>
            <div className="restaurant-list-location-container">
                <p className="restaurant-list-location-label">Lokasi Sekarang</p>
                <FaMapMarker className="restaurant-list-location-icon"/>
                <p className="restaurant-list-location" onClick={() => showCurrentLocationModal()}>{currentLocation.name && currentLocation.name || "Pilih Lokasi"}</p>
                <FaChevronRight className="restaurant-list-location-icon-2"/> 
            </div>

            <div className="restaurant-list-container-2">
                {
                    type === TYPE_HORECA_LIST_PROMO_RELATED &&
                    <PromoUse/>
                    ||
                    <HorizontalList
                        title="Paket"
                        data={packagePromo}
                        type={TYPE_HORECA_LIST}
                        onSeeMoreClicked={() => onSeeMoreClicked()}
                        isSeeMoreAvailable={true}/>
                }
                {
                    loading && <Spin/>
                    ||
                    !loading && items.length == 0 && <EmptyPage message="Tidak ada data restoran"/> ||
                    <StoreList
                        isWithImage={true}
                        items={items}/>
                }
            </div>
            <div className="restaurant-list-pagination-container">
                <Pagination 
                    current={currentPage} 
                    onChange={onChange} 
                    total={totalPage} />
            </div>

            <OutletModal
                    isShowModal={isShowCurrentLocationModal}
                    onCancel={() => onCurrentLocationCancel()}
                    selectedItem={new Map()}
                    city={cities}
                    onOk={(e) => onCurrentLocationOk(e)}/>
        </div>
    )
}

export default RestaurantList
