import React from 'react'
import { useHistory } from 'react-router-dom'
import { PATH_ARTICLE_ALL } from '../../const/Pathname'
import { ArticleRow } from './ArticleRow'
import './home-article.css'

const HomeArticle = ({datas}) => {

    const history = useHistory()

    const onSeeMoreClicked = () => {
        history.push(PATH_ARTICLE_ALL)
    }

    return (
        <div className="home-article-container">
            <div className="home-article-header-container">
                <p className="home-article-title">Artikel</p>
                <p className="home-article-more" onClick={() => onSeeMoreClicked()}>Lihat Semua</p>
            </div>
            <div className="home-article-content-container">
                {
                    datas.map(function(data, i){
                        if (i >= 5) return
                        return <ArticleRow
                                data={data}/>
                    })
                }
            </div>
        </div>
    )
}

export default HomeArticle
