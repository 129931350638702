import React, { useState } from 'react'
import './buy-package-menu.css'
import BuyPackageMenuItem from './BuyPackageMenuItem'

const BuyPackageMenu = ({onChangeType}) => {

    const [s1, setS1] = useState(true)
    const [s2, setS2] = useState(false)
    const [s3, setS3] = useState(false)
    const [s4, setS4] = useState(false)

    const onMenuClicked = ( index ) => {
        if (index == 0) {
            setS1(true)
            setS2(false)
            setS3(false)
            setS4(false)
        } else if (index == 1) {
            setS1(false)
            setS2(true)
            setS3(false)
            setS4(false)
        } else if (index == 2) {
            setS1(false)
            setS2(false)
            setS3(true)
            setS4(false)
        } else if (index == 3) {
            setS1(false)
            setS2(false)
            setS3(false)
            setS4(true)
        } 
        onChangeType(index)
    }

    return (
        <div className="buy-package-menu-container">
            <BuyPackageMenuItem category="Semua" type="all"
                onSelected={onMenuClicked}
                selected={s1}
                number={0}/>
            <BuyPackageMenuItem category="Reguler" type="reguler"
                onSelected={onMenuClicked}
                selected={s2}
                number={1}/>
            <BuyPackageMenuItem category="Loyalty" type="loyalty"
                onSelected={onMenuClicked}
                selected={s3}
                number={2}/>
            <BuyPackageMenuItem category="Loyalty" type="loyalty-2"
                onSelected={onMenuClicked}
                selected={s4}
                number={3}/>
        </div>
    )
}

export default BuyPackageMenu
