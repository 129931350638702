import React, { useState } from 'react'
import './notification-menu.css'
import NotificationMenuItem from './NotificationMenuItem'

const NotificationMenu = ({onChange}) => {

    const [selectedMenu, setSelectedMenu] = useState(-1)

    const onMenuClicked = ( index ) => {
        setSelectedMenu(index)
        onChange(index)
    }

    return (
        <div className="notification-menu-container">
            <NotificationMenuItem category="Semua" type="all"
                onSelected={onMenuClicked}
                selected={selectedMenu == -1}
                number={-1}/>
            <NotificationMenuItem category="Transaksi" type="transaction"
                onSelected={onMenuClicked}
                selected={selectedMenu == 2}
                number={2}/>
            <NotificationMenuItem category="Poin" type="poin"
                onSelected={onMenuClicked}
                selected={selectedMenu == 1}
                number={1}/>
            <NotificationMenuItem category="Redeem" type="redeem"
                onSelected={onMenuClicked}
                selected={selectedMenu == 0}
                number={0}/>
            <NotificationMenuItem category="Reservasi" type="restaurant"
                onSelected={onMenuClicked}
                selected={selectedMenu == 3}
                number={3}/>
        </div>
    )
}

export default NotificationMenu
