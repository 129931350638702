import { Checkbox, Modal, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { getJsonResponse, url } from '../../service/Service'
import ButtonPrimary from '../ButtonPrimary'
import ButtonSecondary from '../ButtonSecondary'
import './product-modal.css'

const ProductModal = ({ isShowModal, selectedItem, onCancel, onOk, isLoading }) => {

    const [datas, setData] = useState([])
    const [dataMap, setDataMap] = useState(new Map())
    const [checked, setChecked] = useState(new Map())

    useEffect(() => {
        getProduct()
    }, [])

    async function getProduct() {
        await fetch(url + "produkgg")
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setData(json['data'])
                var m = new Map()
                var dm = new Map()
                json['data'].map(function(d, index) {
                    if (selectedItem.has(d.id)) {
                        m.set(d.id, {id: d.id, name: d.name})
                    }
                    dm.set(d.id, {id: d.id, name: d.name})
                })
                setDataMap(dm)
                setChecked(m)
            })
            .catch(error => console.log(error))
    }

    const onItemSelected = e => {
        if (e.target.checked) {
            selectedItem.set(e.target.value, dataMap.get(e.target.value))
            var m = new Map()
            for(let [key, value] of checked) {
                m.set(key, value)
            }
            m.set(e.target.value, dataMap.get(e.target.value))
            setChecked(m)
        } else {
            if (selectedItem.has(e.target.value)) {
                selectedItem.delete(e.target.value)
                var m = new Map()
                for(let [key, value] of checked) {
                    m.set(key, value)
                }
                m.delete(e.target.value)
                setChecked(m)
            }
        }
    }

    return (
        <Modal 
            title="PRODUK PILIHAN" 
            visible={isShowModal} 
            onOk={() => onOk != null && onOk(selectedItem)} 
            onCancel={() => onCancel != null && onCancel()}
            footer={[
                <div className="select-product-modal-footer-container">
                    {
                        isLoading && <Spin/>
                        ||
                        <>
                            <ButtonSecondary
                                text="Kembali" 
                                key="back" 
                                onClick={() => onCancel != null && onCancel()}/>,
                            <div className="select-product-modal-button-separator"/>,
                            <ButtonPrimary 
                                text="Simpan"
                                key="submit" 
                                type="primary" 
                                onClick={() => onCancel != null && onOk(selectedItem)}/>
                        </>
                    }
                </div>
            ]}>
            <div className="select-product-modal-option-container">
                {
                    datas.map(function(data, i) {
                        return <>
                            <div className="select-product-modal-option"
                                key={i}>
                                <Checkbox onChange={onItemSelected} checked={checked.has(data.id)} value={data.id}>{data.name}</Checkbox>
                            </div>
                            <div className="select-product-modal-line"/>
                        </>
                    })
                }
                
            </div>
        </Modal>
    )
}

ProductModal.defaultProps = {
    isLoading: false
}

export default ProductModal
