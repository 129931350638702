import React from 'react'
import './horizontal-list.css'
import { Link } from 'react-router-dom'
import moment from 'moment'

const HorizontalList = ({ title, number, isSeeMoreAvailable, type, onSeeMoreClicked, data, isClickable, onPreview }) => {

    return (
        <div className="horizontal-list-container">
            <div className="horizontal-list-title-container">
                <p className="horizontal-list-title">{title}</p>
                {
                    isSeeMoreAvailable && <p className="horizontal-list-see-more" onClick={() => onSeeMoreClicked && onSeeMoreClicked()}>Lihat Semua</p>
                }
            </div>
            <div className="horizontal-list-container-2">
                <div className="horizontal-list-container-3">
                    {
                        data && data.length > 0 && data.map(function(d, index) {
                            if (index > 4) return
                            if (onPreview) {
                                return <div className="horizontal-list-item-container" onClick={() => onPreview(d.image)}>
                                            <img className="horizontal-list-item-image" src={d.image}/>
                                        </div>
                            } else {
                                return <Link 
                                            className="horizontal-list-item-container"
                                            to={{
                                                pathname: "/promo-detail/" + type,
                                                state: {data: {
                                                    image: d.image,
                                                    time: "Berlaku dari " + moment(d.start).format("DD MMM yyyy") + " sampai " + moment(d.end).format("DD MMM yyyy"),
                                                    title: d.name,
                                                    content: "",
                                                    id: d.id
                                                }}
                                            }}>
                                                <img className="horizontal-list-item-image" src={d.image}/>
                                        </Link>
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}

HorizontalList.defaultProps = {
    isSeeMoreAvailable: false,
    type: "view",
    data: [],
    isClickable: true
}

export default HorizontalList
