import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import './merchant-history-row.css'
import { Link, useHistory } from 'react-router-dom'

const MerchantHistoryRow = ({ name, address, point, date, isHistory }) => {

    const history = useHistory()

    const onPromoClicked = () => {
        if (isHistory) return
        history.push("/change-point")
    }

    return (
        <div onClick={() => onPromoClicked()} className="merchant-history-row-container">
            <div className="merchant-history-row-container-2">
                <div className="merchant-history-row-container-4">
                    <div className="merchant-history-row-name-container">
                        <p className="merchant-history-row-name">{name}</p>
                        <p className="merchant-history-row-date">{date}</p>
                    </div>
                    <p className="merchant-history-row-address">{address}</p>
                    <p 
                        className={
                            isHistory &&
                            "merchant-history-row-point-disable"
                            ||
                            "merchant-history-row-point"
                        }>{point} poin</p>
                </div>
            </div>
            <div className="merchant-history-row-line"/>
        </div>
    )
}

MerchantHistoryRow.defaultProps = {
    name: "",
    item: "",
    point: 0,
    date: "",
    isHistory: false
}

export default MerchantHistoryRow
