import React, { useEffect, useState } from 'react'
import NavHome from '../../components/navhome/NavHome'
import '@brainhubeu/react-carousel/lib/style.css'
import BottomMenu from '../../components/bottommenu/BottomMenu'
import 'antd/dist/antd.css'
import { Carousel, Spin, Tabs } from 'antd'
import HomeMerchantMenu from '../../components/homemerchantmenu/HomeMerchantMenu'
import HomeVideo from '../../components/homevideo/HomeVideo'
import HomeArticle from '../../components/homearticle/HomeArticle'
import { getJsonResponse, headerBearer, headerNoUrlEncodedBearer, url } from '../../service/Service'
import ReservationMerchantRow from '../../components/reservationmerchantrow/ReservationMerchantRow'
import PromoRow from '../../components/promorow/PromoRow'
import BuyPackageMenu from '../../components/buypackage/menu/BuyPackageMenu'
import moment from 'moment'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import EmptyPage from '../../components/emptypage/EmptyPage'

const PackageAndMerchandise = () => {
    
    const [itemPaket, setItemPaket] = useState([])
    const [itemLoyalty, setItemLoyalty] = useState([])
    const [itemLoyalty2, setItemLoyalty2] = useState([])
    const [itemType, setItemType] = useState(0)
    const [merchandise, setMerchandise] = useState([])
    const outlet = JSON.parse(localStorage.getItem("outlet"))

    const { TabPane } = Tabs;

    function callback(key) {
        console.log(key);
    }

    async function getAllProgram() {
        await fetch(url + "allprogram/" + outlet.id, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setItemPaket([])
                json['data']['cpo'].map(function(d, index){
                    var data = {
                        name: d.nama_paket, point: d.poin && d.poin || 0, alokasi_item: d.qty
                    }
                    setItemPaket(arr => [...arr, data])
                })

                setItemLoyalty([])
                json['data']['loyalty'].map(function(d, index){
                    var data = {
                        name: d.nama_paket, point: d.poin && d.poin || 0, alokasi_item: d.qty
                    }
                    setItemLoyalty(arr => [...arr, data])
                })

                setItemLoyalty2([])
                json['data']['loyalty_berjenjang'].map(function(d, index){
                    var data = {
                        name: d.nama_paket, point: d.poin && d.poin || 0, alokasi_item: d.qty
                    }
                    setItemLoyalty2(arr => [...arr, data])
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    async function getReward() {
        await fetch(url + "loyalty/merchandise/" + outlet.id, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                console.log(json)
                setMerchandise([])
                json['data'].map(function(d, index) {
                    var data = {
                        id: d.itemhadiah_id, 
                        name: d.nama_hadiah, 
                        point: d.pointhadiah, 
                        stock: d.qty && d.qty || 0, 
                        transactionRewardId: d.id,
                        assignOutletRewardId: d.mekanismeprogram_id,
                        image: d.image
                    }
                    setMerchandise(arr => [...arr, data])
                })
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getAllProgram()
        getReward()
    }, [])

    const [selected, setSelected] = useState(-1)

    const onChangeType = (index) => {
        setItemType(index)
    }

    return (
        <div className="home-merchant-container">
            <NavbarDetail
                title="Paket &amp; Merchandise"/>
                <Tabs defaultActiveKey="1" onChange={callback} className="package-merchandise-merchant-tab">
                    <TabPane tab="Paket" key="1">
                        <div className="homeTabPaneContainer">
                            <div className="buy-package-container-menu">
                                <BuyPackageMenu
                                    onChangeType={onChangeType}/>
                            </div>

                            {
                                itemPaket && itemPaket.length == 0 && itemLoyalty && itemLoyalty.length == 0 && itemLoyalty2 && itemLoyalty2.length == 0 && itemType == 0 &&
                                <EmptyPage
                                    message="Tidak ada data paket"/>
                            }
                            
                            {
                                itemPaket.length > 0 && itemType == 0 &&
                                <>
                                    <p className="buy-package-label">Reguler</p>
                                    {
                                        itemPaket.map(function(item, i) {
                                            return <>
                                                <div className="buy-package-point-container"
                                                    key={i}>
                                                    <div className="buy-package-point-container-2">
                                                        <p className="buy-package-point-label">{item.name}</p>
                                                    </div>
                                                    <p className="buy-package-stock-label">{item.alokasi_item} Stok</p>
                                                </div>
                                                <div className="buy-package-line"/>  
                                            </>
                                        })
                                    }
                                </>
                                ||
                                itemPaket.length > 0 && itemType == 1 &&
                                <>
                                    {
                                        itemPaket.map(function(item, i) {
                                            return <>
                                                <div className="buy-package-point-container"
                                                    key={i}>
                                                    <div className="buy-package-point-container-2">
                                                        <p className="buy-package-point-label">{item.name}</p>
                                                    </div>
                                                    <p className="buy-package-stock-label">{item.alokasi_item} Stok</p>
                                                </div>
                                                <div className="buy-package-line"/>  
                                            </>
                                        })
                                    }
                                </>   
                                ||
                                itemPaket && itemPaket.length == 0 && itemType == 1 &&
                                    <EmptyPage
                                        message="Tidak ada data paket"/>
                            }
                            {
                                itemLoyalty.length > 0 && itemType == 0 &&
                                <>
                                    <p className="buy-package-label">Paket Loyalty</p>
                                    {
                                        itemLoyalty.map(function(item, i) {
                                            return <>
                                                <div className="buy-package-point-container"
                                                    key={i}>
                                                    <div className="buy-package-point-container-2">
                                                        <p className="buy-package-point-label">{item.name}</p>
                                                        <p className="buy-package-point">+ {item.point} poin</p>
                                                    </div>
                                                </div>
                                                <div className="buy-package-line"/>  
                                            </>
                                        })
                                    }
                                </>
                                ||
                                itemLoyalty.length > 0 && itemType == 2 &&
                                <>
                                    {
                                        itemLoyalty.map(function(item, i) {
                                            return <>
                                                <div className="buy-package-point-container"
                                                    key={i}>
                                                    <div className="buy-package-point-container-2">
                                                        <p className="buy-package-point-label">{item.name}</p>
                                                        <p className="buy-package-point">+ {item.point} poin</p>
                                                    </div>
                                                </div>
                                                <div className="buy-package-line"/>  
                                            </>
                                        })
                                    }
                                </>
                                ||
                                itemLoyalty && itemLoyalty.length == 0 && itemType == 2 &&
                                    <EmptyPage
                                        message="Tidak ada data paket"/>
                            }

                            {
                                itemLoyalty2.length > 0 && itemType == 0 &&
                                <>
                                    <p className="buy-package-label">Paket Loyalty Berjenjang</p>
                                    {
                                        itemLoyalty2.map(function(item, i) {
                                            return <>
                                                <div className="buy-package-point-container"
                                                    key={i}>
                                                    <div className="buy-package-point-container-2">
                                                        <p className="buy-package-point-label">{item.name}</p>
                                                        <p className="buy-package-point">+ {item.point} poin</p>
                                                    </div>
                                                </div>
                                                <div className="buy-package-line"/>  
                                            </>
                                        })
                                    }
                                </>
                                ||
                                itemLoyalty2.length > 0 && itemType == 3 &&
                                <>
                                    {
                                        itemLoyalty2.map(function(item, i) {
                                            return <>
                                                <div className="buy-package-point-container"
                                                    key={i}>
                                                    <div className="buy-package-point-container-2">
                                                        <p className="buy-package-point-label">{item.name}</p>
                                                        <p className="buy-package-point">+ {item.point} poin</p>
                                                    </div>
                                                </div>
                                                <div className="buy-package-line"/>  
                                            </>
                                        })
                                    }
                                </>
                                ||
                                itemLoyalty2 && itemLoyalty2.length == 0 && itemType == 3 &&
                                    <EmptyPage
                                        message="Tidak ada data paket"/>
                            }
                        </div>
                    </TabPane>
                    <TabPane tab="Merchandise" key="2">
                        <div className="homeTabPaneContainer">
                            {
                                merchandise.length > 0 &&
                                <div className="change-point-item-container">
                                    {
                                        merchandise.map(function(item, i){
                                            return <div className="change-point-item-container-2"
                                                    key={i}>
                                                <img className="change-point-item-image" src={item.image && item.image || "../../logo192.png"}/>
                                                <p className="change-point-item-name">{item.name}</p>
                                                <p className="change-point-item-point">{item.point} poin</p>
                                                <p className="change-point-item-stock">{item.stock} item tersisa</p>
                                            </div>
                                        })
                                    }
                                </div>
                                ||
                                <EmptyPage
                                    message="Tidak ada data merchandise"/>
                            }
                        </div>
                    </TabPane>
                </Tabs>
        </div>
    )
}

export default PackageAndMerchandise
