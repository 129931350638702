import React, { useEffect, useState } from 'react'
import NavHome from '../../components/navhome/NavHome'
import '@brainhubeu/react-carousel/lib/style.css'
import './home.css'
import BottomMenu from '../../components/bottommenu/BottomMenu'
import 'antd/dist/antd.css'
import { Carousel, Spin } from 'antd'
import HomeMerchantMenu from '../../components/homemerchantmenu/HomeMerchantMenu'
import HomeVideo from '../../components/homevideo/HomeVideo'
import HomeArticle from '../../components/homearticle/HomeArticle'
import { getJsonResponse, headerBearer, url } from '../../service/Service'
import HomeMerchant from './HomeMerchant'
import HomeUser from './HomeUser'
import { messaging, tokennyo } from "../../service/firebase";

const Home = () => {

    async function storeFCMToken(token) {
        var formData = new FormData()
        formData.append("firebase_token", token)
        
        const requestOptions = {
            method: 'POST',
            body: formData
        }

        await fetch(url + "firebase/token/store", requestOptions)
            .then(response => console.log(response))
            .then(json => console.log(json))
            .catch(error => console.log(error))
    }

    if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("./firebase-messaging-sw.js")
          .then(function(registration) {
            console.log("Registration successful, scope is:", registration.scope);
            tokennyo(storeFCMToken)
          })
          .catch(function(err) {
            console.log("Service worker registration failed, error:", err);
          });
      }
    
      useEffect(() => {
        navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
      })

    useEffect(() => {
        console.log(localStorage.getItem("role"))
    })

    return (
        <>
            {
                localStorage.getItem("role") === "user" && <HomeUser />
                ||
                localStorage.getItem("role") === "useroutlet" && <HomeMerchant/>
            }
        </>
    )
}

export default Home
