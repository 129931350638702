import { Spin } from 'antd'
import React, { useState, useEffect } from 'react'
import { FaChevronRight, FaEdit, FaPencilAlt, FaPencilRuler, FaRegEdit } from 'react-icons/fa'
import { getJsonResponse, headerBearer, headerNoUrlEncodedBearer, url } from '../../service/Service'
import InputPrimary from '../input/InputPrimary'
import './row-info.css'

const RowInfoEditPassword = ({ keyRequest, label, value, type, isEditable }) => {

    const [isEditEnabled, setEditEnabled] = useState(false)
    const [defaultValue, setDefaultValue] = useState(value)
    const [passwordOld, setPasswordOld] = useState("")
    const [passwordNew, setPasswordNew] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const [loading, setLoading] = useState(false)

    const onChangePasswordOld = (e) => {
        setPasswordOld(e)
    }

    const onChangePasswordNew =(e) => {
        setPasswordNew(e)
    }

    const onChangePasswordConfirmation =(e) => {
        setPasswordConfirmation(e)
    }

    async function updatePassword() {
        setLoading(true)
        if (passwordNew != passwordConfirmation) {
            alert("Password Baru dan Konfirmasi Password Baru anda tidak sama!")
            return
        }

        var formData = new FormData()
        
        formData.append("password", passwordNew)
        formData.append("password_confirmation", passwordConfirmation)
        formData.append("old_password", passwordOld)

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }

        await fetch(url + "change/password", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                alert("Ubah password berhasil")
                setEditEnabled(false)
            })
            .catch(error => {
                alert(error)
            })
        
        setLoading(false)
    }

    return (
        <div className="rowInfoContainer">
            <div className="rowInfoContainer2">
                <p className="rowInfoLabel">{label}</p>
                {
                    isEditEnabled 
                    && <>
                    <div style={{height: 10, width: '100%'}}/>
                    <InputPrimary
                        placeholder="Password Baru"
                        type="password"
                        isProfile={true}
                        onChange={(e) => onChangePasswordNew(e)}/>
                    <div style={{height: 10, width: '100%'}}/>
                    <InputPrimary
                        placeholder="Konfirmasi Password Baru"
                        type="password"
                        isProfile={true}
                        onChange={(e) => onChangePasswordConfirmation(e)}/>
                    <div style={{height: 10, width: '100%'}}/>
                    <InputPrimary
                        placeholder="Password Lama"
                        type="password"
                        isProfile={true}
                        onChange={(e) => onChangePasswordOld(e)}/>
                    </>
                    || value && <p className="rowInfoValue">{defaultValue}</p>
                }
            </div>
            {
                !isEditEnabled && isEditable && <FaPencilAlt className="rowInfoIcon" onClick={() => setEditEnabled(true)}/>
                || isEditable && !loading && <button className="rowInfoButton" onClick={() => updatePassword()}>Simpan</button>
                || loading && <Spin/>
            }
        </div>
    )
}

RowInfoEditPassword.defaultProps = {
    type: "text",
    isEditable: true
}

export default RowInfoEditPassword
