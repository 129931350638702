import { useState } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import './input-primary.css'

const InputPrimary = ({ placeholder, type, gap, onChange, value, isProfile }) => {

    const [isShownPassword, setShownPassword] = useState(false)
    const [resetType, setType] = useState("password")

    const showHidePassword = () => {
        setShownPassword(!isShownPassword)

        if (isShownPassword) {
            setType("text")
        } else {
            setType("password")
        }
    }

    return (
        <div className="inputPrimaryContainer">
            <div className={isProfile && 'inputPrimaryContainerForProfile2' || 'inputPrimaryContainer2'}
            style={{marginTop: gap}}>
                <input
                type={
                    type === "password" 
                    && isShownPassword 
                    && "text"
                    ||
                    type === "password" 
                    && !isShownPassword 
                    && "password" || type}
                className='inputPrimary'
                placeholder=" "
                value={value}
                onChange={event => onChange != null && onChange(event.target.value)}/>
                <span className='inputPlaceholder'>{placeholder}</span>
            </div>
            {
                type === 'password' && isShownPassword &&
                <AiFillEye className="inputPrimaryPasswordIcon"
                onClick={() => showHidePassword()}/>
                || type === 'password' && !isShownPassword &&
                <AiFillEyeInvisible className="inputPrimaryPasswordIcon"
                onClick={() => showHidePassword()}/>
                ||
                <></>
            }
        </div>
    )
}

InputPrimary.defaultProps = {
    placeholder: '',
    type: 'text',
    gap: 0,
    isProfile: false
}

export default InputPrimary
