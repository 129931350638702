import React from 'react'
import './time-number.css'
import moment from 'moment'

const TimeNumber = ({data, isActive}) => {
    return (
        <div className="time-number-container">
            <p className={isActive && "time-number-time" || "time-number-time-disable"}>{moment(data.start_program).format("DD MMM yyyy") + " - " + data.jam_reservasi}</p>
            <p className={isActive && "time-number-number" || "time-number-number-disable"}>{data.jumlah_orang} pax</p>
        </div>
    )
}

TimeNumber.defaultProps = {
    isActive: true,
    data: {
        jumlah_orang: 0,
        jam_reservasi: "",
        start_program: ""
    }
}

export default TimeNumber
