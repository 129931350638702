import React, { useState } from 'react'
import ButtonPrimary from '../../components/ButtonPrimary'
import InputPrimary from '../../components/input/InputPrimary'
import './login.css'
import { Link } from 'react-router-dom'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import ButtonSecondary from '../../components/ButtonSecondary'
import { useHistory } from 'react-router-dom'
import { Spin } from 'antd'
import service, { ERROR, getJsonResponse, ggAlert } from '../../service/Service'
import { url } from '../../service/Service'
import { getErrorMessage } from '../../service/ErrorHandling'

const Login = () => {

    const history = useHistory()
    const [loading, setLoading] = useState(false)

    var [emailOrPhone, setEmailOrPhone] = useState("")
    var [password, setPassword] = useState("")

    async function onLogin() {
        const endpoint = "login"
        
        const body = {
            emailOrPhone: emailOrPhone,
            password: password
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        }

        setLoading(true)
        await fetch(url + endpoint, requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                localStorage.setItem('accessToken', json['data']['access_token'])
                localStorage.setItem('userId', json['data']['id'])
                localStorage.setItem('role', json['data']['role'])
                if (json['data']['role'] === "useroutlet") {
                    localStorage.setItem('outlet', JSON.stringify(json['data']['outlet']))
                    localStorage.setItem('merchantType', json['data']['outlet']['tipe'])
                } else {
                    localStorage.setItem("phone", json['data']['phone'])
                    localStorage.setItem("lokasiId", json['data']['lokasi'])
                    localStorage.setItem("kota", json['data']['city'])
                }
                localStorage.setItem("produkPilihan", json['produkpilihan'])
                console.log(json)
                if (json['data']['role'] === "useroutlet") {
                    window.location.href="/profile"
                } else {
                    window.location.href="/home"
                }
            })
            .catch(error => {
                ggAlert("Error", error, ERROR)
            })
        setLoading(false)
    }

    return (
        <div className='loginContainerStyling'>
            <NavbarDetail
                title="LOG IN"
                useBackButton={false}/>
            <div className='loginContentContainerStyling'>
                <div className='separatorStyling'/>
                <p className='loginLabelStyling'>Silahkan masukkan nomor telepon dan password anda untuk Log In</p>
                <div className='separatorStyling'/>
                <InputPrimary placeholder='Email / Phone Number' type='text' 
                    onChange={(e) => setEmailOrPhone(e)}/>
                <div className='separatorStyling'/>
                <InputPrimary placeholder='Password' type='password'
                    onChange={(e) => setPassword(e)}/>
                <div className='separatorStyling'/>
                <div className='loginForgotContainerStyling'>
                    <Link to='/forgot-password' className='loginForgotTextStyling'><a className='loginForgotTextStyling'>Lupa Kata Sandi?</a></Link>
                </div>
                <div className='loginRemainingSpaceStyling'>
                    {
                        loading && 
                        <Spin />
                        ||
                        <div onClick={() => onLogin()}>
                            <ButtonPrimary text='Masuk'/>
                        </div>
                    }
                    <div style={{width: '100%', height: 30}}/>
                    <p className='loginRegisterLabelStyling'>Belum punya akun?</p>
                    <div style={{width: '100%', height: 30}}/>
                    <Link to="/register" className="loginRegisterTextStyling"><ButtonSecondary text="Daftar Baru"/></Link>
                    {/* <p className="loginRegisterLabelOperate">By PT. ARENA MADYA WISESA</p> */}
                    <div style={{width: '100%', height: 40}}/>
                </div>
            </div>
        </div>
    )
}

export default Login
