import React from 'react'
import { FaDirections, FaPhone } from 'react-icons/fa'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import TimeNumber from '../../components/timenumber/TimeNumber'
import { useParams } from 'react-router-dom'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react'
import useGeolocation from '../../util/useGeolocation'
import { GOOGLE_MAP } from '../../const/ApiKey'
import { ERROR, ggAlert, INFO } from '../../service/Service'

const ReservationDetail = (props) => {

    const {state} = props.location
    const { type } = useParams()
    
    const location = useGeolocation()
    
    const openGMapDirection = () => {
        if (location.loaded && state && state.data && state.data.data) {
            console.log(JSON.stringify(location))
            if (location.coordinates) {
                window.open("http://maps.google.com/maps?saddr="+location.coordinates.lat+","+ location.coordinates.lng +"&daddr=" + state.data.data.latitude + ","+ state.data.data.longitude)
            } else {
                ggAlert("Info", "Tidak dapat menemukan lokasi anda, mohon menyalakan GPS dan memberikan akses untuk layanan ini.", INFO)
            }
        } else {
            ggAlert("Info", "Tidak dapat menemukan lokasi anda, mohon menyalakan GPS dan memberikan akses untuk layanan ini.", INFO)
        }
    }

    return (
        <div className="gg-parent-container">
            <NavbarDetail
                title="RINCIAN RESERVASI"/>

            <div className={type === "active" && "gg-content-with-bottom-container" || "gg-content-no-top-padding-container"}>
                {
                    state && state.data && state.data.data &&
                    <Map google={props.google} zoom={14}
                        containerStyle={{height: "70vw", position: 'relative'}}
                        style={{height: "70vw", position: 'relative'}}
                        center={{lat: parseFloat(state.data.data.latitude), lng: parseFloat(state.data.data.longitude)}}
                        >
                        <Marker
                            title={''}
                            name={state.data.data.outlet_name}
                            position={{lat: parseFloat(state.data.data.latitude), lng: parseFloat(state.data.data.longitude)}} />
                    </Map>
                }

                <div className="merchant-detail-location-container">
                    <div className="merchant-detail-location-container-2">
                        <p className="merchant-detail-location-name">{state && state.data && state.data.data.outlet_name}</p>
                        <p className="merchant-detail-location">{state && state.data && state.data.data.address}</p>
                    </div>
                    <FaDirections className="merchant-detail-location-icon"
                        onClick={() => openGMapDirection()}/>
                </div>
                <div style={{paddingLeft: '20px', paddingRight: '20px', width: '100%'}}>
                    <div className="gg-line"/>
                </div>

                <TimeNumber
                    isActive={type === "active" && true || false}
                    data={state && state.data && state.data.data && state.data.data}/>

                {/* <div style={{paddingLeft: '20px', paddingRight: '20px', width: '100%'}}>
                    <div className="gg-line"/>
                </div> */}

                {/* {
                    type === "active" &&
                    <HorizontalList
                        title="Menu"
                        type="menu"
                        number={8}/>
                } */}
            </div>
            {
                type === "active" &&
                <div className="gg-bottom-container">
                    <div className="merchant-menu-item-container"
                        onClick={() => window.open("tel:" + state.data.data.phone_merchant)}>
                        <div className="merchant-menu-item-icon-container">
                            <FaPhone className="merchant-menu-item-icon"/>
                        </div>
                        <p className="merchant-menu-item-label">Hubungi</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default GoogleApiWrapper({
    apiKey: (GOOGLE_MAP)
  })(ReservationDetail)
