import React, { useState } from 'react'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import './scan-qr.css'
import QrReader from 'react-qr-reader'
import { useHistory } from 'react-router-dom'
import ButtonPrimary from '../../components/ButtonPrimary'
import { useParams } from 'react-router-dom'
import { AiOutlineClose } from 'react-icons/ai'
import ButtonSecondary from '../../components/ButtonSecondary'
import InputPrimary from '../../components/input/InputPrimary'
import { ERROR, getJsonResponse, ggAlert, headerBearer, headerNoUrlEncodedBearer, url } from '../../service/Service'
import { Spin } from 'antd'

const ScanQR = (props) => {

    const {type} = useParams()
    const {state} = props.location

    const history = useHistory()
    const [inputManual, setInputManual] = useState("")
    const [scanResult, setScanResult] = useState({ result: "No Result" })
    const [isBottomSheetShown, setBottomShetShown] = useState(false)
    const [isScanEnabled, setScanEnabled] = useState(true)

    async function checkQRCode(data) {
        if (data === null || data === '') return
        setScanEnabled(false)
        var endpoint = ""
        var formData = new FormData()
        if (type === 'buy-package') {
            formData.append("codeoutlet", data)
            formData.append("idoutlet", state.id)
            endpoint = "scan/outlet"
        } else {
            if (type === 'user') {
                formData.append("codeoutlet", data)
                formData.append("type", "scanhome")
                endpoint = "scan/homeconsumer"
            } else {
                formData.append("mobile", data)
                endpoint = "transactionmerchant/scanfromhomemerchant"
            }
        }
        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }

        await fetch(url + endpoint, requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setScanEnabled(true)
                console.log(json)
                var d = {}

                console.log(type)

                if (type === 'useroutlet') {
                    var outletData = JSON.parse(localStorage.getItem("outlet"))
                    d = {
                        id: outletData['id'],
                        address: outletData['address'] + outletData['kecamatan'] + outletData['kabupaten_kota'],
                        name: outletData['outlet_name'],
                        userId: json['data']['id'],
                        type: "useroutlet"
                    }
                } else {
                    d = { 
                        id: json['data']['id'],
                        address: json['data']['address'] + json['data']['kecamatan'] + json['data']['kabupaten_kota'],
                        name: json['data']['outlet_name'],
                        type: "user" 
                    }
                }

                history.push({
                    pathname: "/buy-package",
                    state: d
                })
            })
            .catch(error => {
                setScanEnabled(true)
                ggAlert("Error", error, ERROR)
            })
    }
    
    const handleScan = (data) => {
        if (!isScanEnabled) return
        checkQRCode(data)
    }

    const inputManualConfirm = () => {
        checkQRCode(inputManual)
    }

    const handleError = (err) => {
        console.error(err)
    }
    const previewStyle = {
        height: '100%',
        width: '100%',
        background: 'none'
    }

    const bottomSheetClose = () => {
        setBottomShetShown(false)
    }

    const bottomSheetShown = () => {
        setBottomShetShown(true)
    }

    return (
        <div className="gg-parent-container">
            <NavbarDetail
                title="SCAN QR"/>

            <div className="gg-content-no-top-padding-container">
                {
                    isScanEnabled
                    &&
                    <QrReader
                        delay={300}
                        style={previewStyle}
                        onError={handleError}
                        onScan={handleScan}/>
                    ||
                    <Spin/>
                }
            </div>

            <div className="gg-bottom-container">
                <ButtonPrimary
                    text={
                        type === "user" && "Input Kode Manual" || type === "buy-package" && "Input Kode Manual" || "Input Nomor Telepon"
                    }
                    onClick={() => bottomSheetShown()}/>
            </div>

            <div className={isBottomSheetShown && "gg-bottom-sheet-container" || "gg-bottom-sheet-container-hide"}>
                <div className="gg-bottom-sheet-header">
                    <AiOutlineClose className="gg-bottom-sheet-header-close-icon"
                        onClick={() => bottomSheetClose()}/>
                    <p className="gg-bottom-sheet-header-label">{
                        type === "user" && "Input Kode Manual" || type === "buy-package" && "Input Kode Manual" || "INPUT NOMOR TELEPON"
                    }</p>
                </div>

                <div className="gg-line"/>

                <div className="gg-bottom-sheet-content">
                    <InputPrimary
                        type={
                            type === "user" && "text" || type === "buy-package" && "text" || "number"
                        }
                        placeholder={
                            type === "user" && "Input Kode Manual" || type === "buy-package" && "Input Kode Manual" || "Nomor Telepon"
                        }
                        onChange={(e) => setInputManual(e)}/>
                </div>

                <div className="gg-bottom-sheet-footer">
                    {
                        isScanEnabled
                        &&
                        <>
                        <ButtonSecondary
                            text="Batal"
                            onClick={() => bottomSheetClose()}/>
                        <div className="gg-bottom-sheet-footer-separator"/>
                        <ButtonPrimary
                            text="Konfirmasi"
                            onClick={() => inputManualConfirm()}/>
                        </>
                        ||
                        <Spin/>
                    }
                </div>
            </div>
        </div>
    )
}

export default ScanQR
