import { Spin, Switch, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import EmptyPage from '../../components/emptypage/EmptyPage'
import MerchantHistoryRow from '../../components/merchanthistoryrow/MerchantHistoryRow'
import MerchantRow from '../../components/merchanthistoryrow/MerchantRow'
import NavbarSearchTab from '../../components/navbarsearch/NavbarSearchTab'
import { getJsonResponse, headerNoUrlEncodedBearer, url } from '../../service/Service'
import './point-loyalty-history.css'

const PointLoyaltyHistory = () => {

    const { TabPane } = Tabs;
    const [tabPosition, setTabPosition] = useState(0)
    const [searchKeyword, setSearhKeyword] = useState(["", ""])
    const [loading, setLoading] = useState([false, false])

    function callback(key) {
        setTabPosition(parseInt(key, 10) - 1)
    }

    const [merchantActive, setMerchantActive] = useState()
    const [merchantDeactive, setMerchantDeactive] = useState()

    async function getMerchantList(keyword) {
        setLoadingByTab(true)
        var endpoint = "loyalty/merchantlist"
        if (keyword) {
            endpoint = endpoint + "?search=" + keyword
        }
        await fetch(url + endpoint, {headers: headerNoUrlEncodedBearer})
        .then(response => {
            return getJsonResponse(response)
        })
        .then(json => {
            setMerchantActive([])
            json['data'].map(function(data, index){
                var d = { 
                    item_id: data.id,
                    id: data.id_outlet, 
                    name: data.outlet_name, 
                    address: data.outlet_address, 
                    kecamatan: data.outlet_kecamatan,
                    kabupaten_kota: data.outlet_kabupaten_kota,
                    point: data.point, 
                    description: data.keterangan,
                    date: "data.tanggal", 
                    isHistory: false
                }
                setMerchantActive(arr => [...arr, d])
            })
        })
        .catch(error => {
            console.log(error)
        })
        setLoadingByTab(false)
    }

    const setLoadingByTab = (isLoading) => {
        var newLoading = [...loading]
        newLoading[tabPosition] = isLoading
        setLoading(newLoading)
    }

    async function getChangePointHistory(keyword) {
        setLoadingByTab(true)
        var endpoint = "loyalty/tukarpointhistory"

        if (keyword) {
            endpoint = endpoint + "?search=" + keyword
        }

        await fetch(url + endpoint, {headers: headerNoUrlEncodedBearer})
        .then(response => {
            return getJsonResponse(response)
        })
        .then(json => {
            setMerchantDeactive([])
            json['data'].map(function(data, index) {
                var d = { 
                    id: data.id,
                    name: data.outlet_name, 
                    address: data.outlet_address + ", " + data.outlet_kecamatan + ", " + data.outlet_kabupaten_kota, 
                    point: data.point_hadiah && data.point_hadiah || 0, 
                    date: data.tanggal_penukaran, 
                    isHistory: true
                }
                setMerchantDeactive(arr => [...arr, d])
            })
        })
        .catch(error => {
            console.log(error)
        })
        setLoadingByTab(false)
    }

    const onSearch = (keyword) => {
        var newArray = [...searchKeyword]
        newArray[tabPosition] = keyword
        setSearhKeyword(newArray)
        switch(tabPosition) {
            case 0:
                getMerchantList(keyword)
                break;
            case 1:
                getChangePointHistory(keyword)
                break;
            default:
                getMerchantList(keyword)
                break;    
        }
    }

    const onKeywordChanged = (keyword) => {
        var newArray = [...searchKeyword]
        newArray[tabPosition] = keyword
        setSearhKeyword(newArray)
    }

    useEffect(() => {
        getMerchantList()
        getChangePointHistory()
    }, [])

    return (
        <div className="point-loyalty-history-container">
            <NavbarSearchTab
                placeholder="Cari..."
                searchKeyword={searchKeyword[tabPosition]}
                onKeywordChanged={(keyword) => onKeywordChanged(keyword)}
                onSearch={(keyword) => onSearch(keyword)}/>
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Daftar Merchant" key="1">
                    <div className="point-loyalty-history-item-container">
                        {
                            loading[0] && <Spin/> ||
                            merchantActive && merchantActive.length > 0 &&
                            merchantActive.map(function(data, i){
                                return <MerchantRow
                                            data={data}/>
                            })
                            ||
                            <EmptyPage
                                message="Data merchant belum ada"/>
                        }
                    </div>
                </TabPane>
                <TabPane tab="Sejarah Penukaran" key="2">
                    <div className="point-loyalty-history-item-container">
                        {
                            loading[1] && <Spin/> ||
                            merchantDeactive && merchantDeactive.length > 0 && 
                            merchantDeactive.map(function(data, i){
                                return <MerchantHistoryRow
                                            name={data.name}
                                            address={data.address}
                                            point={data.point}
                                            isHistory={data.isHistory}
                                            date={data.date}/>
                            })
                            ||
                            <EmptyPage
                                message="Data sejarah penukaran belum ada"/>
                        }
                    </div>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default PointLoyaltyHistory
