import React from 'react'
import './success-verification.css'
import { BsCheckCircle } from 'react-icons/bs'

const SuccessVerification = ({phoneNumber}) => {
    return (
        <div className="successVerificationContainer">
            <BsCheckCircle className="successVerificationIcon"/>
            <p className="successVerificationContact">{phoneNumber}</p>
            <p className="successVerificationLabel">Nomor anda sudah terverifikasi</p>
        </div>
    )
}

export default SuccessVerification
