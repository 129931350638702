import React from 'react'
import './verification.css'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import VerificationOptions from '../../components/VerificationOption'

const Verification = () => {
    return (
        <div className='verificationContainer'>
            <NavbarDetail title='VERIFICATION'/>
            <p className='verificationLabel'>Please select your verification method for this number</p>
            <p className='verificationContact'>+6281234123412</p>
            <div style={{width: '100%', height: 40}} />
            <VerificationOptions/>
        </div>
    )
}

export default Verification
