import { DatePicker } from 'antd';
import React, { useState } from 'react'
import './gg-date-picker.css'
import { format } from 'date-fns'
import moment from 'moment'

const GGDatePicker = ( { label, startDate, onChange } ) => {

    function disableDateRanges(range = { startDate: false, endDate: true }) {
        const { startDate, endDate } = range;
        return function disabledDate(current) {
          let startCheck = true;
          let endCheck = true;
          if (startDate) {
            startCheck = current && current < moment(startDate, 'YYYY-MM-DD');
          }
          if (endDate) {
            endCheck = current && current > moment(moment(new Date()).subtract(18, 'year'), 'YYYY-MM-DD');
          }
          return (startDate && startCheck) || (endDate && endCheck);
        };
      }

    return (
        <div className="gg-date-picker-container">
            <p className="gg-date-picker-label">{label}</p>
            <DatePicker
                defaultValue={moment(new Date()).subtract(18, 'year')}
                onChange={(date) => onChange(date)}
                format="DD MMM yyyy"
                disabledDate={disableDateRanges({endDate:new Date(), startDate:new Date('1900-01-01')})} />
        </div>
    )
}

GGDatePicker.defaultProps = {
    startDate: new Date()
}

export default GGDatePicker
