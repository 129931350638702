import React, { useEffect, useState } from 'react'
import NavHome from '../../components/navhome/NavHome'
import '@brainhubeu/react-carousel/lib/style.css'
import './home.css'
import BottomMenu from '../../components/bottommenu/BottomMenu'
import 'antd/dist/antd.css'
import { Carousel, Spin, Tabs } from 'antd'
import HomeMerchantMenu from '../../components/homemerchantmenu/HomeMerchantMenu'
import HomeVideo from '../../components/homevideo/HomeVideo'
import HomeArticle from '../../components/homearticle/HomeArticle'
import { getJsonResponse, headerBearer, headerNoUrlEncodedBearer, url } from '../../service/Service'
import ReservationMerchantRow from '../../components/reservationmerchantrow/ReservationMerchantRow'
import PromoRow from '../../components/promorow/PromoRow'
import BuyPackageMenu from '../../components/buypackage/menu/BuyPackageMenu'
import moment from 'moment'
import EmptyPage from '../../components/emptypage/EmptyPage'

const HomeMerchant = () => {
    
    const [reservations, setReservations] = useState([])
    const [promos, setPromos] = useState([])
    const [itemPaket, setItemPaket] = useState([])
    const [itemLoyalty, setItemLoyalty] = useState([])
    const [itemLoyalty2, setItemLoyalty2] = useState([])
    const [itemType, setItemType] = useState(0)

    const { TabPane } = Tabs;

    function callback(key) {
        console.log(key);
    }

    async function getPromoHoreca() {
        var outlet = JSON.parse(localStorage.getItem("outlet"))
        await fetch(url + "homehoreca/promo/" + outlet.id, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setPromos([])
                json['data'].map(function(d, index) {
                    var data = {
                        title: d.title, time: "Berlaku hingga " + moment(d.start_program).format("DD MMM yyyy"), image: d.image
                    }
                    setPromos(arr => [...arr, data])
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    async function getReservasiHoreca() {
        var outlet = JSON.parse(localStorage.getItem("outlet"))
        await fetch(url + "homehoreca/reservasi/" + outlet.id, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setReservations([])
                json['data'].map(function(d, index) {
                    var data = {
                        name: d.user.name, phone: d.user.mobile, time: moment(d.tanggal_reservasi).format("DD MMM yyyy") + " - " + d.jam_reservasi, number: d.jumlah_orang
                    }
                    setReservations(arr => [...arr, data])
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    async function getAllProgram() {
        var outlet = JSON.parse(localStorage.getItem("outlet"))
        await fetch(url + "allprogram/" + outlet.id, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setItemPaket([])
                json['data']['cpo'].map(function(d, index){
                    var data = {
                        name: d.nama_paket, point: d.poin && d.poin || 0, alokasi_item: d.alokasi_item
                    }
                    setItemPaket(arr => [...arr, data])
                })

                setItemLoyalty([])
                json['data']['loyalty'].map(function(d, index){
                    var data = {
                        name: d.nama_paket, point: d.poin && d.poin || 0
                    }
                    setItemLoyalty(arr => [...arr, data])
                })

                setItemLoyalty2([])
                json['data']['loyalty_berjenjang'].map(function(d, index){
                    var data = {
                        name: d.nama_paket, point: d.poin && d.poin || 0
                    }
                    setItemLoyalty2(arr => [...arr, data])
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (localStorage.getItem("merchantType") == 1) {
            getPromoHoreca()
            getReservasiHoreca()
        } else {
            getAllProgram()
        }
    }, [])

    const [selected, setSelected] = useState(-1)

    const onChangeType = (index) => {
        setItemType(index)
    }

    return (
        <div className="home-merchant-container">
            <NavHome
                isNotificationShown={false}/>
                {
                    localStorage.getItem("merchantType") == 1
                    &&
                    <>
                        <Tabs defaultActiveKey="1" onChange={callback} className="home-merchant-tab">
                            <TabPane tab="Reservasi" key="1">
                                <div className="homeTabPaneContainer">
                                    {
                                        reservations && reservations.length == 0 && 
                                        <EmptyPage
                                            message="Tidak ada reservasi"/>
                                        ||
                                        reservations && 
                                        reservations.map(function(data, i){
                                            return <ReservationMerchantRow data={data} isViewOnly={true}/>
                                        })
                                    }
                                </div>
                            </TabPane>
                            <TabPane tab="Promo Aktif" key="2">
                                <div className="homeTabPaneContainer">
                                    {
                                        promos && promos.length == 0 && 
                                        <EmptyPage
                                            message="Tidak ada promo"/>
                                        ||
                                        promos && 
                                        promos.map(function(data, i){
                                            return <PromoRow data={data} isViewOnly={true}/>
                                        })
                                    }
                                </div>
                            </TabPane>
                        </Tabs>
                    </>
                    ||
                    <>
                        <Tabs defaultActiveKey="1" onChange={callback} className="home-merchant-tab-2">
                            <TabPane tab="Paket" key="1">
                                <div className="homeTabPaneContainer">
                                    <div className="buy-package-container-menu">
                                        <BuyPackageMenu
                                            onChangeType={onChangeType}/>
                                    </div>
                                    
                                    {
                                        itemPaket.length > 0 && itemType == 0 &&
                                        <>
                                            <p className="buy-package-label">Paket Reguler</p>
                                            {
                                                itemPaket.map(function(item, i) {
                                                    return <>
                                                        <div className="buy-package-point-container"
                                                            key={i}>
                                                            <div className="buy-package-point-container-2">
                                                                <p className="buy-package-point-label">{item.name}</p>
                                                            </div>
                                                            <p className="buy-package-stock-label">{item.alokasi_item} Stok</p>
                                                        </div>
                                                        <div className="buy-package-line"/>  
                                                    </>
                                                })
                                            }
                                        </>
                                        ||
                                        itemPaket.length > 0 && itemType == 1 &&
                                        <>
                                            {
                                                itemPaket.map(function(item, i) {
                                                    return <>
                                                        <div className="buy-package-point-container"
                                                            key={i}>
                                                            <div className="buy-package-point-container-2">
                                                                <p className="buy-package-point-label">{item.name}</p>
                                                            </div>
                                                            <p className="buy-package-stock-label">{item.alokasi_item} Stok</p>
                                                        </div>
                                                        <div className="buy-package-line"/>  
                                                    </>
                                                })
                                            }
                                        </>
                                    }
                                    {
                                        itemLoyalty.length > 0 && itemType == 0 &&
                                        <>
                                            <p className="buy-package-label">Paket Loyalty</p>
                                            {
                                                itemLoyalty.map(function(item, i) {
                                                    return <>
                                                        <div className="buy-package-point-container"
                                                            key={i}>
                                                            <div className="buy-package-point-container-2">
                                                                <p className="buy-package-point-label">{item.name}</p>
                                                                <p className="buy-package-point">+ {item.point} poin</p>
                                                            </div>
                                                        </div>
                                                        <div className="buy-package-line"/>  
                                                    </>
                                                })
                                            }
                                        </>
                                        ||
                                        itemLoyalty.length > 0 && itemType == 2 &&
                                        <>
                                            {
                                                itemLoyalty.map(function(item, i) {
                                                    return <>
                                                        <div className="buy-package-point-container"
                                                            key={i}>
                                                            <div className="buy-package-point-container-2">
                                                                <p className="buy-package-point-label">{item.name}</p>
                                                                <p className="buy-package-point">+ {item.point} poin</p>
                                                            </div>
                                                        </div>
                                                        <div className="buy-package-line"/>  
                                                    </>
                                                })
                                            }
                                        </>
                                    }

                                    {
                                        itemLoyalty2.length > 0 && itemType == 0 &&
                                        <>
                                            <p className="buy-package-label">Paket Loyalty Berjenjang</p>
                                            {
                                                itemLoyalty2.map(function(item, i) {
                                                    return <>
                                                        <div className="buy-package-point-container"
                                                            key={i}>
                                                            <div className="buy-package-point-container-2">
                                                                <p className="buy-package-point-label">{item.name}</p>
                                                                <p className="buy-package-point">+ {item.point} poin</p>
                                                            </div>
                                                        </div>
                                                        <div className="buy-package-line"/>  
                                                    </>
                                                })
                                            }
                                        </>
                                        ||
                                        itemLoyalty2.length > 0 && itemType == 3 &&
                                        <>
                                            {
                                                itemLoyalty2.map(function(item, i) {
                                                    return <>
                                                        <div className="buy-package-point-container"
                                                            key={i}>
                                                            <div className="buy-package-point-container-2">
                                                                <p className="buy-package-point-label">{item.name}</p>
                                                                <p className="buy-package-point">+ {item.point} poin</p>
                                                            </div>
                                                        </div>
                                                        <div className="buy-package-line"/>  
                                                    </>
                                                })
                                            }
                                        </>
                                    }
                                </div>
                            </TabPane>
                            <TabPane tab="Merchandise" key="2">
                                <div className="homeTabPaneContainer">
                                    {/* <div className="change-point-item-container">
                                        {
                                            items.map(function(item, i){
                                                return <div className="change-point-item-container-2"
                                                        key={i}>
                                                    <img className="change-point-item-image" src="../../logo192.png"/>
                                                    <p className="change-point-item-name">{item.name}</p>
                                                    <p className="change-point-item-point">{item.point} poin</p>
                                                    <p className="change-point-item-stock">{item.stock} item tersisa</p>
                                                </div>
                                            })
                                        }
                                    </div> */}
                                    <EmptyPage
                                            message="Tidak ada data Merchandise"/>
                                </div>
                            </TabPane>
                        </Tabs>
                    </>
                }
            <BottomMenu title="Transaksi"/>
        </div>
    )
}

export default HomeMerchant
