import React from 'react'
import ButtonPrimary from '../../components/ButtonPrimary'
import OTPInput from '../../components/otp-input/Index'
import './otp-v2.css'

const OtpV2 = ( { onVerification, onResendOTP, phoneNumber } ) => {

    var otp = ""

    const otpChanged = (value) => {
        otp = value
    }

    return (
        <div className="otpv2Container">
            <p className="otpv2Label">Masukkan One-Time Password yang anda terima ke nomor berikut</p>
            <p className="otpv2Contact">{phoneNumber}</p>
            <OTPInput
                autoFocus
                isNumberInput
                length={6}
                className='otpv2InputContainer'
                inputClassName='otpv2Input'
                onChangeOTP={otpChanged}
            />
            <div className="otpv2Space"/>
            <div className="otpv2ButtonContainer">
                <ButtonPrimary
                    text="Verifikasi"
                    onClick={() => onVerification != null && onVerification(otp)}/>
                <div className="otpv2NotSentLabelContainer">
                    <div className="otpv2Line"/>
                    <p className="otpv2NotSentLabel">Tidak mendapat OTP?</p>
                    <div className="otpv2Line"/>
                </div>
                <div className="otpv2Separator"/>
                <div className="otpv2Separator"/>
                <p className="otpv2PrimaryLabel" onClick={() => onResendOTP()}>Kirim Ulang</p>
            </div>
        </div>
    )
}

OtpV2.defaultProps = {
    onVerification: null
}

export default OtpV2
