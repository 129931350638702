import React from 'react'
import FAQRow from '../../../components/faqrow/FAQRow'
import NavbarSearch from '../../../components/navbarsearch/NavbarSearch'

const FAQ = () => {

    const items = [
        {
            question: "Apa itu TapPoint?", 
            answer: "TapPoint adalah web apps yang bertujuan meningkatkan interaksi antara outlet dan pelanggan dengan menyediakan berbagai kemudahan dan program khusus."
        },
        {
            question: "TapPoint bisa dipakai di mana saja?", 
            answer: "TapPoint dapat dipakai pada outlet pilihan bertanda khusus dengan logo TapPoint."
        },
    ]

    return (
        <div className="gg-parent-container">
            <NavbarSearch
                placeholder="Cari..."/>
                <div className="gg-content-no-top-padding-container">
                    {
                        items.map(function(data) {
                            return <FAQRow
                                        question={data.question}
                                        answer={data.answer}/>
                        })
                    }
                </div>
        </div>
    )
}

export default FAQ
