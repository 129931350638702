import React, { useState, useEffect } from 'react'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import RowInfoEdit from '../../components/rowinfo/RowInfoEdit'
import './profile.css'
import { Link } from 'react-router-dom'
import { FaAward, FaChevronDown, FaChevronUp, FaMailBulk, FaMailchimp, FaStar, FaVoicemail } from 'react-icons/fa'
import { ImSpoonKnife, ImMug } from 'react-icons/im'
import { MdEmail } from 'react-icons/md' 
import { GrHistory } from 'react-icons/gr'
import { BsFileEarmarkCheck } from 'react-icons/bs'
import { HiBadgeCheck } from 'react-icons/hi'
import RowInfoTo from '../../components/rowinfo/RowInfoTo'
import { url, headerBearer } from '../../service/Service'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import ProductModal from '../../components/selectproductmodal/ProductModal'
import RowInfoEditLocation from '../../components/rowinfo/RowInfoEditLocation'
import RowInfoGender from '../../components/rowinfo/RowInfoGender'
import RowInfoEditPassword from '../../components/rowinfo/RowInfoEditPassword'
import { PATH_ACTIVITY_HISTORY, PATH_FAQ, PATH_VENUE_CONFIG } from '../../const/Pathname'
import RowInfoDate from '../../components/rowinfo/RowInfoDate'
import ProductWarning from '../../components/productwarning/ProductWarning'

const ProfileMerchant = () => {

    const [isPersonalDataHidden, setPersonalDataHidden] = useState(false)
    const [isMerchantDataHidden, setMerchantDataHidden] = useState(false)

    const [userData, setUserData] = useState({})
    const [outletData, setOutletData] = useState({})
    const [successGetProfile, setSuccessGetProfile] = useState(false)

    const history = useHistory()

    async function getUser() {
        await fetch(url + "auth/user", {headers: headerBearer})
        .then(response => {
            console.log(response)
            if (response.status == 200) {
                return response.json()
            } else {
                return response.text().then(text => {
                    throw new Error(text)
                })
            }
        })
        .then(json => {
            console.log(json)
            setUserData(json.data)
            setOutletData(json.data.outlet)
            setSuccessGetProfile(true)
        })
        .catch(error => console.log(error))
    }

    const logout = () => {
        localStorage.clear()
        history.push("/login")
    }

    const [isShowProductModal, setShowProductModal] = useState(false)

    const onProductCancel = () => {
        setShowProductModal(!isShowProductModal)
    }

    const onProductOk = (items) => {
        setShowProductModal(!isShowProductModal)
    }

    const showProductModal = () => {
        setShowProductModal(!isShowProductModal)
    }

    const gotoFAQ = () => {
        history.push(PATH_FAQ)
    }

    useEffect(() => {
        getUser()
    }, []);

    return (
        <div className="profile-container">
            <NavbarDetail
                title="PROFILE"/>
            <div className="profile-container-2">
                <div className="profile-container-red">
                    <p className="profile-name">{userData.name && userData.name || "-"}</p>
                    <p className="profile-level"></p>
                </div>

                <div className="profile-menu-container">
                    <Link to="/inbox" className="profile-menu-container-2">
                        <MdEmail className="profile-menu-icon"/>
                        <p className="profile-menu-name">Inbox</p>
                    </Link>
                    <Link to={PATH_ACTIVITY_HISTORY} className="profile-menu-container-2">
                        <GrHistory className="profile-menu-icon"/>
                        <p className="profile-menu-name">Sejarah Aktivitas</p>
                    </Link>
                    {
                        localStorage.getItem("role") === 'useroutlet' && localStorage.getItem("merchantType") == 1
                        &&
                        <>
                            <Link className="profile-menu-container-2"
                                to="/promo-management">
                                <HiBadgeCheck className="profile-menu-icon"/>
                                <p className="profile-menu-name">Manajemen Promo</p>
                            </Link>
                            <Link className="profile-menu-container-2" to={PATH_VENUE_CONFIG}>
                                <ImMug className="profile-menu-icon"/>
                                <p className="profile-menu-name">Pengaturan Venue</p>
                            </Link>
                        </>
                    }
                    <Link to="/package-and-merchandise" className="profile-menu-container-2">
                        <BsFileEarmarkCheck className="profile-menu-icon"/>
                        <p className="profile-menu-name">Paket &amp; Merchandise</p>
                    </Link>
                </div>

                <div className="profile-personal-data-container">
                    <div className="profile-personal-data-title-container">
                        <p className="profile-personal-data-title">Data Merchant</p>
                        {
                            isMerchantDataHidden && 
                            <FaChevronDown className="profile-personal-up-icon"
                                onClick={() => setMerchantDataHidden(false)}/>
                            ||
                            <FaChevronUp className="profile-personal-up-icon"
                                onClick={() => setMerchantDataHidden(true)}/>
                        }
                    </div>
                    {
                        isMerchantDataHidden
                        &&
                        <></>
                        || successGetProfile &&
                        <div className="profile-personal-data-container-2">
                            <RowInfoEdit
                                keyRequest="name"
                                label="Nama Merchant"
                                isEditable={false}
                                value={outletData.outlet_name && outletData.outlet_name || "-"}/>
                            <div className="profile-line-2"/>
                            <RowInfoEdit
                                label="Alamat Merchant"
                                value={outletData.address && outletData.address || "-"}
                                isEditable={false}/>
                            <div className="profile-line-2"/>
                            <RowInfoEditLocation
                                keyRequest="lokasi"
                                label="Lokasi"
                                isEditable={false}
                                value={outletData.kecamatan && outletData.kabupaten_kota && outletData.kecamatan + " - " + outletData.kabupaten_kota || "-"}/>
                            <div className="profile-line-2"/>
                            <RowInfoEdit
                                label="Nomor Telepon Merchant"
                                value={outletData.phone_merchant && outletData.phone_merchant || "-"}
                                isEditable={false}/>
                        </div>
                    }
                </div>

                <div className="profile-personal-data-container">
                    <div className="profile-personal-data-title-container">
                        <p className="profile-personal-data-title">Data Pribadi</p>
                        {
                            isPersonalDataHidden && 
                            <FaChevronDown className="profile-personal-up-icon"
                                onClick={() => setPersonalDataHidden(false)}/>
                            ||
                            <FaChevronUp className="profile-personal-up-icon"
                                onClick={() => setPersonalDataHidden(true)}/>
                        }
                    </div>
                    {
                        isPersonalDataHidden
                        &&
                        <></>
                        || successGetProfile &&
                        <div className="profile-personal-data-container-2">
                            <RowInfoEdit
                                keyRequest="name"
                                label="Nama Lengkap Pemilik"
                                isEditable={false}
                                value={outletData.outlet_owner && outletData.outlet_owner || "-"}/>
                            <div className="profile-line-2"/>
                            {/* <RowInfoDate
                                keyRequest="dob"
                                label="Tanggal Lahir"
                                isEditable={false}
                                value={userData.dob && moment(userData.dob).format("DD MMM yyyy") || "-"}
                                currentValueDateFormat={new Date(userData.dob)}/>
                            <div className="profile-line-2"/> */}
                            {/* <RowInfoEdit
                                keyRequest="nik"
                                label="No. KTP"
                                isEditable={false}
                                value={userData.id_no && userData.id_no || "-"}/>
                            <div className="profile-line-2"/> */}
                            {/* <RowInfoEdit
                                label="Alamat Email Pemilik"
                                value={userData.email && userData.email || "-"}
                                isEditable={false}/>
                            <div className="profile-line-2"/> */}
                            <RowInfoEdit
                                label="Nomor Telepon Pemilik"
                                value={outletData.phone_owner && outletData.phone_owner || "-"}
                                isEditable={false}/>
                        </div>
                    }
                </div>
                
                <div className="profile-personal-data-container">
                    <p className="profile-personal-data-title">Lainnya</p>
                    <RowInfoEditPassword
                        keyRequest=""
                        label="Update Password"/>
                    <div className="profile-line-2"/>
                    <RowInfoTo
                        label="FAQ dan Bantuan"
                        onClick={() => gotoFAQ()}/>
                    <div className="profile-line-2"/>
                </div>
            </div>
            <div className="profile-button-container">
                <button className="profile-sign-out-button"
                    onClick={() => logout()}>Keluar</button>
            </div>
            <ProductModal
                isShowModal={isShowProductModal}
                onCancel={() => onProductCancel()}
                selectedItem={new Map()}
                onOk={(e) => onProductOk(e)}/>
        </div>
    )
}

export default ProfileMerchant
