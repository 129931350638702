import React, { useEffect, useState } from 'react'
import { FaChevronRight, FaMapMarker } from 'react-icons/fa'
import NavbarSearch from '../../components/navbarsearch/NavbarSearch'
import StoreList from '../../components/storelist/StoreList'
import '../restaurantlist/restaurant-list.css'
import { useParams, useHistory } from 'react-router-dom'
import PromoUse from '../../components/promo/promoused/PromoUse'
import { getJsonResponse, headerBearer, headerNoUrlEncodedBearer, url } from '../../service/Service'
import { TYPE_STORE_FROM_REWARD } from '../../const/ParamType'

const RewardHorecaList = (props) => {

    const { type } = useParams()
    const history = useHistory()
    const {state} = props.location

    const [items, setItems] = useState([])

    async function getOutlet() {
        await fetch(url + "detailhorecatransactionreward/" + state.id, {headers: headerBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setItems([])
                json['data'].map(function(d, index) {
                    var item = {
                        id: d.outlet.id,
                        outlet_name: d.outlet.outlet_name,
                        address: d.outlet.address,
                        program_id: state.id,
                        transactionId: state.transactionId
                    }
                    console.log(item)
                    setItems(arr => [...arr, item])
                })
                console.log(items)
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getOutlet()
    }, [])

    return (
        <div className="restaurant-list-container">
            <NavbarSearch placeholder="Cari Restoran/Cafe..."/>
            {/* <div className="restaurant-list-location-container">
                <p className="restaurant-list-location-label">Lokasi Sekarang</p>
                <FaMapMarker className="restaurant-list-location-icon"/>
                <p className="restaurant-list-location">Bandung</p>
                <FaChevronRight className="restaurant-list-location-icon-2"/> 
            </div> */}

            <div className="restaurant-list-container-2">
            <PromoUse
                    label="Klaim Reward"
                    name={state && state.program_name}/>
                <StoreList
                    isWithImage={true}
                    items={items}
                    type={TYPE_STORE_FROM_REWARD}/>
            </div>
        </div>
    )
}

export default RewardHorecaList
