import React from 'react'
import { FaBell, FaMapMarkerAlt, FaUser } from 'react-icons/fa'
import './nav-home.css'
import { Link } from 'react-router-dom'

const NavHome = ({isNotificationShown, notificationCount}) => {
    return (
        <div className="navHomeContainer">
            <Link to="/profile" className="navHomeIcon">
                <FaUser className="navHomeIcon"/>
            </Link>
            <div className="navHomeLocationContainer">
                {/* <p className="navHomeTitle">TAP<i className="navHomeTitleBold">POINT</i></p> */}
                <div className="navHomeLogo"/>
            </div>
            {
                isNotificationShown &&
                <Link to="/notification" className="navHomeIcon">
                    <FaBell className="navHomeIcon"/>
                    {
                        notificationCount > 0 &&
                        <div className="navHomeNotification"/>
                    }
                </Link> 
            }
        </div>
    )
}

NavHome.defaultProps = {
    isNotificationShown: true,
    notificationCount: 0
}

export default NavHome
