import { Spin } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import ButtonPrimary from '../ButtonPrimary'
import ButtonSecondary from '../ButtonSecondary'
import InputAutoSearch from '../input/InputAutoSearch'

const OutletModal = ({ isShowModal, city, onCancel, onOk, isLoading }) => {

    var selectedCity = ""

    const onSelectedCity = (data) => {
        selectedCity = data
        console.log(data)
    }

    return (
        <Modal 
            title="PILIH LOKASI" 
            visible={isShowModal} 
            onOk={() => onOk != null && onOk(selectedCity)} 
            onCancel={() => onCancel != null && onCancel()}
            footer={[
                <div className="select-product-modal-footer-container">
                    {
                        isLoading && <Spin/>
                        ||
                        <>
                            <ButtonSecondary
                                text="Kembali" 
                                key="back" 
                                onClick={() => onCancel != null && onCancel()}/>,
                            <div className="select-product-modal-button-separator"/>,
                            <ButtonPrimary 
                                text="Simpan"
                                key="submit" 
                                type="primary" 
                                onClick={() => onCancel != null && onOk(selectedCity)}/>
                        </>
                    }
                </div>
            ]}>

            <div className="gg-separator"/>

            <InputAutoSearch
                 label="Kota"
                 placeholder="Cari Kota ..."
                 suggestions={city}
                 onSelected={(data) => onSelectedCity(data)}/>
        </Modal>
    )
}

export default OutletModal
