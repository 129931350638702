import React from 'react'

const ProductWarning = () => {
  return (
    <div className="gg-fix-product-warning-container">
        <img className="gg-product-warning-image" src="../../product_warning.png"/>
        <p className="gg-product-warning-label"><b>PERINGATAN:</b><br/>KARENA MEROKOK, SAYA TERKENA KANKER TENGGOROKAN. LAYANAN BERHENTI MEROKOK (0800-177-6565)</p>
        <img className="gg-product-warning-image-2" src="../../product_18.png"/>
    </div>
  )
}

export default ProductWarning