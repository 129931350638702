import React from 'react'
import { FaPhone, FaShoppingBag, FaStar } from 'react-icons/fa'
import { ImSpoonKnife } from 'react-icons/im'
import './merchant-menu.css'
import { Link } from 'react-router-dom'
import { CHANGE_POINT_USER } from '../../const/Pathname'

const MerchantMenu = ({type, id, data}) => {
    return (
        <div className="merchant-menu-container">
            {/* {
                type === "restaurant-qr" &&
                <Link className="merchant-menu-item-container"
                    to="/buy-package">
                    <div className="merchant-menu-item-icon-container">
                        <FaShoppingBag className="merchant-menu-item-icon"/>
                    </div>
                    <p className="merchant-menu-item-label">Beli Paket</p>
                </Link>
                ||
                type === "grocery-qr" &&
                <Link className="merchant-menu-item-container"
                    to="/buy-package">
                    <div className="merchant-menu-item-icon-container">
                        <FaShoppingBag className="merchant-menu-item-icon"/>
                    </div>
                    <p className="merchant-menu-item-label">Beli Paket</p>
                </Link>
            } */}
            <Link className="merchant-menu-item-container"
                to={{
                    pathname: "/scan-qr/buy-package",
                    state: { id: id }
                }}>
                <div className="merchant-menu-item-icon-container">
                    <FaShoppingBag className="merchant-menu-item-icon"/>
                </div>
                <p className="merchant-menu-item-label">Beli Paket</p>
            </Link>
            <Link className="merchant-menu-item-container"
                to={{ pathname: CHANGE_POINT_USER, state: { data: data }}}>
                <div className="merchant-menu-item-icon-container">
                    <FaStar className="merchant-menu-item-icon"/>
                </div>
                <p className="merchant-menu-item-label">Tukar Poin</p>
            </Link>
            
            {
                type === "restaurant" &&
                <>
                <div className="merchant-menu-item-container"
                    onClick={() => window.open("tel:" + data.phone_merchant)}>
                    <div className="merchant-menu-item-icon-container">
                        <FaPhone className="merchant-menu-item-icon"/>
                    </div>
                    <p className="merchant-menu-item-label">Hubungi</p>
                </div>
                <Link className="merchant-menu-item-container"
                    to={{
                        pathname: "/reservation",
                        state: {id: id, data: data}
                    }}>
                    <div className="merchant-menu-item-icon-container">
                        <ImSpoonKnife className="merchant-menu-item-icon"/>
                    </div>
                    <p className="merchant-menu-item-label">Reservasi</p>
                </Link>
                </>
                ||
                type === "restaurant-qr" &&
                <>
                <div className="merchant-menu-item-container">
                    <div className="merchant-menu-item-icon-container">
                        <FaPhone className="merchant-menu-item-icon"/>
                    </div>
                    <p className="merchant-menu-item-label">Hubungi</p>
                </div>
                <Link className="merchant-menu-item-container"
                    to={{
                        pathname: "/reservation",
                        state: {id: id, data: data}
                    }}>
                    <div className="merchant-menu-item-icon-container">
                        <ImSpoonKnife className="merchant-menu-item-icon"/>
                    </div>
                    <p className="merchant-menu-item-label">Reservasi</p>
                </Link>
                </>
            }
        </div>
    )
}

export default MerchantMenu
