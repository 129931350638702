import { message } from 'antd';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { onBackgroundMessage } from "firebase/messaging/sw";

const firebaseConfig = {
  apiKey: "AIzaSyBxqfW04_7Mst0ZwCmQtUEKgj9hmGs2-Ds",
  authDomain: "ggtoucpointpushnotif.firebaseapp.com",
  projectId: "ggtoucpointpushnotif",
  storageBucket: "ggtoucpointpushnotif.appspot.com",
  messagingSenderId: "14416140729",
  appId: "1:14416140729:web:9244fa5e845e286569fe68",
  measurementId: "G-GW7F6L2KWJ"
};

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app);

const MESSAGE_KEY = "BEe856znOTYbFG11wsfYWwE2ea54mO0e7u6dcCz8pTxiAuk-WAZ3mIKqxaaKjvA8blcuiBrjDbURl6O4iLM1jYw"

export const tokennyo = (storeFCMToken) => {
    getToken(messaging, { vapidKey: MESSAGE_KEY }).then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
          console.log(currentToken)
          storeFCMToken(currentToken)
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
}