import { Modal, Radio } from 'antd'
import React, { useState } from 'react'
import ButtonPrimary from '../ButtonPrimary'
import ButtonSecondary from '../ButtonSecondary'
import './gender-modal.css'

const GenderModal = ({ isShowModal, onCancel, onOk }) => {

    const[gender, setGender] = useState("-1")

    const handleChange = e => {
        setGender(e.target.value)
    } 

    return (
        <Modal 
            title="JENIS KELAMIN" 
            visible={isShowModal} 
            onOk={() => onOk != null && onOk(gender)} 
            onCancel={() => onCancel != null && onCancel()}
            footer={[
                <div className="gender-modal-footer-container">
                    <ButtonSecondary
                        text="Kembali" 
                        key="back" 
                        onClick={() => onCancel != null && onCancel()}/>,
                    <div className="gender-modal-button-separator"/>,
                    <ButtonPrimary 
                        text="Simpan"
                        key="submit" 
                        type="primary" 
                        onClick={() => onOk != null && onOk(gender)}/>
                </div>
            ]}>
            <div className="gender-modal-option-container">
                <Radio.Group className="gender-modal-option" onChange={handleChange}>
                    <Radio 
                        className="gender-modal-option-text"
                        value="1">Laki-Laki</Radio>
                    <div className="gender-modal-line"/>
                    <Radio
                        className="gender-modal-option-text"
                        value="0">Perempuan</Radio>
                </Radio.Group>
            </div>
        </Modal>
    )
}

export default GenderModal
