import React, { useState, useEffect } from 'react'
import { FaChevronRight, FaEdit, FaPencilAlt, FaPencilRuler, FaRegEdit } from 'react-icons/fa'
import { getJsonResponse, headerBearer, headerNoUrlEncodedBearer, url } from '../../service/Service'
import InputAutoSearch from '../input/InputAutoSearch'
import InputAutoSearchProfile from '../input/InputAutoSearchProfile'
import InputPrimary from '../input/InputPrimary'
import './row-info.css'

const RowInfoEditLocation = ({ keyRequest, label, value, type, isEditable }) => {

    const [isEditEnabled, setEditEnabled] = useState(false)
    const [defaultValue, setDefaultValue] = useState(value)
    var lokasi = ""
    var lokasiId = 0

    const onChange = (e) => {
        setDefaultValue(e)
    }

    async function updateData() {
        setEditEnabled(false)
        if (!keyRequest) return
        if (lokasiId == 0) return
        var formData = new FormData()
        formData.append(keyRequest, lokasiId)

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }

        await fetch(url + "account/setting", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                console.log(json)
                setDefaultValue(lokasi)
            })
            .catch(error => {
                console.log(error)
            })
    }

    var provinces = []
    var provincesMap = new Map()
    var cities = []
    var citiesMap = new Map()

    const getCity = (provinceId) => {
        fetch(url + "cities/" + provinceId)
            .then(response => response.json())
            .then(json => {
                json.data.map(function(d, i){
                    cities[i] = d.name
                    if (!citiesMap.has(d.name)) {
                        citiesMap.set(d.name, d.id)
                    }
                })
            })
            .catch(error => console.log(error))
    }

    async function fetchProvince() {
        await fetch(url + "provinces")
        .then(response => {
            return getJsonResponse(response)
        })
        .then(json => {
            console.log(json)
            json.data.map(function(d, i){
                provinces[i] = d.name
                if (!provincesMap.has(d.name)) {
                    provincesMap.set(d.name, d.id)
                }
            })
        })
        .catch(error => console.log(error))
    }

    const onSelectedProvinces = (province) => {
        // data.province = provincesMap.get(province)
        // onDataChanged(data)
        getCity(provincesMap.get(province))
        lokasi = province
    }

    const onSelectedCity = (city) => {
        // data.city = citiesMap.get(city)
        // onDataChanged(data)
        lokasi = city + " - " + lokasi
        lokasiId = citiesMap.get(city)
    }

    useEffect(() => {
        fetchProvince()
    })

    return (
        <div className="rowInfoContainer">
            <div className="rowInfoContainer2">
                <p className="rowInfoLabel">{label}</p>
                <p className="rowInfoValue">{defaultValue}</p>
                {
                    isEditEnabled 
                    && 
                    <div style={{marginTop: 10, width: '90%'}}>
                        <InputAutoSearchProfile
                            label="Provinsi"
                            placeholder="Cari Provinsi ..."
                            suggestions={provinces}
                            onSelected={(data) => onSelectedProvinces(data)}/>
                        <div className="registerSeparator"/>
                        <InputAutoSearchProfile
                            label="Kabupaten/Kota"
                            placeholder="Cari Kabupaten/Kota ..."
                            suggestions={cities}
                            onSelected={(data) => onSelectedCity(data)}/>
                    </div>
                }
            </div>
            {
                !isEditEnabled && isEditable && <FaPencilAlt className="rowInfoIcon" onClick={() => setEditEnabled(true)}/>
                || isEditable && <button className="rowInfoButton" onClick={() => updateData()}>Simpan</button>
            }
        </div>
    )
}

RowInfoEditLocation.defaultProps = {
    type: "text",
    isEditable: true
}

export default RowInfoEditLocation
