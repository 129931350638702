import React, { useEffect, useState } from 'react'
import ButtonPrimary from '../../components/ButtonPrimary'
import InputPrimary from '../../components/input/InputPrimary'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import SuccessLayout from '../../components/successlayout/SuccessLayout'
import './reservation.css'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { DatePicker, Spin } from 'antd'
import GGDatePicker from '../../components/datepicker/GGDatePicker'
import GGTimePicker from '../../components/timepicker/GGTimePicker'
import { ERROR, getJsonResponse, ggAlert, headerNoUrlEncodedBearer, INFO, url } from '../../service/Service'
import moment from 'moment'

const Reservation = (props) => {

    const history = useHistory()
    const {state} = props.location

    const [isConfirmed, setConfirmation] = useState(false)
    const [buttonText, setButtonText] = useState("Ajukan Reservasi")
    const [loading, setLoading] = useState(false)

    const onButtonClicked = () => {
        if (isConfirmed) {
            history.go(-2)
            return
        }
        if (date === "" || time === "" || number === 0) {
            ggAlert("Info", "Lengkapi data anda!", INFO)
        } else {
            createReservation()
        }
    }

    async function createReservation() {
        setLoading(true)
        var formData = new FormData()
        formData.append("id_outlet", state.id)
        formData.append("tanggal_mulai", moment(date).format("yyyy-MM-DD"))
        formData.append("jam_reservasi", time)
        formData.append("jumlah_orang", number)
        formData.append("idkonsumen", localStorage.getItem("userId"))

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }

        await fetch(url + "horeca/reservasi", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setConfirmation(true)
                setButtonText("Kembali Ke Beranda")
            })
            .catch(error => {
                ggAlert("Error", error, ERROR)
            })
        setLoading(false)
    }

    const [date, setDate] = useState(format(new Date(), 'yyyy/MM/dd'))
    const [time, setTime] = useState("")
    const [number, setNumber] = useState(0)

    const formatedDate = format(new Date(date), 'yyyy MMM dd')

    useEffect(() => {
        console.log(state)
    }, [])

    return (
        <div className="reservation-container">
            <NavbarDetail
                title="RESERVASI"/>

            <div className="reservation-container-2">

                <div className="reservation-location-container">
                    <p className="reservation-location-name">{state && state.data.outlet_name}</p>
                    <p className="reservation-location-address">{state && state.data.address + ", " + state.data.kecamatan + ", " + state.data.kabupaten_kota}</p>
                    {
                        isConfirmed && 
                        <div className="reservation-data-container">
                            <p className="reservation-data-name">{formatedDate} - {time}</p>
                            <p className="reservation-data">+ {number} pax</p>
                        </div>
                    }
                </div>
                {
                    isConfirmed && 
                    <SuccessLayout
                        title="Terima Kasih!"
                        message="Dimohon menunggu hingga merchant selesai memproses reservasi and"/>
                    ||
                    <>
                        <div className="reservation-serparator"/>

                        <GGDatePicker
                            label="Tanggal Reservasi"
                            startDate={date}
                            onChange={setDate}/>

                        <div className="reservation-serparator"/>

                        <GGTimePicker
                            label="Waktu Reservasi"
                            onChange={setTime}/>
                        {/* <InputPrimary
                            placeholder="Watu Reservasi"
                            type="text"
                            onChange={setTime}/> */}
                        
                        <div className="reservation-serparator"/>

                        <InputPrimary
                            placeholder="Jumlah Pax/Orang"
                            type="number"
                            onChange={setNumber}/>

                    </>
                }
            </div>

            <div className="reservation-button-container">
                {
                    loading && <Spin/>
                    ||
                    <ButtonPrimary
                        text={buttonText}
                        onClick={() => onButtonClicked()}/>
                }
            </div>
        </div>
    )
}

export default Reservation
