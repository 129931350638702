import React from 'react'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import './term-agreement.css'

const TermAgreement = () => {
    return (
        <div className="gg-parent-container">
            <NavbarDetail
                title="Syarat &amp; Ketentuan"/>

            <div className="gg-content-container">
                <div className="term-agreement-container">
                    {/* <p className="term-agreement-title">Syarat &amp; Ketentuan</p> */}
                    <p className="term-agreement-paragrapf">Saya perokok dewasa berumur 18 tahun atau lebih yang berdomisili di Indonesia. 
-                        Berminat untuk menerima materi promosi dan informasi tentang peluncuran merek, perubahan kemasan,
-                        acara, kegiatan pemasaran, peraturan produk tembakau dan yang mungkin relevan bagi produk tembakau 
-                        dari PT. Gudang Garam Tbk. 
-                        dan atau afiliasinya di masa mendatang. 
-                        Saya menyetujui bahwa PT. Gudang Garam Tbk.
-                        dan atau afiliasinya dapat memasukkan data ini dalam database pemasarannya dan menggunakannya.
-                        PT. Gudang Garam Tbk. berkomitmen untuk menjaga dan melindungi kerahasiaan data pribadi Anda. 
-                        Data yang Anda berikan tidak akan di perjualbelikan atau
-                        dipindahtangankan kepada pihak yang tidak berkepentingan dengan PT. Gudang Garam Tbk. dan atau afiliasinya. 
-                        Anda akan diberikan kesempatan untuk menghapus nama Anda dari database kami di masa mendatang.</p>
                </div>
            </div>
        </div>
    )
}

export default TermAgreement
