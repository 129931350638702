import React from 'react'
import './promo-row.css'
import { Link } from 'react-router-dom'
import { PATH_PROMO_DETAIL } from '../../const/Pathname'

const PromoRow = ({data, pending, type, isViewOnly}) => {
    return (
        isViewOnly &&
        <div className={pending && "promo-row-pending-container" || "promo-row-container"}>
            <img src={data.image} className="promo-row-image"/>
            <div className="promo-row-image-transparent"/>
            <p className="promo-row-title">{data.title}</p>
            <p className="promo-row-time">{data.time}</p>
        </div>
        ||
        <Link to={{
            pathname: PATH_PROMO_DETAIL + "/" + type,
            state: {data : data}
        }} className={pending && "promo-row-pending-container" || "promo-row-container"}>
            <img src={data.image} className="promo-row-image"/>
            <div className="promo-row-image-transparent"/>
            <p className="promo-row-title">{data.title}</p>
            <p className="promo-row-time">{data.time}</p>
        </Link>
    )
}

PromoRow.defaultProps = {
    pending: false,
    type: "detail"
}

export default PromoRow
