import React from 'react'
import { isPropertyAccessExpression } from 'typescript'
import './category-item.css'
import CategoryItemCheckBox from './CategoryItemCheckBox'

const CategoryItem = ( {datas} ) => {

    return (
        <div className="categoryItemContainer">
            <p className="categoryItemTitleLabel">Select your catalogue items :</p>
            <div className="categoryItemLine"/>
            <div className="categoryItemContainer2">
                {datas.map(function(data, i){
                    return <CategoryItemCheckBox
                        isChecked={data.isChecked}
                        label={data.label}
                        key={i}/>
                })}
            </div>
        </div>
    )
}

CategoryItem.defaultProps = {
    datas: [
        {label: String, isChecked: Boolean}
    ]
}

export default CategoryItem
