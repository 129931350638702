import React, { useState } from 'react'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import './otp.css'
import OTPInput from '../../components/otp-input/Index'
import ButtonPrimary from '../../components/ButtonPrimary'
import { Link } from 'react-router-dom'
import OtpV2 from '../otpv2/OtpV2'
import { useHistory } from 'react-router-dom'
import { ERROR, getJsonResponse, ggAlert, SUCCESS, url } from '../../service/Service'

const Otp = (props) => {

    let history = useHistory()
    const {state} = props.location
    const [isLoading, setLoading] = useState(false)

    async function onVerification(otp) {
        setLoading(true)
        var formData = new FormData()
        formData.append("phone", state.phone)
        formData.append("otp_code", otp)
        const requestOptions = {
            method: 'POST',
            body: formData
        }
        await fetch(url + "otp/verify", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                history.push({
                    pathname: "/reset-password",
                    state: { phone: state.phone, otp: otp }
                })
            })
            .catch(error => {
                ggAlert("Error", error, ERROR)
            })
        setLoading(false)
    }

    async function onResendOTP() {
        var formData = new FormData()
        formData.append("phone", state.phone)
        const requestOptions = {
            method: 'POST',
            body: formData
        }
        await fetch(url + "sendOTP", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                ggAlert("Success", json['message'], SUCCESS)
            })
            .catch(error => {
                ggAlert("Error", error, ERROR)
            })
    }

    return (
        <div className="otpContainer">
            <NavbarDetail
            title="MASUKKAN OTP"/>
            <div className='otpContentContainer'>
                <OtpV2 onVerification={onVerification} onResendOTP={onResendOTP} phoneNumber={state.phone}/>
            </div>
        </div>
    )
}

export default Otp
