import React, { useEffect, useState } from 'react'
import ButtonPrimary from '../../components/ButtonPrimary'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import '../promodetail/promo-detail.css'
import { useParams, useHistory } from 'react-router-dom'
import { getJsonResponse, headerNoUrlEncodedBearer, url } from '../../service/Service'
import moment from 'moment'
import { TYPE_DETAIL, TYPE_HORECA_LIST, TYPE_STORE_FROM_REWARD } from '../../const/ParamType'

const RewardDetail = (props) => {

    const { type } = useParams()
    const { state } = props.location
    const history = useHistory()
    const [data, setData] = useState([])
    const [isClaimed, setClaimed] = useState(false)

    const onButtonClick = () => {
        history.push({
            pathname: "/reward-horeca-list/horeca-reward-related",
            state: { id: data['idprogram'], transactionId: state.id, program_name: data['paket_detail']['program_name'] }
        })
    }

    async function getDetail() {
        if (state) {
            await fetch(url + "detailtransactionreward/" + state.id, { headers: headerNoUrlEncodedBearer })
                .then(response => {
                    return getJsonResponse(response)
                })
                .then(json => {
                    setData(json['data'])
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    useEffect(() => {
        getDetail()
    }, [])

    return (
        <div className="promo-detail-container">
            <NavbarDetail
                title="RINCIAN REWARD"/>

            <div className="promo-detail-container-2">
                <img className="promo-detail-image" src={ data && data['image'] || "../../logo512.png"}/>

                {
                    data && data['paket_detail'] &&
                    <>
                        <div className="promo-detail-content-container">
                            <p className="promo-detail-title">{data && data['paket_detail'] && data['paket_detail']['program_name']}</p>
                            <p className="promo-detail-expired">Berlau hingga {data && data['paket_detail'] && moment(data['paket_detail']['start_program']).format("DD MMM yyyy")} sampai {data&& data['paket_detail'] && moment(data['paket_detail']['end_program']).format("DD MMM yyyy")}</p>
                            <p className="promo-detail-message">{data && data['paket_detail'] && data['paket_detail']['mekanisme_progam']}</p>
                        </div>
                    </>
                }

            </div>

            <div className="promo-detail-button-container">
                {
                    type === TYPE_DETAIL && <></>
                    || 
                    <ButtonPrimary
                    text={
                        type === TYPE_HORECA_LIST && "Lihat Merchant Terdekat"
                        || isClaimed && type === TYPE_STORE_FROM_REWARD && "Reward sudah diklaim" 
                        || type === TYPE_STORE_FROM_REWARD && "Klaim Reward"
                    }
                    active={
                        type === TYPE_STORE_FROM_REWARD && !isClaimed
                        || type === TYPE_DETAIL || false
                    }
                    onClick={() => onButtonClick()}/>
                }
            </div>
        </div>
    )
}

export default RewardDetail
