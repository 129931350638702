import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import './reservation-merchant-row.css'
import { Link } from 'react-router-dom'

const ReservationMerchantRow = ({data, isActive, isViewOnly}) => {
    return (
        isViewOnly &&
        <div className="reservation-merchant-row-container">
            <div className="reservation-merchant-row-container-2">
                <div className="reservation-merchant-row-container-3">
                    <p className="reservation-merchant-row-name">{data.name}</p>
                    <p className="reservation-merchant-row-phone">{data.phone}</p>
                </div>
                <FaChevronRight className="reservation-merchant-row-icon"/>
            </div>
            <div className="reservation-merchant-row-line"/>
            <div className="reservation-merchant-row-container-2">
                <p className={isActive && "reservation-merchant-row-time" || "reservation-merchant-row-time-disable"}>{data.time}</p>
                <p className={isActive && "reservation-merchant-row-number" || "reservation-merchant-row-number-disable"}>{data.number} pax</p>
            </div>
        </div>
        ||
        <Link className="reservation-merchant-row-container" to="/reservation-confirmation">
            <div className="reservation-merchant-row-container-2">
                <div className="reservation-merchant-row-container-3">
                    <p className="reservation-merchant-row-name">{data.name}</p>
                    <p className="reservation-merchant-row-phone">{data.phone}</p>
                </div>
                <FaChevronRight className="reservation-merchant-row-icon"/>
            </div>
            <div className="reservation-merchant-row-line"/>
            <div className="reservation-merchant-row-container-2">
                <p className={isActive && "reservation-merchant-row-time" || "reservation-merchant-row-time-disable"}>{data.time}</p>
                <p className={isActive && "reservation-merchant-row-number" || "reservation-merchant-row-number-disable"}>{data.number} pax</p>
            </div>
        </Link>    
    )
}

ReservationMerchantRow.defaultProps = {
    isActive: true
}

export default ReservationMerchantRow
