import { FaRegComment, FaWhatsapp, FaPhone, FaChevronRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const VerificationOption = () => {

    const onClick = (message) => {
        alert(message)
    }

    return (
        <div>
            <Link to='/otp'>
                <div style={optionContainerStyling}>
                    <FaRegComment style={iconStyling}/>
                    <div style={textContainer}>
                        <p style={titleStyling}>One Time Password via SMS</p>
                    </div>
                    <FaChevronRight style={iconStyling}/>
                </div>
            </Link>
            <div style={separatorStyling}/>
            <Link to='/otp'>
                <div style={optionContainerStyling}>
                    <FaWhatsapp style={iconStyling}/>
                    <div style={textContainer}>
                        <p style={titleStyling}>One Time Password via WhatsApp</p>
                    </div>
                    <FaChevronRight style={iconStyling}/>
                </div>
            </Link>
            <div style={separatorStyling}/>
            <Link to='/otp'>
                <div style={optionContainerStyling}>
                    <FaPhone style={iconStyling}/>
                    <div style={textContainer}>
                        <p style={titleStyling}>Verify via phone call</p>
                        <p style={descStyling}>It's okay to not pick up this call</p>
                    </div>
                    <FaChevronRight style={iconStyling}/>
                </div>
            </Link>
        </div>
    )
}

const optionContainerStyling = {
    cursor: 'pointer',
    background: '#C5C5C544',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    marginLeft: 20,
    marginRight: 20,
    height: 60,
    paddingLeft: 20,
    paddingRight: 20
}

const textContainer = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: 'none'
}

const separatorStyling = {
    marginTop: 20
}

const iconStyling = {
    color: 'white',
    fontSize: 20,
    background: 'none'
}

const titleStyling = {
    color: 'white',
    fontSize: 14,
    textAlign: 'left',
    background: 'none',
    paddingLeft: 20,
    paddingRight: 20,
    fontWeight: 600
}

const descStyling = {
    color: 'gray',
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'left',
    background: 'none',
    fontSize: 12
}

export default VerificationOption
