import React from 'react'
import { useHistory } from 'react-router-dom'
import { PATH_ARTICLE_DETAIL } from '../../const/Pathname'

export const ArticleRow = ({data}) => {

    const history = useHistory()

    const onClick = () => {
        history.push({
            pathname: PATH_ARTICLE_DETAIL,
            state: { data: data }
        })
    }

    return (
        <div className="home-article-item-container" key={data.id}
            onClick={() => onClick()}>
            <img
                className="home-article-item-thumbnail"
                src={data.image}/>
            <div className="home-article-item-content-container">
                <p className="home-article-item-title">{data.title}</p>
                <div className="home-article-item-text" dangerouslySetInnerHTML={ {__html: data.content }} />
                <p className="home-article-item-date">{data.time && data.time || "-"}</p>
            </div>
        </div>
    )
}
