import { TimePicker } from 'antd';
import React, { useState } from 'react'
import './gg-time-picker.css'
import moment from 'moment'
import { format } from 'date-fns'

const GGTimePicker = ( { label, onChange, defaultValue } ) => {

    return (
        <div className="gg-date-picker-container">
            <p className="gg-date-picker-label">{label}</p>
            <TimePicker
                onChange={(time) => onChange(format(new Date(time), "HH:mm"))} 
                defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                defaultValue={defaultValue && moment(defaultValue, 'HH:mm:ss')}
                format="HH:mm" />
        </div>
    )
}

export default GGTimePicker
