import React, { useState } from 'react'
import { FaChevronRight, FaEdit, FaPencilAlt, FaPencilRuler, FaRegEdit } from 'react-icons/fa'
import InputPrimary from '../input/InputPrimary'
import './row-info.css'
import { format } from 'date-fns'
import { DatePicker } from 'antd'
import moment from 'moment'
import { getJsonResponse, headerBearer, headerNoUrlEncodedBearer, url } from '../../service/Service'

const RowInfoDate = ({ keyRequest, label, value, currentValueDateFormat, isEditable }) => {

    const [isEditEnabled, setEditEnabled] = useState(false)
    const [defaultValue, setDefaultValue] = useState(value)

    const onChange = (e) => {
        setDefaultValue(moment(e).format("DD MMM yyyy"))
    }

    async function updateData() {
        setEditEnabled(false)
        var formData = new FormData()
        formData.append(keyRequest, moment(defaultValue).format("yyyy-MM-DD"))
        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }

        await fetch(url + "account/setting", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                console.log(json)
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className="rowInfoContainer">
            <div className="rowInfoContainer2">
                <p className="rowInfoLabel">{label}</p>
                {
                    isEditable && isEditEnabled 
                    && <div className="rowInfoDateContainer">
                        <DatePicker
                            defaultValue={moment(defaultValue)}
                            onChange={(date) => onChange(date)}
                            format="DD MMM yyyy"/>
                    </div>
                    || value && <p className="rowInfoValue">{defaultValue}</p>
                }
            </div>
            {
                isEditable && !isEditEnabled && <FaPencilAlt className="rowInfoIcon" onClick={() => setEditEnabled(true)}/>
                || isEditable && <button className="rowInfoButton" onClick={() => updateData()}>Simpan</button>
            }
        </div>
    )
}

RowInfoDate.defaultProps = {
    isEditable: true
}

export default RowInfoDate
