import './profile.css'
import ProfileUser from './ProfileUser'
import ProfileMerchant from './ProfileMerchant'

const Profile = () => {

    return (
        <>
        {
            localStorage.getItem("role") === "user" && <ProfileUser/> || <ProfileMerchant/>
        }
        </>
    )
}

export default Profile
