export const PATH_PROMO_DETAIL = "/promo-detail"
export const PATH_RESTAURANT_LIST = "/restaurant-list"
export const PATH_ACTIVITY_HISTORY = "/activity-history"
export const PATH_PACKAGE_MERCHANDISE = "/package-and-merchandise"
export const PATH_SCAN_QR_REWARD_MERCHANT = "/scan-qr-reward-merchant"
export const PATH_REWARD_HORECA_LIST = "/reward-horeca-list"
export const PATH_REWARD_DETAIL = "/reward-detail"
export const PATH_FAQ = "/faq"
export const PATH_ARTICLE_ALL = "/article-all"
export const PATH_ARTICLE_DETAIL = "/article-detail"
export const PATH_VIDEO_ALL = "/gallery-all"
export const PATH_VENUE_CONFIG = "/venue-config"
export const CHANGE_POINT = "/change-point"
export const CHANGE_POINT_USER = "/change-point-user"
export const TRANSACTION_CONFIRMATION = "/transaction-confirmation"