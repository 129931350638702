import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import InputPrimary from '../../../components/input/InputPrimary'
import GenderModal from '../../../components/gendermodal/GenderModal'
import './step1.css'
import ProductModal from '../../../components/selectproductmodal/ProductRadioModal'
import GGDatePicker from '../../../components/datepicker/GGDatePicker'
import { format } from 'date-fns'
import moment from 'moment'
import InputAutoSearch from '../../../components/input/InputAutoSearch'
import { url } from '../../../service/Service'

const Step1 = ({ onDataChanged, data }) => {

    var provinces = []
    var provincesMap = new Map()
    var cities = []
    var citiesMap = new Map()

    async function fetchProvince() {
        await fetch(url + "provinces")
        .then(response => response.json())
        .then(json => {
            console.log(json)
            json.data.map(function(d, i){
                provinces[i] = d.name
                if (!provincesMap.has(d.name)) {
                    provincesMap.set(d.name, d.id)
                }
            })
        })
        .catch(error => console.log(error))
    }

    useEffect(() => {     
        fetchProvince()
    });

    const getCity = (provinceId) => {
        
        fetch(url + "cities/" + provinceId)
            .then(response => response.json())
            .then(json => {
                json.data.map(function(d, i){
                    cities[i] = d.name
                    if (!citiesMap.has(d.name)) {
                        citiesMap.set(d.name, d.id)
                    }
                })
            })
            .catch(error => console.log(error))
    }

    const [isShowGenderModal, setShowGenderModal] = useState(false)
    const [isShowProductModal, setShowProductModal] = useState(false)

    const onGenderCancel = () => {
        setShowGenderModal(!isShowGenderModal)
    }

    const onGenderOk = (gender) => {
        setShowGenderModal(!isShowGenderModal)
        data.gender = gender
        onDataChanged(data)
    }

    const onProductCancel = () => {
        setShowProductModal(!isShowProductModal)
    }

    const onProductOk = (selectedItem, selectedItemName) => {
        setShowProductModal(!isShowProductModal)
        data.selectedItem = selectedItem
        data.selectedItemName = selectedItemName
        console.log(selectedItemName)
        console.log(selectedItem)
        onDataChanged(data)
    }
    
    const setDate = (value) => {
        data.date = moment(value).format("yyyy-MM-DD")
        onDataChanged(data)
    }

    const onSelectedProvinces = (province) => {
        data.province = provincesMap.get(province)
        onDataChanged(data)
        getCity(provincesMap.get(province))
    }

    const onSelectedCity = (city) => {
        data.city = citiesMap.get(city)
        onDataChanged(data)
    }

    return (
        <>
            {/* <p className="registerStep1Label">Semua form yang ditandai wajib diisi</p> */}
            <div className="registerSeparator"/>
            <InputPrimary
                placeholder="Nama Lengkap"
                type="text"
                onChange={(e) => 
                {
                    data.fullname = e
                    onDataChanged(data)}
                }/>
            <div className="registerSeparator"/>
            <GGDatePicker
                label="Tanggal Lahir"
                startDate={data.date}
                onChange={setDate}/>
            <div className="registerSeparator"/>
            <div 
                className="registerOptionContainer"
                onClick={() => setShowGenderModal(!isShowGenderModal)}>
                <div className="registerOptionContainer2">
                    <div className="registerOptionContainer3">
                        <p className="registerOptionLabel">Jenis Kelamin</p>
                        <p className="registerOptionValue">
                            {
                                data.gender === "-1" && "Tap disini untuk memilih"
                                || data.gender === "1" && "Laki-laki"
                                || data.gender === "0" && "Perempuan"
                            }
                        </p>
                    </div>
                    <FaChevronRight className="registerOptionIcon"/>
                </div>
            </div>
            <div className="registerSeparator"/>
            <InputPrimary
                placeholder="Nomor Telepon"
                type="number"
                onChange={(e) => 
                {
                    data.phoneNumber = e
                    onDataChanged(data)}
                }/>
            <div className="registerSeparator"/>
            <InputPrimary
                placeholder="Email (opsional)"
                type="text"
                onChange={(e) => 
                {
                    data.email = e
                    onDataChanged(data)}
                }/>
            <div className="registerSeparator"/>
            <InputPrimary
                placeholder="Password"
                type="password"
                onChange={(e) => 
                {
                    data.password = e
                    onDataChanged(data)}
                }/>
            <div className="registerSeparator"/>
            <InputPrimary
                placeholder="Konfirmasi Password"
                type="password"
                onChange={(e) => 
                {
                    data.confirmationPassword = e
                    onDataChanged(data)}
                }/>
            <div className="registerSeparator"/>
            <div 
                className="registerOptionContainer"
                onClick={() => setShowProductModal(!isShowProductModal)}>
                <div className="registerOptionContainer22">
                    <div className="registerOptionContainer3">
                        <p className="registerOptionLabel">Produk Saat Ini</p>
                        <p className="registerOptionValue">Tap disini untuk memilih</p>
                    </div>
                    <FaChevronRight className="registerOptionIcon"/>
                </div>
                {
                    <p className="registerSelectedProduct">Produk Dipilih : <b style={{background: 'none'}}>{
                        data.selectedItemName && data.selectedItemName || "Anda belum memilih produk"
                    }</b></p>
                }
                
            </div>
            <div className="registerSeparator"/>
            <InputAutoSearch
                label="Provinsi"
                placeholder="Cari Provinsi ..."
                suggestions={provinces}
                onSelected={(data) => onSelectedProvinces(data)}/>
            <div className="registerSeparator"/>
            <InputAutoSearch
                label="Kabupaten/Kota"
                placeholder="Cari Kabupaten/Kota ..."
                suggestions={cities}
                onSelected={(data) => onSelectedCity(data)}/>
            <div className="registerSeparator"/>
            <GenderModal
                isShowModal={isShowGenderModal}
                key="gender"
                onCancel={() => onGenderCancel()}
                onOk={(gender) => onGenderOk(gender)}/>
            <ProductModal
                isShowModal={isShowProductModal}
                onCancel={() => onProductCancel()}
                onOk={(selectedItem, selectedItemName) => onProductOk(selectedItem, selectedItemName)}/>
        </>
    )
}

export default Step1
