import { Modal, Radio } from 'antd'
import React, { useEffect, useState } from 'react'
import { getJsonResponse, url } from '../../service/Service'
import ButtonPrimary from '../ButtonPrimary'
import ButtonSecondary from '../ButtonSecondary'
import './product-modal.css'

const ProductModal = ({ isShowModal, onCancel, onOk }) => {

    const [datas, setData] = useState([])
    var selectedItemName = ""
    var selectedItem = 0

    useEffect(() => {
        getProduct()
    }, [])

    async function getProduct() {
        await fetch(url + "produkgg")
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setData(json['data'])
            })
            .catch(error => console.log(error))
    }

    const onItemSelected = e => {
        var d = e.target.value.split("|-|")
        selectedItem = d[0]
        selectedItemName = d[1]
    }

    return (
        <Modal 
            title="PRODUK PILIHAN" 
            visible={isShowModal} 
            onOk={() => onOk != null && onOk(selectedItem, selectedItemName)} 
            onCancel={() => onCancel != null && onCancel()}
            footer={[
                <div className="select-product-modal-footer-container">
                    <ButtonSecondary
                        text="Kembali" 
                        key="back" 
                        onClick={() => onCancel != null && onCancel()}/>,
                    <div className="select-product-modal-button-separator"/>,
                    <ButtonPrimary 
                        text="Simpan"
                        key="submit" 
                        type="primary" 
                        onClick={() => onCancel != null && onOk(selectedItem, selectedItemName)}/>
                </div>
            ]}>
            <Radio.Group className="select-product-modal-option-container" onChange={onItemSelected}>
                {
                    datas.map(function(data, i) {
                        return <>
                            <div className="select-product-modal-option"
                                key={i}>
                                <Radio
                                    className="select-product-modal-radio"
                                    value={data['id'] + "|-|" + data['name']}>{data['name']}</Radio>
                            </div>
                            <div className="select-product-modal-line"/>
                        </>
                    })
                }
            </Radio.Group>
        </Modal>
    )
}

export default ProductModal
