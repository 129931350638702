import React from 'react'
import ButtonPrimary from '../../../components/ButtonPrimary'
import ScheduleRow from '../../../components/schedulerow/ScheduleRow'
import './schedule.css'

const Schedule = ({data, onChange, onEditClicked}) => {

    return (
        <>
            <div className="schedule-container-2">
                {
                    data.map(function(d) {
                        return <ScheduleRow
                            data={d}
                            onChange={onChange}/>
                    })
                }
            </div>
            <div className="schedule-container-edit">
                <ButtonPrimary
                    text="Ubah"
                    onClick={() => onEditClicked()}/>
            </div>
        </>
    )
}

export default Schedule
