import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import './inbox.css'

const InboxRowReservation = ({data, isActive}) => {
    return (
        <Link className="reservation-merchant-row-container" to={{
            pathname: "/reservation-confirmation",
            state: {data : data, isActive: isActive}
        }}>
            <div className="reservation-merchant-row-container-2">
                <div className="reservation-merchant-row-container-3">
                    <p className="reservation-merchant-row-name">{data.name}</p>
                    <p className="reservation-merchant-row-phone">{data.phone}</p>
                </div>
                <FaChevronRight className="reservation-merchant-row-icon"/>
            </div>
            <div className="reservation-merchant-row-line"/>
            <div className="reservation-merchant-row-container-2">
                <p className="reservation-merchant-row-time">{data.time}</p>
                <p className="reservation-merchant-row-number">{data.number} pax</p>
            </div>
        </Link>
    )
}

InboxRowReservation.defaultProps = {
    isActive: true
}

export default InboxRowReservation
