import { useState } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import './input-primary.css'

const InputAutoSearchProfile = ({ label, placeholder, gap, suggestions, onSelected }) => {

    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [input, setInput] = useState("");

    const onChange = (e) => {
        const userInput = e.target.value;
    
        // Filter our suggestions that don't contain the user's input
        const unLinked = suggestions.filter(
          (suggestion) =>
            suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );
    
        setInput(e.target.value);
        setFilteredSuggestions(unLinked);
        setActiveSuggestionIndex(0);
        setShowSuggestions(true);
      };

      const onClick = (e) => {
        setFilteredSuggestions([])
        setInput(e.target.innerText)
        if (onSelected != null)
            onSelected(e.target.innerText)
        setActiveSuggestionIndex(0)
        setShowSuggestions(false)
      };

      const SuggestionsListComponent = () => {
        return filteredSuggestions.length ? (
          <ul class="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;
              // Flag the active suggestion with a class
              if (index === activeSuggestionIndex) {
                className = "suggestion-active";
              }
              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        ) : (
          <div class="no-suggestions">
            <em>Data Tidak Ditemukan!</em>
          </div>
        );
      };

    return (
        <div className="inputPrimaryContainer">
            <div className='inputPrimaryLocationContainer2'
            style={{marginTop: gap}}>
                <input
                    className="inputPrimaryAutoComplete"
                    type="text"
                    onChange={onChange}
                    placeholder={placeholder}
                    value={input}
                />
                {showSuggestions && input && <SuggestionsListComponent />}
                <span className='inputPlaceholderAutoComplete'>{label}</span>
            </div>
        </div>
    )
}

InputAutoSearchProfile.defaultProps = {
    placeholder: 'test',
    type: 'text',
    gap: 0
}

export default InputAutoSearchProfile
