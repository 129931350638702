import React from 'react'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import './point-community.css'

const PointCommunity = () => {
    return (
        <div className="gg-parent-container">
            <NavbarDetail
                title="POIN KOMUNITAS"/>

            <div className="gg-content-container">
                <div className="point-community-container">
                    <p className="point-community-title">Poin Komunitas anda</p>
                    <p className="point-community-point">0 poin</p>
                    <p className="point-community-desc">Silahkan menghubungi Ambassador komunitas anda untuk menukar Poin Komunitas dengan merchandise khusus komunitas!</p>
                </div>
            </div>
        </div>
    )
}

export default PointCommunity
