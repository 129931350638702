import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import PromoRow from '../../components/promorow/PromoRow'
import { getJsonResponse, headerNoUrlEncodedBearer, url } from '../../service/Service'
import moment from 'moment'

const PromoAll = (props) => {

    const {state} = props.location
    const [loading, setLoading] = useState(false)
    const [promos, setPromos] = useState([])

    const datas = [
        {title: "Bonus Coca-Cola dengan Pembelian Gudang Garam Filter International", time: "Berlaku Hingga 24 Mei 2021"},
        {title: "New member Only: Bonus 1000 Loyalty Poin", time: "Berlaku Hingga 24 Mei 2021"},
    ]

    async function getPackage() {
        setLoading(true)
        await fetch(url + "paketpromo", {headers: headerNoUrlEncodedBearer})
            .then(response => getJsonResponse(response))
            .then(json => {
                setPromos([])
                json['data']['cpo'].map(function(d) {
                    setPromos(arr => [...arr, {
                        title: d.program_name,
                        time: moment(d.start_program).format("DD MMM yyyy") + " - " + moment(d.end_program).format("DD MMM yyyy"),
                        image: d.image
                    }])
                })
                json['data']['loyalty'].map(function(d) {
                    setPromos(arr => [...arr, {
                        title: d.program_name,
                        time: moment(d.start_program).format("DD MMM yyyy") + " - " + moment(d.end_program).format("DD MMM yyyy"),
                        image: d.image
                    }])
                })
                json['data']['loyalty_berjenjang'].map(function(d) {
                    setPromos(arr => [...arr, {
                        title: d.program_name,
                        time: moment(d.start_program).format("DD MMM yyyy") + " - " + moment(d.end_program).format("DD MMM yyyy"),
                        image: d.image
                    }])
                })
            })
            .catch(error => {
                console.log(error)
            })
        setLoading(false)
    }

    useEffect(() => {
        getPackage()
    }, [])

    return (
        <div className="gg-parent-container">
            <NavbarDetail
                title={state && state.type === "package" && "DAFTAR PAKET" || "DAFTAR PROMO"}/>
            <div className="gg-content-container">
                {
                    loading && <Spin/>
                    ||
                    promos.map(function(data, i){
                        return <PromoRow data={data} type="horeca-list"/>
                    })
                }
                <div style={{width: '100%', marginTop: '50px', display: 'flex'}}/>
            </div>
        </div>
    )
}

export default PromoAll
