import React from 'react'
import './promo-use.css'

const PromoUse = ({label, name}) => {
    return (
        <div className="promo-use-container">
            <p className="promo-use-label">{label}</p>
            <p className="promo-use-name">{name}</p>
        </div>
    )
}

PromoUse.defaultProps = {
    label: "Promo",
    name: "New Member Only: Diskon 50% untuk pembelian di Merchant Pilihan Anda"
}

export default PromoUse
