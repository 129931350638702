import { Radio } from 'antd'
import React, { useState, useEffect } from 'react'
import { FaChevronRight, FaEdit, FaPencilAlt, FaPencilRuler, FaRegEdit } from 'react-icons/fa'
import { getJsonResponse, headerBearer, headerNoUrlEncodedBearer, url } from '../../service/Service'
import InputPrimary from '../input/InputPrimary'
import './row-info.css'

const RowInfoGender = ({ keyRequest, label, value, type, isEditable }) => {

    const [isEditEnabled, setEditEnabled] = useState(false)
    const [defaultValue, setDefaultValue] = useState(value)
    const[gender, setGender] = useState("-1")

    const handleChange = e => {
        setGender(e.target.value)
    } 

    async function updateData() {
        setEditEnabled(false)
        if (!keyRequest) return
        if (gender === "-1") return
        var formData = new FormData()
        var g = ""
        if (gender === "1") {
            g = "Male"
            setDefaultValue("Laki-Laki")
        } else {
            g = "Female"
            setDefaultValue("Perempuan")
        }

        formData.append(keyRequest, g)

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }

        await fetch(url + "account/setting", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                console.log(json)
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div className="rowInfoContainer">
            <div className="rowInfoContainer2">
                <p className="rowInfoLabel">{label}</p>
                <p className="rowInfoValue">{defaultValue}</p>
                {
                    isEditEnabled 
                    && 
                    <div className="rowInfoGenderContainer">
                        <Radio.Group className="rowInfoGenderInput" onChange={handleChange}>
                            <Radio 
                                className="gender-modal-option-text"
                                value="1">Laki-Laki</Radio>
                            <div className="gender-modal-line"/>
                            <Radio
                                className="gender-modal-option-text"
                                value="0">Perempuan</Radio>
                        </Radio.Group>
                    </div>
                }
            </div>
            {
                !isEditEnabled && isEditable && <FaPencilAlt className="rowInfoIcon" onClick={() => setEditEnabled(true)}/>
                || isEditable && <button className="rowInfoButton" onClick={() => updateData()}>Simpan</button>
            }
        </div>
    )
}

RowInfoGender.defaultProps = {
    type: "text",
    isEditable: true
}

export default RowInfoGender
