import React from 'react'
import QRCode from 'react-qr-code'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import './my-qr-code.css'

const MyQRCode = () => {
    return (
        <div className="my-qr-code-container">
            <NavbarDetail
                title="MY QR"/>

            <div className="my-qr-code-container-2">
                <QRCode className="my-qr-code" value={localStorage.getItem("role") === "user" && localStorage.getItem("phone") || JSON.parse(localStorage.getItem("outlet")).outlet_code}/>
                <p className="my-qr-code-text">{localStorage.getItem("role") === "user"  && localStorage.getItem("phone") || JSON.parse(localStorage.getItem("outlet")).outlet_code}</p>
            </div>
        </div>
    )
}

export default MyQRCode
