import React, { useState } from 'react'
import { FaQrcode } from 'react-icons/fa'
import { BiScan } from 'react-icons/bi'
import './bottom-menu.css'
import { useHistory } from 'react-router-dom'

const BottomMenu = ({title}) => {

    const history = useHistory()
    const [selected, setSelected] = useState(0)

    const onClicked = (value) => {
        setSelected(value)
        let type = localStorage.getItem("role")
        if (value == 0) {
            history.push("/my-qr-code")
        } else {
            history.push("/scan-qr/" + type)
        }
    }

    return (
        <div className="bottomMenuContainer">
            <div className="bottomMenuContainer2">
                <div
                    className={ selected == 0 && "bottomMenuItemSelectedContainer" || "bottomMenuItemContainer"}
                    onClick={() => onClicked(0)}>
                    <FaQrcode className={selected == 0 && "bottomMenuIconSelected" || "bottomMenuIcon"}/>
                    <p className={ selected == 0 && "bottomMenuSelectedTitle" || "bottomMenuTitle" }>My QR</p>
                </div>
                <div className="bottomMenuSeparator"/>
                <div to="scan-qr" 
                    className={ selected == 1 && "bottomMenuItemSelectedContainer" || "bottomMenuItemContainer"}
                    onClick={() => onClicked(1)}>
                    <BiScan className={selected == 1 && "bottomMenuIconSelected" || "bottomMenuIcon"}/>
                    <p className={ selected == 1 && "bottomMenuSelectedTitle" || "bottomMenuTitle" }>{title && title || "Scan QR"}</p>
                </div>
            </div>
        </div>
    )
}

export default BottomMenu
