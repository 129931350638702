import './App.css'
import Login from './ui/login/Login'
import ForgotPassword from './ui/forgotpassword/ForgotPassword'
import Verification from './ui/verification/Verification'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Otp from './ui/otp/Otp'
import FillDataCustomer from './ui/filldatacustomer1/FillDataCustomer1'
import FillData2 from './ui/filldata2/FillData2'
import FillDataMerchant from './ui/filldatamerchant1/FillDataMerchant'
import Register from './ui/register/Register'
import Congratulation from './ui/congratulation/Congratulation'
import Home from './ui/home/Home';
import Profile from './ui/profile/Profile'
import Notification from './ui/notification/Notification'
import LoyaltyPoints from './ui/loyaltypoints/LoyaltyPoints'
import BookingList from './ui/bookinglist/BookingList'
import RegisterV2 from './ui/registerv2/RegisterV2'
import OtpV2 from './ui/otpv2/OtpV2'
import ResetPassword from './ui/resetpassword/ResetPassword'
import Agreement from './ui/agreement/Agreement'
import MerchantDetail from './ui/merchantdetail/MerchantDetail'
import BuyPackage from './ui/buypackage/BuyPackage'
import Reservation from './ui/reservation/Reservation'
import ChangePoint from './ui/changepoint/ChangePoint'
import GroceryStoreList from './ui/grocerystorelist/GroceryStoreList'
import RestaurantList from './ui/restaurantlist/RestaurantList'
import ScanQR from './ui/scanqr/ScanQR'
import PromoDetail from './ui/promodetail/PromoDetail'
import PointLoyaltyHistory from './ui/pointloyaltyhistory/PointLoyaltyHistory'
import MyQRCode from './ui/myqrcode/MyQRCode'
import { ProtectedRoute } from './util/protected.route'
import HomeMerchant from './ui/home/HomeMerchant'
import ReservationConfirmation from './ui/reservationconfirmation/ReservationConfirmation'
import ReservationConfirmationFinal from './ui/reservationconfirmationfinal/ReservationConfirmationFinal'
import ReservationHistory from './ui/reservationhistory/ReservationHistory'
import ReservationDetail from './ui/reservationdetail/ReservationDetail'
import PromoManagement from './ui/promomanagement/PromoManagement'
import PromoCreate from './ui/promocreate/PromoCreate'
import Inbox from './ui/inbox/Inbox'
import TransactionConfirmation from './ui/transactionconfirmation/TransactionConfirmation'
import ChangePointConfirmation from './ui/changepointconfirmation/ChangePointConfirmation'
import PromoAll from './ui/promoall/PromoAll'
import PointCommunity from './ui/pointcommunity/PointCommunity'
import TermAgreement from './ui/termagreement/TermAgreement'
import PrivacyPolicy from './ui/termagreement/PrivacyPolicy'
import RewardDetail from './ui/rewarddetail/RewardDetail'
import RewardHorecaList from './ui/rewardhorecalist/RewardHorecaList'
import ScanQRRewardMerchant from './ui/scanqr/ScanQRRewardMerchant'
import PackageAndMerchandise from './ui/paket-and-merchandise/PackageAndMerchandise'
import { CHANGE_POINT, CHANGE_POINT_USER, PATH_ACTIVITY_HISTORY, PATH_ARTICLE_ALL, PATH_ARTICLE_DETAIL, PATH_FAQ, PATH_PACKAGE_MERCHANDISE, PATH_REWARD_DETAIL, PATH_REWARD_HORECA_LIST, PATH_SCAN_QR_REWARD_MERCHANT, PATH_VENUE_CONFIG, PATH_VIDEO_ALL } from './const/Pathname'
import ActivityHistory from './ui/activityhistory/ActivityHistory'
import FAQ from './ui/profile/faq/FAQ'
import ArticleAll from './ui/home/article/ArticleAll'
import ArticleDetail from './ui/home/article/ArticleDetail'
import GalleryAll from './ui/home/video/VideoAll'
import VideoAll from './ui/home/video/VideoAll'
import VenueConfig from './ui/venueconfig/VenueConfig'
import { useEffect, useState } from 'react'
import ChangePointUser from './ui/changepointuser/ChangePointUser'
import ProductWarning from './components/productwarning/ProductWarning'

function App() {
  return (
    <Router>
    <div className="App">
      <div className="app-content-container">
        <Switch>
          <Route path="/" exact 
            render={ props => {
              if (localStorage.getItem("aggrementChecked") === "true") {
                return <Redirect to={
                    {
                        pathname: "/login",
                        state: {
                            from: props.location
                        }
                    }
                }/>
              } else {
                return <Agreement/>
              }
          }}/>
          <Route path="/login" 
            render={ props => {
              if (localStorage.getItem("accessToken") !== null) {
                var path = "/home"
                if (localStorage.getItem("role") === "useroutlet") {
                    path = "/profile"
                }
                return <Redirect to={
                    {
                        pathname: path,
                        state: {
                            from: props.location
                        }
                    }
                }/>
              } else {
                return <Login/>
              }
          }}/>
          <Route path="/forgot-password" component={ForgotPassword}/>
          <Route path="/verification" component={Verification}/>
          <Route path="/otp" component={Otp}/>
          <Route path="/fill-data-customer-1" component={FillDataCustomer}/>
          <Route path="/fill-data-2" component={FillData2}/>
          <Route path="/fill-data-merchant-1" component={FillDataMerchant}/>
          <Route path="/register" component={RegisterV2}/>
          <Route path="/congratulation" component={Congratulation}/>
          <Route path="/reset-password" component={ResetPassword}/>
          <ProtectedRoute path="/home" component={Home}/>
          <ProtectedRoute path="/home-merchant" component={HomeMerchant}/>
          <ProtectedRoute path="/profile" component={Profile}/>
          <ProtectedRoute path="/notification" component={Notification}/>
          <ProtectedRoute path="/loyalty-points" component={LoyaltyPoints}/>
          <ProtectedRoute path="/booking-list" component={BookingList}/>
          <ProtectedRoute path="/merchant-detail/:type" component={MerchantDetail}/>
          <ProtectedRoute path="/buy-package" component={BuyPackage}/>
          <ProtectedRoute path="/reservation" component={Reservation}/>
          <ProtectedRoute path={CHANGE_POINT} component={ChangePoint}/>
          <ProtectedRoute path={CHANGE_POINT_USER} component={ChangePointUser}/>
          <ProtectedRoute path="/grocery-store-list" component={GroceryStoreList}/>
          <ProtectedRoute path="/restaurant-list/:type" component={RestaurantList}/>
          <ProtectedRoute path="/scan-qr/:type" component={ScanQR}/>
          <ProtectedRoute path="/promo-detail/:type" component={PromoDetail}/>
          <ProtectedRoute path="/point-loyalty-history" component={PointLoyaltyHistory}/>
          <ProtectedRoute path="/my-qr-code" component={MyQRCode}/>
          <ProtectedRoute path="/reservation-confirmation" component={ReservationConfirmation}/>
          <ProtectedRoute path="/reservation-confirmation-finish/:type" component={ReservationConfirmationFinal} />
          <ProtectedRoute path="/reservation-history" component={ReservationHistory}/>
          <ProtectedRoute path="/reservation-detail/:type" component={ReservationDetail}/>
          <ProtectedRoute path="/promo-management" component={PromoManagement}/>
          <ProtectedRoute path="/promo-create" component={PromoCreate}/>
          <ProtectedRoute path="/inbox" component={Inbox}/>
          <ProtectedRoute path="/transaction-confirmation" component={TransactionConfirmation}/>
          <ProtectedRoute path="/change-point-confirmation" component={ChangePointConfirmation}/>
          <ProtectedRoute path="/promo-all" component={PromoAll}/>
          <ProtectedRoute path="/point-community" component={PointCommunity}/>
          <Route path="/term-agreement" component={TermAgreement}/>
          <Route path="/privacy-policy" component={PrivacyPolicy}/>
          <ProtectedRoute path={PATH_REWARD_DETAIL} component={RewardDetail}/>
          <ProtectedRoute path={PATH_REWARD_HORECA_LIST} component={RewardHorecaList}/>
          <ProtectedRoute path={PATH_SCAN_QR_REWARD_MERCHANT} component={ScanQRRewardMerchant}/>
          <ProtectedRoute path={PATH_PACKAGE_MERCHANDISE} component={PackageAndMerchandise}/>
          <ProtectedRoute path={PATH_ACTIVITY_HISTORY} component={ActivityHistory}/>
          <ProtectedRoute path={PATH_FAQ} component={FAQ}/>
          <ProtectedRoute path={PATH_ARTICLE_ALL} component={ArticleAll}/>
          <ProtectedRoute path={PATH_ARTICLE_DETAIL} component={ArticleDetail}/>
          <ProtectedRoute path={PATH_VIDEO_ALL} component={VideoAll}/>
          <ProtectedRoute path={PATH_VENUE_CONFIG} component={VenueConfig}/>
        </Switch>
      </div>
      <ProductWarning/>
    </div>
    </Router>
  );
}

export default App;
