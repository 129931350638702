import React from 'react'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import RowNotification from '../../components/rownotification/RowNotification'
import './booking-list.css'

const BookingList = () => {

    const datas = [
        {title: "Covfefe Cafe", address: "Jl. Sunan Kalijaga No.42B, Jakarta Pusat", date: "14 May 2021 - 09:00 PM", amount: 2},
        {title: "Soul Pact Coffee", address: "Sopo Del Tower, First Floor, Jakarta Pusat", date: "21 May 2021 - 07:15 PM", amount: 4},
        {title: "Rick Roll Round Restaurant", address: "Jl. M.H. Thamrin No. 56, Jakarta Pusat", date: "02 April 2021 - 08:30 AM", amount: 2},
        {title: "Covfefe Cafe", address: "Jl. Sunan Kalijaga No.42B, Jakarta Pusat", date: "14 May 2021 - 09:00 PM", amount: 2},
        {title: "Soul Pact Coffee", address: "Sopo Del Tower, First Floor, Jakarta Pusat", date: "21 May 2021 - 07:15 PM", amount: 4},
        {title: "Rick Roll Round Restaurant", address: "Jl. M.H. Thamrin No. 56, Jakarta Pusat", date: "02 April 2021 - 08:30 AM", amount: 2},
        {title: "Covfefe Cafe", address: "Jl. Sunan Kalijaga No.42B, Jakarta Pusat", date: "14 May 2021 - 09:00 PM", amount: 2},
        {title: "Soul Pact Coffee", address: "Sopo Del Tower, First Floor, Jakarta Pusat", date: "21 May 2021 - 07:15 PM", amount: 4},
        {title: "Rick Roll Round Restaurant", address: "Jl. M.H. Thamrin No. 56, Jakarta Pusat", date: "02 April 2021 - 08:30 AM", amount: 2},
        {title: "Covfefe Cafe", address: "Jl. Sunan Kalijaga No.42B, Jakarta Pusat", date: "14 May 2021 - 09:00 PM", amount: 2},
        {title: "Soul Pact Coffee", address: "Sopo Del Tower, First Floor, Jakarta Pusat", date: "21 May 2021 - 07:15 PM", amount: 4},
        {title: "Rick Roll Round Restaurant", address: "Jl. M.H. Thamrin No. 56, Jakarta Pusat", date: "02 April 2021 - 08:30 AM", amount: 2}
    ]

    return (
        <div className="parent">
            <NavbarDetail
                title="BOOKING LIST"/>
            <div className="bookingListContainer">
                {datas.map(function(data, i){
                    return i == 0 && 
                    <RowNotification
                        title={data.title}
                        address={data.address}
                        date={data.date}
                        amount={data.amount}
                        key={i}
                        isToday={true}/>|| 
                    <RowNotification
                        title={data.title}
                        address={data.address}
                        date={data.date}
                        amount={data.amount}
                        key={i}/>
                })}
            </div>
        </div>
    )
}

export default BookingList
