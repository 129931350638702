import React, { useState } from 'react'
import ButtonPrimary from '../../components/ButtonPrimary'
import InputPrimary from '../../components/input/InputPrimary'
import NavbarDetails from '../../components/navbardetail/NavbarDetail'
import './forgot-password.css'
import { Link } from 'react-router-dom'
import { ERROR, getJsonResponse, ggAlert, headerNoUrlEncodedBearer, requestOptions, url } from '../../service/Service'
import { useHistory } from 'react-router-dom'
import { Spin } from 'antd'

const ForgotPassword = () => {

    const [phone, setPhone] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    async function requestOTP() {
        setLoading(true)
        var formData = new FormData()
        formData.append("phone", phone)

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }

        await fetch(url + "sendOTP", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                history.push({
                    pathname: "/otp",
                    state: {type: "forgot", phone: phone}
                })
            })
            .catch(error => {
                ggAlert("Error", error, ERROR)
            })
        setLoading(false)
    }

    return (
        <div className='forgotPasswordContainer'>
            <NavbarDetails title='VERIFIKASI' subTitle="Lupa Password" />
            <p className='forgotPasswordLabel'>Dimohon masukkan nomor telepon anda sebagai langkah verifikasi melalui SMS</p>
            <InputPrimary placeholder='Nomor Telepon / Email' type='text' onChange={(e) => setPhone(e)}/>
            <div className='forgotPasswordRemainingSpace'>
                <div style={{width: 'calc(100% - 40px)'}} to='/otp'>
                    {
                        loading && <Spin/>
                        ||
                        <ButtonPrimary text='Lanjut'
                            onClick={() => requestOTP()}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
