import { Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import NavbarSearchTab from '../../components/navbarsearch/NavbarSearchTab'
import ReservationUserRow from '../../components/reservationmerchantrow/ReservationUserRow'
import { getJsonResponse, headerNoUrlEncodedBearer, url } from '../../service/Service'
import moment from 'moment'

const ReservationHistory = () => {

    const { TabPane } = Tabs;
    const [reservationsActive, setReservationsActive] = useState([])
    const [reservationsHistory, setReservationsHistory] = useState([])
    const [searchPlaceHolder, setSearchPlaceholder] = useState("Cari Transaksi")
    const [searchKeyword, setSearhKeyword] = useState(["", "", ""])
    const [tabPosition, setTabPosition] = useState(0)

    function callback(key) {
        setTabPosition(parseInt(key, 10) - 1)
        switch(key) {
            case "1":
                setSearchPlaceholder("Cari Reservasi Aktif")
                break;
            case "2":
                setSearchPlaceholder("Cari Sejarah Reservasi")
                break;
            default:
                setSearchPlaceholder("Cari Reservasi Aktif")
                break;        
        }
    }

    async function getReservationActive() {

        var endpoint = url + "customer/reservasi"

        if (searchKeyword[0] != "") {
            endpoint = endpoint + "?search=" + searchKeyword[0]
        }

        await fetch(endpoint, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                console.log(json)
                setReservationsActive([])
                json['data'].map(function(d, index) {
                    var data ={
                        outlet_name: d.outlet_name && d.outlet_name || "-", 
                        address: d.address && d.address || "-", 
                        time: moment(d.start_program).format("DD MMM yyyy") + " - " + d.jam_reservasi, 
                        number: d.jumlah_orang,
                        data: d
                    }
                    setReservationsActive(arr => [...arr, data])
                })
            })
            .catch(error => console.log(error))
    }

    async function getReservationHistory() {

        var endpoint = url + "customer/historyreservasi"

        if (searchKeyword[1] != "") {
            endpoint = endpoint + "?search=" + searchKeyword[1]
        }

        await fetch(endpoint, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setReservationsHistory([])
                json['data'].map(function(d, index) {
                    var data ={
                        outlet_name: d.outlet_name && d.outlet_name || "-", 
                        address: d.address && d.address || "-", 
                        time: moment(d.start_program).format("DD MMM yyyy") + " - " + d.jam_reservasi, 
                        number: d.jumlah_orang,
                        data: d
                    }
                    setReservationsHistory(arr => [...arr, data])
                })
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getReservationActive()
        getReservationHistory()
    }, [])

    const onSearch = (keyword) => {
        var newArray = [...searchKeyword]
        newArray[tabPosition] = keyword
        console.log(newArray)
        setSearhKeyword(newArray)
        switch(tabPosition) {
            case 0:
                getReservationActive()
                break;
            case 1:
                getReservationHistory()
                break;
            default:
                getReservationActive()
                break;        
        }
    }

    const onKeywordChanged = (keyword) => {
        var newArray = [...searchKeyword]
        newArray[tabPosition] = keyword
        console.log(newArray)
        setSearhKeyword(newArray)
    }

    return (
        <div className="gg-parent-container">
            <NavbarSearchTab
                placeholder={searchPlaceHolder}
                onSearch={(keyword) => onSearch(keyword)}
                searchKeyword={searchKeyword[tabPosition]}
                onKeywordChanged={(keyword) => onKeywordChanged(keyword)}/>
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Reservasi Aktif" key="1">
                    <div className="gg-tab-content-container">
                        {
                            reservationsActive &&
                            reservationsActive.map(function(data, i){
                                return <ReservationUserRow
                                            data={data}/>
                            })
                        }
                    </div>
                </TabPane>
                <TabPane tab="Sejarah Reservasi" key="2">
                    <div className="gg-tab-content-container">
                        {
                            reservationsHistory &&
                            reservationsHistory.map(function(data, i){
                                return <ReservationUserRow
                                            data={data}
                                            isActive={false}/>
                            })
                        }
                    </div>
                </TabPane>
            </Tabs>

        </div>
    )
}

export default ReservationHistory
