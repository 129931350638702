import React from 'react'
import { FaCircle, FaShapes, FaShoppingBag, FaStar } from 'react-icons/fa'
import { WiStars } from 'react-icons/wi'
import { MdLocalPrintshop } from 'react-icons/md'
import './buy-package-menu.css'

const BuyPackageMenuItem = ({ category, type, onSelected, selected, number }) => {
    return (
        <div className="buy-package-menu-item-container"
            onClick={() => onSelected(number)}>
            <div 
                className={
                    selected && "buy-package-menu-item-selected-container-2" 
                    || "buy-package-menu-item-container-2"
                }>
                {
                    type == "all" && <FaShapes className={selected && "notification-menu-item-selected-icon" || "notification-menu-item-icon"}/>
                    || type == "reguler" && <MdLocalPrintshop className={selected && "notification-menu-item-selected-icon" || "notification-menu-item-icon"}/>
                    || type == "loyalty" && <FaStar className={selected && "notification-menu-item-selected-icon" || "notification-menu-item-icon"}/>
                    || type == "loyalty-2" && <WiStars className={selected && "notification-menu-item-selected-icon" || "notification-menu-item-icon"}/>
                }
                <p className={selected && "buy-package-menu-item-selected-category" || "notification-menu-item-category"}>{category}</p>
            </div>
        </div>
    )
}

export default BuyPackageMenuItem
