import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import './next-button.css'

const NextButton = ( { onClick, active } ) => {
    return (
        <div className="nextButtonContainer" onClick={() => onClick(2)}>
            <p className={active && "nextButtonText" || "nextButtonTextNotActive" }>Lanjut</p>
            <FaChevronRight className={active && "nextButtonIcon" || "nextButtonIconNotActive" }/>
        </div>
    )
}

export default NextButton
