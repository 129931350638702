import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import './store-list.css'
import { Link } from 'react-router-dom'
import { TYPE_STORE_FROM_REWARD } from '../../const/ParamType'

const StoreList = ( {items, isWithImage, type} ) => {

    const toGrocery = (id, program_id, transactionId, address, outlet_name) => { 
        var endPoint = type === TYPE_STORE_FROM_REWARD && "/change-point" || "/merchant-detail/grocery"
        return {
            pathname: endPoint,
            state: { id: id, program_id: program_id, transactionId: transactionId, address: address, outlet_name: outlet_name }
        }
      };

    const toRestaurant = (id, program_id, transactionId, address, outlet_name) => {
        var endPoint = type === TYPE_STORE_FROM_REWARD && "/change-point" || "/merchant-detail/restaurant"
        return {
            pathname: endPoint,
            state: { id: id, program_id: program_id, transactionId: transactionId, address: address, outlet_name: outlet_name }
        }
    }


    return (
        items.map(function(item, i) {
            return <div className="store-list-item-container" key={i}>
                <Link className="store-list-item-container-2" 
                    to={
                        isWithImage && toRestaurant(item.id, item.program_id, item.transactionId, item.address, item.outlet_name) || toGrocery(item.id, item.program_id, item.transactionId, item.address, item.outlet_name)
                    }>
                    <div className="store-list-item-container-3">
                        <p className="store-list-item-name">{item.outlet_name}</p>
                        <p className="store-list-item-address">{item.address}</p>
                        {/* <p className="store-list-item-distance">{0}</p> */}
                    </div>
                    <FaChevronRight
                        className="store-list-item-icon"/>
                </Link>

                {
                    isWithImage &&
                    <>
                        {
                            item.promomerchants && item.promomerchants.length > 0 &&
                            <div className="store-list-item-line"/>
                        }
                        <div className="store-list-item-image-container">
                        {
                            item.promomerchants && item.promomerchants.map(function(promomerchant, j) {
                                if (j >= 4) return
                                return <img className="store-list-item-image" src={promomerchant.image}/>
                            })
                        }
                        </div>
                    </>
                }
            </div>
        })
        
    )
}

StoreList.defaultProps = {
    items: [
        {
            id: 0,
            outlet_name: "",
            address: "", 
            kabupaten_kota: "",
            kecamatan: "",
            kelurahan: "",
            latitude: "",
            longitude: "",
            operatingunitname_id: 0,
            operating_unit_names: "",
            subchannel_id: 0,
            territory_id: 0,
            program_id: 0
        },
    ],
    isWithImage: false
}

export default StoreList
