import React from 'react'
import './home-video.css'
import { useHistory } from 'react-router-dom'
import { PATH_VIDEO_ALL } from '../../const/Pathname'
import { FaPlay } from 'react-icons/fa'

const HomeVideo = ({data}) => {

    const history = useHistory()

    return (
        <div className="home-video-container">
            <div className="home-video-header-container">
                <p className="home-video-title">Galeri</p>
                <p className="home-video-more" onClick={() => history.push(PATH_VIDEO_ALL)}>Lihat Semua</p>
            </div>
            <div className="horizontal-list-container-2">
                <div className="horizontal-list-container-3">
                    {
                        data.map(function(d, index) {
                            if (index >= 5) return
                            // onClick={() => window.open(d.url)}
                            return  <div className="home-video-item-container">
                                       <img className="home-video-item-container-2" src={d.image}/>
                                       {/* <FaPlay className="home-video-item-play"/> */}
                                    </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default HomeVideo
