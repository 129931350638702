import { Spin } from 'antd'
import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'
import ButtonPrimary from '../../components/ButtonPrimary'
import ButtonSecondary from '../../components/ButtonSecondary'
import InputPrimary from '../../components/input/InputPrimary'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import QrReader from 'react-qr-reader'
import { ERROR, ggAlert } from '../../service/Service'

const ScanQRRewardMerchant = (props) => {

    const {state} = props.location

    const history = useHistory()
    const [inputManual, setInputManual] = useState("")
    const [scanResult, setScanResult] = useState({ result: "No Result" })
    const [isBottomSheetShown, setBottomShetShown] = useState(false)
    const [isScanEnabled, setScanEnabled] = useState(true)

    const handleScan = (data) => {
        if (!isScanEnabled) return
        checkQRCode(data)
    }

    const checkQRCode = (data) => {
        if (data === null || data === '') return
        if (data === state.data.phone) {
            console.log("datanyo", state.data)
            history.push({
                pathname: "/change-point-confirmation",
                state: {data: state.data, isActive: state.isActive}
            })
        } else {
            ggAlert("Error", "Data user tidak sesuai", ERROR)
        }
    }

    const inputManualConfirm = () => {
        checkQRCode(inputManual)
    }

    const handleError = (err) => {
        console.error(err)
    }
    const previewStyle = {
        height: '100%',
        width: '100%',
        background: 'none'
    }

    const bottomSheetClose = () => {
        setBottomShetShown(false)
    }

    const bottomSheetShown = () => {
        setBottomShetShown(true)
    }

    return (
        <div className="gg-parent-container">
            <NavbarDetail
                title="SCAN QR"/>

            <div className="gg-content-no-top-padding-container">
                {
                    isScanEnabled
                    &&
                    <QrReader
                        delay={300}
                        style={previewStyle}
                        onError={handleError}
                        onScan={handleScan}/>
                    ||
                    <Spin/>
                }
            </div>

            <div className="gg-bottom-container">
                <ButtonPrimary
                    text="Input Nomor Telephone Manual"
                    onClick={() => bottomSheetShown()}/>
            </div>

            <div className={isBottomSheetShown && "gg-bottom-sheet-container" || "gg-bottom-sheet-container-hide"}>
                <div className="gg-bottom-sheet-header">
                    <AiOutlineClose className="gg-bottom-sheet-header-close-icon"
                        onClick={() => bottomSheetClose()}/>
                    <p className="gg-bottom-sheet-header-label">Input Nomor Telephone Manual</p>
                </div>

                <div className="gg-line"/>

                <div className="gg-bottom-sheet-content">
                    <InputPrimary
                        type="number"
                        placeholder="Input Nomor Telephone"
                        onChange={(e) => setInputManual(e)}/>
                </div>

                <div className="gg-bottom-sheet-footer">
                    {
                        isScanEnabled
                        &&
                        <>
                        <ButtonSecondary
                            text="Batal"
                            onClick={() => bottomSheetClose()}/>
                        <div className="gg-bottom-sheet-footer-separator"/>
                        <ButtonPrimary
                            text="Konfirmasi"
                            onClick={() => inputManualConfirm()}/>
                        </>
                        ||
                        <Spin/>
                    }
                </div>
            </div>
        </div>
    )
}

export default ScanQRRewardMerchant
