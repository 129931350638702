import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { BsCheckCircle } from 'react-icons/bs'
import './success-layout.css'

const CancelLayout = ( { title, message} ) => {
    return (
        <div className="success-layout-container">
            <AiOutlineCloseCircle className="cancel-layout-icon"/>
            <p className="success-layout-title">{title}</p>
            <p className="success-layout-message">{message}</p>
        </div>
    )
}

export default CancelLayout
