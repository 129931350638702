import React from 'react'
import { BsCheckCircle } from 'react-icons/bs'
import './success-layout.css'

const SuccessLayout = ( { title, message} ) => {
    return (
        <div className="success-layout-container">
            <BsCheckCircle className="success-layout-icon"/>
            <p className="success-layout-title">{title}</p>
            <p className="success-layout-message">{message}</p>
        </div>
    )
}

export default SuccessLayout
