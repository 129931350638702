import React from 'react'
import { BsCheckCircle } from 'react-icons/bs'
import './success-layout-change-point.css'

const SuccessLayoutChangePoint = ( { title, message, isUsingExpired} ) => {
    var time = new Date()
    var hour = time.getHours() + 1 < 10 && "0" + (time.getHours() + 1) || time.getHours() + 1
    var minute = time.getMinutes() < 10 && "0" + time.getMinutes() || time.getMinutes()
    return (
        <div className="success-layout-change-point-container">
            <BsCheckCircle className="success-layout-change-point-icon"/>
            <p className="success-layout-change-point-title">{title}</p>
            <p className="success-layout-change-point-message">{message}</p>
            {
                isUsingExpired && 
                <div className="success-layout-change-point-container-2">
                    <p className="success-layout-change-point-expired">{hour + ":" + minute}</p>
                    <p className="success-layout-change-point-expired-label">Segera validasi QR Anda di kasir selama persediaan masih ada.</p>
                </div>
            }
        </div>
    )
}

export default SuccessLayoutChangePoint
