import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import EmptyPage from '../../../components/emptypage/EmptyPage'
import { ArticleRow } from '../../../components/homearticle/ArticleRow'
import HomeArticle from '../../../components/homearticle/HomeArticle'
import NavbarDetail from '../../../components/navbardetail/NavbarDetail'
import { getJsonResponse, headerBearer, url } from '../../../service/Service'
import './article-all.css'

const ArticleAll = () => {

    const [news, setNews] = useState([])
    const [loading, setLoading] = useState(false)

    async function getNews() {
        setLoading(true)
        await fetch(url + "News", {headers: headerBearer})
            .then(response => getJsonResponse(response))
            .then(json => setNews(json['data']))
            .catch(error => console.log(error))
        setLoading(false)
    }

    useEffect(() => {
        getNews()
    }, [])

    return (
        <div className="gg-parent-container">
            <NavbarDetail
                title="ARTIKEL"/>
            <div className="gg-content-no-top-padding-container">
                {
                    !loading && news.length <= 0 &&
                    <EmptyPage
                        message="Tidak ada artikel"/>
                    ||
                    <div className="article-all-container">
                        {
                            loading && <Spin/>
                            ||
                            news.length > 0 && news.map(function(data) {
                                return <ArticleRow
                                        data={data}/>
                            })
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default ArticleAll
