import React from 'react'
import { FaRegUser } from 'react-icons/fa'
import './user-card.css'

const UserCard = ({data}) => {
    return (
        <div className="user-card-container">
            <FaRegUser className="user-card-icon"/>
            <p className="user-card-name">{data && data['consumen']}</p>
            <p className="user-card-phone">{data && data['phone']}</p>
        </div>
    )
}

export default UserCard
