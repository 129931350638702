import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { Link, useHistory } from 'react-router-dom'
import { TRANSACTION_CONFIRMATION } from '../../const/Pathname'
import './inbox.css'

const InboxRowTransaction = ({data, isActive}) => {

    const history = useHistory()

    const onNavigateToConfirmation = () => {
        return {
            pathname: TRANSACTION_CONFIRMATION,
            state: { data: data, type: data['tipe'], isActive: isActive }
        }
    }
    
    return (
        <Link className="reservation-merchant-row-container" to={onNavigateToConfirmation()}>
            <div className="reservation-merchant-row-container-2">
                <div className="reservation-merchant-row-container-3">
                    <p className="reservation-merchant-row-name">{data['consumen']}</p>
                    <p className="reservation-merchant-row-phone">{data['phone']}</p>
                </div>
                <FaChevronRight className="reservation-merchant-row-icon"/>
            </div>
            <div className="reservation-merchant-row-line"/>
            <div className="inbox-row-container-2">
                <p className="inbox-row-package-name">{data['namapaket']}</p>
                {/* <p className="inbox-row-pakcage-point">+ {data['']} pax</p> */}
            </div>
        </Link>
    )
}

InboxRowTransaction.defaultProps = {
    isActive: true
}

export default InboxRowTransaction
