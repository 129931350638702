import React, { useEffect, useState } from 'react'
import { BsCheckCircle } from 'react-icons/bs'
import ButtonPrimary from '../../components/ButtonPrimary'
import ButtonPrimaryRed from '../../components/ButtonPrimaryRed'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import SuccessLayoutChangePoint from '../../components/successlayoutchangepoint/SuccessLayout'
import { Link } from 'react-router-dom'
import { ERROR, getJsonResponse, ggAlert, headerNoUrlEncodedBearer, INFO, url } from '../../service/Service'
import { Spin } from 'antd'

const ChangePointUser = (props) => {

    const {state} = props.location

    const [isConfirmed, setConfirmation] = useState(false)
    const [selectedItem, setSelectedItem] = useState({name: "", point: 0, id: 0})
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1)
    const [items, setItems] = useState([])

    const [hadiah_id, setHadiahId] = useState(0)
    const [transactionRewardId, setTransactionRewardId]  = useState(0)
    const [assignOutletRewardId, setAssignOutletRewardId] = useState(0)
    const TYPE = "loyalty"

    const [loading, setLoading] = useState(false)

    const onItemSelected = (index, name, point, itemId, transactionRewardId, assignOutletRewardId) => {
        if (index === selectedItemIndex) {
            onCancel(index)
            return
        }
        setSelectedItemIndex(index)
        setSelectedItem({name, point})
        setHadiahId(itemId)
        setTransactionRewardId(transactionRewardId)
        setAssignOutletRewardId(assignOutletRewardId)
    }

    const onConfirmationButtonClicked = () => {
        if (selectedItemIndex === -1) {
            ggAlert("Info", "Mohon pilih item dulu!", INFO)
            return
        }
        confirmAPI()
    }

    const onCancel = (index) => {
        setConfirmation(false)
        setSelectedItemIndex(-1)
        setSelectedItem({name: "", point: 0})
    }

    const onBack = () => {
        setConfirmation(false)
    }

    async function getReward() {
        await fetch(url + "loyalty/merchandise/" + state.data.id, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                console.log(json)
                setItems([])
                json['data'].map(function(d, index) {
                    var data = {
                        id: d.itemhadiah_id, 
                        name: d.nama_hadiah, 
                        point: d.pointhadiah, 
                        stock: d.qty && d.qty || 0, 
                        transactionRewardId: d.id,
                        assignOutletRewardId: d.mekanismeprogram_id,
                        image: d.image
                    }
                    setItems(arr => [...arr, data])
                })
            })
            .catch(error => console.log(error))
    }

    async function confirmAPI() {
        var formData = new FormData()
        formData.append("hadiah_id", hadiah_id)
        formData.append("id_outlet", state.data.id)
        formData.append("id_transaction_reward", transactionRewardId)
        formData.append("id_assign_outlet_reward", assignOutletRewardId)
        formData.append("tipe", TYPE)

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headerNoUrlEncodedBearer
        }
        setLoading(true)
        await fetch(url + "dokonfirmasitukarpointrewards", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setConfirmation(true)
            })
            .catch(error => {
                ggAlert("Error", error, ERROR)
            })
        setLoading(false)
    }

    useEffect(() => {
        if (state) {
            getReward()
        }
    }, [])

    return (
        <div className="change-point-container">
            
            <NavbarDetail
                title="TUKAR POIN"/>

            <div className="change-point-container-2">

                <div className="change-point-location-container">
                    <p className="change-point-location-name">{state && state.data.outlet_name}</p>
                    <p className="change-point-location-address">{state && state.data.address + ", " + state.data.kecamatan + ", " + state.data.kabupaten_kota}</p>
                    {
                        isConfirmed && 
                        <div className="change-point-selected-container">
                            <p className="change-point-selected-name">{selectedItem.name}</p>
                            <p className="change-point-selected">{selectedItem.point == -1 && "Special Reward" || selectedItem.point + " poin"}</p>
                        </div>
                        ||
                        <p className="change-point">{state && state.data.point || 0} Poin</p>
                    }
                </div>

                {
                    isConfirmed &&
                    <SuccessLayoutChangePoint
                    title="Terima Kasih!"
                    message="Batas waktu pengambilan barang pilihan Anda maksimal hingga pukul"
                    isUsingExpired={true}/> 
                    ||
                    <div className="change-point-item-container">
                        {
                            items.map(function(item, i){
                                return <div className="change-point-item-container-2"
                                        key={i}
                                        onClick={() =>
                                            onItemSelected(i, item.name, item.point, item.id, item.transactionRewardId, item.assignOutletRewardId) 
                                        }>
                                    <img className="change-point-item-image" src={item.image && item.image || "../../logo192.png"}/>
                                    {
                                        selectedItemIndex === i && <BsCheckCircle className="change-point-item-selected"/>
                                    }
                                    <p className="change-point-item-name">{item.name}</p>
                                    <p className="change-point-item-point">{item.point > -1 && item.point + " poin"}</p>
                                    <p className="change-point-item-stock">{item.stock} item tersisa</p>
                                </div>
                            })
                        }
                    </div>

                }

            </div>

            <div className="change-point-button-container">
                {
                    loading && <Spin/> ||
                    !loading && isConfirmed &&
                    <>
                    <Link className="change-point-button-link" to="/my-qr-code">
                        <ButtonPrimaryRed
                            text="MY QR"/>
                    </Link>
                    <div className="change-point-button-separator"/>
                    <ButtonPrimary
                        text="Kembali"
                        onClick={() => onBack()}/>
                    </>
                    ||
                    !loading && <ButtonPrimary
                        text="Konfirmasi Pilihan"
                        active={selectedItemIndex != -1 && true || false}
                        onClick={() => onConfirmationButtonClicked()}/>
                }

            </div>
        </div>
    )
}

export default ChangePointUser
