import React from 'react'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import RowLoyaltyPoints from '../../components/rowloyaltypoints/RowLoyaltyPoints'
import './loyalty-points.css'

const LoyaltyPoints = () => {

    const datas = [
        {label: "Covfefe Cafe", address: "Jl. Sunan Kalijaga No.42B, Jakarta Pusat", point: 2},
        {label: "Soul Pact Coffee", address: "Sopo Del Tower, First Floor, Jakarta Pusat", point: 4},
        {label: "Rick Roll Round Restaurant", address: "Jl. M.H. Thamrin No. 56, Jakarta Pusat", point: 2},
        {label: "Covfefe Cafe", address: "Jl. Sunan Kalijaga No.42B, Jakarta Pusat", point: 2},
        {label: "Soul Pact Coffee", address: "Sopo Del Tower, First Floor, Jakarta Pusat", point: 4},
        {label: "Rick Roll Round Restaurant", address: "Jl. M.H. Thamrin No. 56, Jakarta Pusat", point: 2},
        {label: "Covfefe Cafe", address: "Jl. Sunan Kalijaga No.42B, Jakarta Pusat", point: 2},
        {label: "Soul Pact Coffee", address: "Sopo Del Tower, First Floor, Jakarta Pusat", point: 4},
        {label: "Rick Roll Round Restaurant", address: "Jl. M.H. Thamrin No. 56, Jakarta Pusat", point: 2},
        {label: "Covfefe Cafe", address: "Jl. Sunan Kalijaga No.42B, Jakarta Pusat", point: 2},
        {label: "Soul Pact Coffee", address: "Sopo Del Tower, First Floor, Jakarta Pusat", point: 4},
        {label: "Rick Roll Round Restaurant", address: "Jl. M.H. Thamrin No. 56, Jakarta Pusat", point: 2},
        {label: "Covfefe Cafe", address: "Jl. Sunan Kalijaga No.42B, Jakarta Pusat", point: 2}
    ]

    return (
        <div className="parent">
            <NavbarDetail
                title="LOYALTY POINTS"
                isShownScanIcon={true}/>
            <div className="loyaltyPointsContainer">
                {datas.map(function(data, i){
                    return <RowLoyaltyPoints
                                label={data.label}
                                address={data.address}
                                point={data.point}
                                key={i}/>
                })}
            </div>
        </div>
    )
}

export default LoyaltyPoints
