import React from 'react'
import './row-loyalty-points.css'
import { FaChevronRight } from 'react-icons/fa'

const RowLoyaltyPoints = ( {label, address, point} ) => {
    return (
        <div className="rowLoyaltyPointsContainer">
            <div className="rowLoyaltyPointsContainer2">
                <div className="rowLoyaltyPointsContainer3">
                    <p className="rowLoyaltyPointsLabel">{label}</p>
                    <p className="rowLoyaltyPointsValue">{address}</p>
                    <p className="rowLoyaltyPointsPoints">{point + " point"}</p>
                </div>
                <FaChevronRight className="rowLoyaltyPointsIcon"/>
            </div>
            <div className="rowLoyaltyPointsLine"/>
        </div>
    )
}

export default RowLoyaltyPoints
