import React, { useState } from 'react'
import GGTimePicker from '../timepicker/GGTimePicker'
import './schedule-row.css'

const ScheduleRow = ({data, onChange}) => {

    return (
        <div className="schedule-row-container">
            <p className="schedule-row-day">{data.haribuka}</p>
            <div className="schedule-row-input-container">
                <GGTimePicker
                    label="Jam Buka"
                    defaultValue={data.jambuka}
                    onChange={(e) => onChange(e, data.haribuka, "open")}/>
            </div>
            
            <div className="schedule-row-input-container">
                <GGTimePicker
                    label="Jam Tutup"
                    defaultValue={data.jamtutup}
                    onChange={(e) => onChange(e, data.haribuka, "close")}/>
            </div>
        </div>
    )
}

export default ScheduleRow
