import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import './reservation-merchant-row.css'
import { Link } from 'react-router-dom'

const ReservationUserRow = ({data, isActive}) => {

    const type = isActive && "active" || "deactive"

    return (
        <Link className="reservation-merchant-row-container" to={{pathname: "/reservation-detail/" + type, state: {data: data}}}>
            <div className="reservation-merchant-row-container-2">
                <div className="reservation-merchant-row-container-3">
                    <p className="reservation-merchant-row-name">{data.outlet_name}</p>
                    <p className="reservation-merchant-row-phone">{data.address}</p>
                </div>
                <FaChevronRight className="reservation-merchant-row-icon"/>
            </div>
            <div className="reservation-merchant-row-line"/>
            <div className="reservation-merchant-row-container-2">
                <p className={isActive && "reservation-merchant-row-time" || "reservation-merchant-row-time-disable"}>{data.time}</p>
                <p className={isActive && "reservation-merchant-row-number" || "reservation-merchant-row-number-disable"}>{data.number} pax</p>
            </div>
        </Link>
    )
}

ReservationUserRow.defaultProps = {
    isActive: true
}

export default ReservationUserRow
