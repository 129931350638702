import React, { useEffect, useState } from 'react'
import { FaCircle, FaShoppingBag, FaStar } from 'react-icons/fa'
import './row-notification.css'
import { Link } from 'react-router-dom'
import { ImSpoonKnife } from 'react-icons/im'
import { getJsonResponse, headerNoUrlEncodedBearer, url } from '../../service/Service'
import { Spin } from 'antd'

const RowNotification = ({ title, id, idTransaction, type, message, time, rejected, isRead }) => {

    const [loading, setLoading] = useState(false)
    var [isRead2, setIsRead] = useState(isRead)

    async function setRead() {
        setLoading(true)
        await fetch(url + "user/notification/detail/" + id, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setIsRead(1)
            })
            .catch(error => {
                console.log(error)
            })
        setLoading(false)
    }

    useEffect(() => {
        setIsRead(isRead)
    }, [])

    return (
        // {rejected && "/reservation-confirmation-finish/cancel" || "/reservation-confirmation-finish/accept"}
        <div className="notification-row">
            {
                type == 0 &&
                <Link className="notification-row-container" to={{ pathname: "/reward-detail", state: { id: idTransaction }}}>
                    <div className="notification-row-container-2">
                        <div className="notification-row-icon-container">
                            {
                                type == 0 && <FaCircle className="notification-row-icon"/>
                                || type == 1 && <FaStar className="notification-row-icon"/>
                                || type == 2 && <FaShoppingBag className="notification-row-icon"/>
                                || type == 3 && <ImSpoonKnife className="notification-row-icon"/>
                            }
                        </div>
                        <div className="notification-row-content-container">
                            <p className="notification-row-title">{title}</p>
                            <p className="notification-row-message">{message}</p>
                            <p className="notification-row-time">{time}</p>
                        </div>
                    </div>
                </Link>
                ||
                <div className="notification-row-container" to={{ pathname: "/reward-detail", state: { id: idTransaction }}}>
                    <div className="notification-row-container-2">
                        <div className="notification-row-icon-container">
                            {
                                type == 0 && <FaCircle className="notification-row-icon"/>
                                || type == 1 && <FaStar className="notification-row-icon"/>
                                || type == 2 && <FaShoppingBag className="notification-row-icon"/>
                                || type == 3 && <ImSpoonKnife className="notification-row-icon"/>
                            }
                        </div>
                        <div className="notification-row-content-container">
                            <p className="notification-row-title">{title}</p>
                            <p className="notification-row-message">{message}</p>
                            <p className="notification-row-time">{time}</p>
                        </div>
                    </div>
                </div>
            }
            {
                loading && <Spin className="notification-row-read"/>
                ||
                isRead == 0 && isRead2 == 0 &&
                <p className="notification-row-read" onClick={() => setRead()}>Tandai Dibaca</p>
            }
        </div>
    )
}

RowNotification.defaultProps = {
    title: "Title",
    address: "Address",
    date: "17 August 2021 - 09:00 AM",
    amount: 2,
    isToday: false
}

export default RowNotification
