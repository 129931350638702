import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import EmptyPage from '../../components/emptypage/EmptyPage'
import NotificationMenu from '../../components/notificationmenu/NotificationMenu'
import NotificationNavbar from '../../components/notificationnavbar/NotificationNavBar'
import RowNotification from '../../components/rownotification/RowNotification'
import { getJsonResponse, headerNoUrlEncodedBearer, url } from '../../service/Service'
import './notification.css'

const Notification = () => {

    // const [datas, setData] = useState([])
    const [notification, setNotification] = useState()
    const [notificationStatic, setNotificationStatic] = useState()
    const [filter, setFilter] = useState(-1)
    const [loading, setLoading] = useState(false)

    // async function getTransaction() {
    //     await fetch(url + "transactionreward", {headers: headerNoUrlEncodedBearer})
    //         .then(response => {
    //             return getJsonResponse(response)
    //         })
    //         .then(json => {
    //             setData(json['data'])
    //         })
    //         .catch(error => console.log(error))
    // }

    const onFilterChange = (index) => {
        if (localStorage.getItem("role") != "user") return
        setFilter(index)
        
        if (index == -1) {
            setNotification(notificationStatic)
            return
        }

        setNotification(
            notificationStatic.filter(data => 
                data['type'] == index 
            )
        )
        console.log(notification)
    }

    async function getNotification() {
        setLoading(true)
        await fetch(url + "user/notification", {headers:headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setNotification([])
                setNotificationStatic([])
                setNotificationStatic(json['data'])
                setNotification(json['data'])
            })
            .catch(error => console.log(error))
        setLoading(false)
    }

    useEffect(() => {
        if (localStorage.getItem("role") === 'user') {
            // getTransaction()
            if (localStorage.getItem("role") === "user") {
                getNotification()
            }
        }
    }, [])

    return (
        <div className="notification-container">
            <NotificationNavbar
                title="NOTIFIKASI"
                onChange={onFilterChange}/>
            <div className="notification-item-container">
                {
                    loading && <Spin/> 
                    ||
                    notification && notification.length > 0 &&
                    notification.map(function(data, i) {
                        return <RowNotification
                                    title={data['title']}
                                    isRead={data['is_read']}
                                    idTransaction={data['id_transaction']}
                                    id={data['id']}
                                    type={data['type']}
                                    message={data['body']}
                                    time={data['time']}
                                    // rejected={i === 2 && true || false}
                                    key={i}/>
                    })
                    ||
                    <EmptyPage
                        message="Tidak ada data notifikasi"/>
                }
            </div>
        </div>
    )
}

export default Notification
