import React from 'react'
import ButtonPrimary from '../../components/ButtonPrimary'
import InputPrimary from '../../components/input/InputPrimary'
import './agreement.css'
import { useHistory } from 'react-router-dom'
import { ggAlert, INFO } from '../../service/Service'

const Agreement = () => {

    let history = useHistory()
    let isChecked = false

    const onConfirmationButtonClicked = () => {
        if (!isChecked) {
            ggAlert("Info", "Mohon menyetujui persyaratan dengan checklis pernyataan yang disediakan!", INFO)
            return
        }
        localStorage.setItem("aggrementChecked", "true")
        history.push('/login')
    }

    const onAggrementChecked = (value) => {
        isChecked = value
        console.log(isChecked)
    }

    return (
        <div className="agreementContainer">
            <div className="agreementContentContainer">
                <div className="agreementTitleContainer">
                    {/* <p className="agreementTitle">TAP<i className="agreementTitleBold">POINT</i></p> */}
                    <div className="agreementLogo"/>
                </div>
                <div className="agreementModalContainer">
                    <p className="agreementInfo">Aplikasi ini berisi informasi mengenai produk yang mengandung dan berbahan dasar tembakau, dan hanya diperuntukkan bagi perokok berusia 18 tahun ke atas yang tinggal di Indonesia</p>
                    <p className="agreementInfo2">Untuk melanjutkan, dimohon mencentang persetujuan berikut:</p>
                    <div className="agreementInfoCheckBox">
                        <input
                            className="agreementCheckBox"
                            type="checkbox"
                            onChange={(e) => onAggrementChecked(e.target.checked)}/>
                        <p className="agreementInfo3">Dengan mencentang kotak ini, saya mengakui bahwa saya telah berumur 18 tahun ke atas</p>
                        {/* <p className="agreementInfo3">Dengan mencentang kotak ini, saya setuju dengan syarat dan ketentutan yang telah ditentukan oleh pihak Arena Madya Wisesa</p> */}
                    </div>
                    <ButtonPrimary
                        text="Konfirmasi"
                        onClick={() => onConfirmationButtonClicked()}/>
                </div>
            </div>
        </div>
    )
}

export default Agreement
