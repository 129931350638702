import React from 'react'
import ButtonPrimary from '../../components/ButtonPrimary'
import InputPrimary from '../../components/input/InputPrimary'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import '../general.css'
import './fill-data.css'
import { Link } from 'react-router-dom'

export const FillDataCustomer1 = () => {
    return (
        <div className="parent">
            <NavbarDetail
            title="FILL YOUR DATA (1/2)"/>
            <div className="container">
                
                <InputPrimary
                placeholder="Full Name"
                type="Text"
                gap={20}/>
                
                <InputPrimary
                placeholder="Email"
                type="Text"
                gap={20}/>
                
                <InputPrimary
                placeholder="Password"
                type="Password"
                gap={20}/>
                
                <InputPrimary
                placeholder="Phone Number"
                type="Number"
                gap={20}/>
                
                <InputPrimary
                placeholder="Date of Birth"
                type="Date"
                gap={20}/>
                
                <InputPrimary
                placeholder="City"
                type="Text"
                gap={20}/>

                <div className="fillSpaceContainer" 
                style={{height: "calc(100% - 500px)"}}>
                    <Link to="/fill-data-2">
                        <ButtonPrimary
                            text="Next"/>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default FillDataCustomer1