import React, { useEffect, useState } from 'react'
import { FaPlay } from 'react-icons/fa'
import EmptyPage from '../../../components/emptypage/EmptyPage'
import NavbarDetail from '../../../components/navbardetail/NavbarDetail'
import { getJsonResponse, headerNoUrlEncodedBearer, url } from '../../../service/Service'
import './video-all.css'

const VideoAll = () => {

    const [videoGallery, setVideoGallery] = useState([])

    async function getVideoGallery() {
        await fetch(url + "galleryvideogg", {headers: headerNoUrlEncodedBearer})
            .then(response => getJsonResponse(response))
            .then(json => {
                setVideoGallery(json['data'])
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getVideoGallery()
    }, [])

    return (
        <div className="gg-parent-container">
            <NavbarDetail
                title="GALERI"/>
            <div className="gg-content-no-padding-container">
                <div className="video-all-container">
                    {
                        videoGallery.length > 0 && videoGallery.map(function(data) {
                            // onClick={() => window.open(data.url)}
                            return  <div className="video-all-item">
                                        <img className="video-all-item-2" src={data.image} />    
                                        {/* <FaPlay className="video-all-item-play" /> */}
                                    </div>      
                        })
                        ||
                        <EmptyPage/>
                    }
                </div>
            </div>
        </div>
    )
}

export default VideoAll
