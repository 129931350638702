import React, { useEffect } from 'react'
import ButtonPrimary from '../../components/ButtonPrimary'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import SuccessLayout from '../../components/successlayout/SuccessLayout'
import './reservation-confirmation-final.css'
import { useParams } from 'react-router-dom'
import CancelLayout from '../../components/successlayout/CancelLayout'

const ReservationConfirmationFinal = (props) => {

    const {type} = useParams()
    const {state} = props.location

    const onBackClicked = () => {
        window.location.href = "/home"
    }
    
    return (
        <div className="gg-parent-container">
            <NavbarDetail title="AJUAN RESERVASI"/>

            <div className="gg-content-no-top-padding-container">
                <div className="buy-package-location-container">
                    <p className="buy-package-location-name">{state && state.data && state.data['name']}</p>
                    <p className="buy-package-location-address">{state && state.data && state.data.phone}</p>
                    {
                        <div className="buy-package-point-selected-container">
                            <p className="buy-package-point-selected-name">{state && state.data && state.data.time}</p>
                            <p className="buy-package-point-selected">{state && state.data && state.data.number} pax</p>
                        </div>
                    }
                </div>

                {
                   type === "cancel" &&
                    <CancelLayout
                        title="Reservasi Ditolak!"
                        message={
                            localStorage.getItem("role") === 'user' &&
                            "Mohon maaf reservasi tidak bisa dilakukan. Silahkan memilih waktu reservasi lain"
                            ||
                            "Kami akan segera memberitahukan pelanggan bahwa reservasi telah anda tolak."
                        }/>
                    ||
                    <SuccessLayout
                        title="Reservasi Diterima"
                        message="Kami akan segera memberitahukan pelanggan bahwa reservasi telah anda terima."/>
                }
            </div>

            <div className="gg-bottom-container">
                <ButtonPrimary
                    text="Kembali"
                    onClick={() => onBackClicked()}/>
            </div>
        </div>
    )
}

export default ReservationConfirmationFinal
