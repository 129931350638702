const ButtonPrimaryRed = ({ text, onClick, active }) => {
    return (
        <button
            style={{
                background: active && 'red' || 'gray',
                borderRadius: 10,
                color: 'white',
                width: '100%',
                border: 0,
                height: 40,
                fontSize: 16,
                cursor: 'pointer'
            }}
            onClick={() => onClick != null && onClick()}>
                {text}
        </button>
    )
}

ButtonPrimaryRed.defaultProps = {
    active: true,
    onClick: null 
}

export default ButtonPrimaryRed
