import { Checkbox, Modal, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { getJsonResponse, url } from '../../service/Service'
import ButtonPrimary from '../ButtonPrimary'
import ButtonSecondary from '../ButtonSecondary'
import './reservation-reject-modal.css'

const ReservationRejectModal = ({ isShowModal, onCancel, onOk }) => {

    const reason = useRef(null)

    return (
        <Modal 
            title="ALASAN PENOLAKAN" 
            visible={isShowModal} 
            onOk={() => onOk != null && onOk(reason.current.value)} 
            onCancel={() => onCancel != null && onCancel()}
            footer={[
                <div className="select-product-modal-footer-container">
                    {
                        <>
                            <ButtonSecondary
                                text="Kembali" 
                                key="back" 
                                onClick={() => onCancel != null && onCancel()}/>,
                            <div className="select-product-modal-button-separator"/>,
                            <ButtonPrimary 
                                text="Kirim"
                                key="submit" 
                                type="primary" 
                                onClick={() => onCancel != null && onOk(reason.current.value)}/>
                        </>
                    }
                </div>
            ]}>
            <div className="reservation-reject-modal-text-area-container">
                <textarea
                    placeholder="Berikan Alasan Penolakan disini ..."
                    className="reservation-reject-modal-text-area"
                    style={{width: 'calc(100% - 40px)'}}
                    ref={reason}
                />
                
            </div>
        </Modal>
    )
}

ReservationRejectModal.defaultProps = {
    isLoading: false
}

export default ReservationRejectModal
