import { Spin, Switch, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import InboxRowChangePoint from '../../components/inboxrow/InboxRowChangePoint'
import InboxRowReservation from '../../components/inboxrow/InboxRowReservation'
import InboxRowTransaction from '../../components/inboxrow/InboxRowTransaction'
import NavbarSearch from '../../components/navbarsearch/NavbarSearch'
import NavbarSearchTab from '../../components/navbarsearch/NavbarSearchTab'
import PromoRow from '../../components/promorow/PromoRow'
import ReservationMerchantRow from '../../components/reservationmerchantrow/ReservationMerchantRow'
import { getJsonResponse, headerBearer, headerNoUrlEncodedBearer, url } from '../../service/Service'
import './inbox.css'
import moment from 'moment'
import EmptyPage from '../../components/emptypage/EmptyPage'

const Inbox = () => {

    const { TabPane } = Tabs;
    const [dataTransaction, setDataTransaction] = useState([])
    const [reservations, setReservations] = useState([])
    const [rewards, setReward] = useState([])
    const [transactionLoading, setTransactionLoading] = useState(false)
    const [rewardLoading, setRewardLoading] = useState(false)
    const [reservationLoading, setReservationLoading] = useState(false)
    const [searchPlaceHolder, setSearchPlaceholder] = useState("Cari Transaksi")
    const [searchKeyword, setSearhKeyword] = useState(["", "", ""])
    const [tabPosition, setTabPosition] = useState(0)

    function callback(key) {
        console.log(searchKeyword[tabPosition]);
        setTabPosition(parseInt(key, 10) - 1)
        switch(key) {
            case "1":
                setSearchPlaceholder("Cari Transaksi")
                break;
            case "2":
                setSearchPlaceholder("Cari Tukar Poin")
                break;
            case "3":
                setSearchPlaceholder("Cari Reservasi")
                break;
            default:
                setSearchPlaceholder("Cari Transaksi")
                break;        
        }
    }

    async function getTransaction() {
        var outlet = JSON.parse(localStorage.getItem("outlet"))
        setTransactionLoading(true)

        var endpoint = url + "transactionmerchant/" + outlet['id']

        if (searchKeyword[0] != "") {
            endpoint = endpoint + "?search=" + searchKeyword[0]
        }

        await fetch(endpoint, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setDataTransaction([])
                setDataTransaction(json['data'])
            })
            .catch(error => {
                console.log(error)
            })
        setTransactionLoading(false)
    }

    async function getReservation(keyword) {
        var outlet = JSON.parse(localStorage.getItem("outlet"))
        setReservationLoading(true)

        var endpoint = url + "homehoreca/reservasi/" + outlet['id'] 

        if (searchKeyword[2] != "") {
            endpoint = endpoint + "?search=" + searchKeyword[2]
        }

        await fetch(endpoint, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setReservations([])
                json['data'].map(function(d, index) {
                    var data = {
                        id: d.id, name: d.user.name, phone: d.user.mobile, time: moment(d.start_program).format("DD MMM yyyy") + " - " + d.jam_reservasi, number: d.jumlah_orang
                    }
                    setReservations(arr => [...arr, data])
                })
            })
            .catch(error => console.log(error))
        setReservationLoading(false)
    }

    useEffect(() => {
        getTransaction()
        getReward()
        if (localStorage.getItem("merchantType") == 1) {
            getReservation()
        }
    }, [])

    async function getReward() {
        var outlet = JSON.parse(localStorage.getItem("outlet"))
        setRewardLoading(true)

        var endpoint = url + "transactionrewardmerchant/" + outlet['id'] 

        if (searchKeyword[1] != "") {
            endpoint = endpoint + "?search=" + searchKeyword[1]
        }

        await fetch(endpoint, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                console.log(json)
                setReward([])
                json['data'].map(function(d, index){
                    var data = {
                        name: d.user.name, 
                        phone: d.user.mobile, 
                        package: "", 
                        point: 0, 
                        item: d.hadiah_name, 
                        idtransactionrewards: d.idtransactionrewards,
                        image: d.image,
                        tipe: d.tipe,
                        expire: d.expire
                    }
                    setReward(arr => [...arr, data])
                })
            })
            .catch(error => console.log(error))
        setRewardLoading(false)
    }

    const onSearch = (keyword) => {
        var newArray = [...searchKeyword]
        newArray[tabPosition] = keyword
        console.log(newArray)
        setSearhKeyword(newArray)
        switch(tabPosition) {
            case 0:
                getTransaction()
                break;
            case 1:
                getReward()
                break;
            case 2:
                getReservation()
                break;
            default:
                getTransaction()
                break;            
        }
    }

    const onKeywordChanged = (keyword) => {
        var newArray = [...searchKeyword]
        newArray[tabPosition] = keyword
        console.log(newArray)
        setSearhKeyword(newArray)
    }

    return (
        <div className="gg-parent-container">
            <NavbarSearchTab
                placeholder={searchPlaceHolder}
                searchKeyword={searchKeyword[tabPosition]}
                onKeywordChanged={(keyword) => onKeywordChanged(keyword)}
                onSearch={(keyword) => onSearch(keyword)}/>

            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Transaksi" key="1">
                    <div className="homeTabPaneContainer">
                        {
                            dataTransaction.length > 0 && !transactionLoading &&
                            dataTransaction.map(function(data, i){
                                return <InboxRowTransaction data={data}/>
                            })
                            || transactionLoading && <Spin/>
                            || !transactionLoading
                            &&
                            <EmptyPage
                                message="Data Transaksi belum ada"/>
                        }
                    </div>
                </TabPane>
                <TabPane tab="Tukar Poin" key="2">
                    <div className="homeTabPaneContainer">
                        {
                            rewards.length > 0 && !rewardLoading && 
                            rewards.map(function(data, i){
                                return <InboxRowChangePoint data={data}/>
                            })
                            || rewardLoading && <Spin/>
                            || !rewardLoading
                            &&
                            <EmptyPage
                                message="Data Tukar Poin belum ada"/>
                        }
                    </div>
                </TabPane>
                {
                    localStorage.getItem("merchantType") == 1
                    &&
                    <TabPane tab="Reservasi" key="3">
                        <div className="homeTabPaneContainer">
                            {
                                reservations.length > 0 && !reservationLoading && 
                                reservations.map(function(data, i){
                                    return <InboxRowReservation data={data}/>
                                })
                                || reservationLoading && <Spin/>
                                || !reservationLoading
                                &&
                                <EmptyPage
                                    message="Data Reservasi belum ada"/>
                            }
                        </div>
                    </TabPane>
                }
            </Tabs>
        </div>
    )
}

export default Inbox
