import { Modal } from 'antd'
import React from 'react'
import { FaStar } from 'react-icons/fa'
import ButtonPrimary from '../ButtonPrimary'
import ButtonSecondary from '../ButtonSecondary'
import './buy-confirmation-modal.css'

const BuyConfirmationModal = ({ isShowModal, onCancel, onOk, data }) => {

    return (
        <div className="buy-confirmation-modal-container">
            <Modal
                visible={isShowModal} 
                onOk={() => onOk != null && onOk()} 
                onCancel={() => onCancel != null && onCancel()}
                footer={[
                    <div className="buy-confirmation-modal-footer-container">
                        <ButtonSecondary
                            text="Batal" 
                            key="back" 
                            onClick={() => onCancel != null && onCancel()}/>,
                        <div className="select-product-modal-button-separator"/>,
                        <ButtonPrimary
                            text="Konfirmasi"
                            key="submit" 
                            type="primary" 
                            onClick={() => onCancel != null && onOk()}/>
                    </div>
                ]}>

                <p className="buy-confirmation-modal-label">Apakah anda yakin ingin melanjutkan pembelian paket di bawah ini?</p>

                <div className="buy-confirmation-modal-container-2">
                    {/* <div className="buy-confirmation-modal-type"><FaStar className="buy-confirmation-modal-icon"/><p className="buy-confirmation-modal-type-text">Loyalty</p></div> */}
                    <p className="buy-confirmation-modal-package">{data.packageName}</p>
                    <p className="buy-confirmation-modal-number">{data.number} pcs</p>
                </div>
            </Modal>
        </div>
    )
}

export default BuyConfirmationModal
