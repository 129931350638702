import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FaAward, FaDirections } from 'react-icons/fa'
import HorizontalList from '../../components/horizontallist/HorizontalList'
import MerchantMenu from '../../components/merchantmenu/MerchantMenu'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import './merchant-detail.css'
import useGeolocation from '../../util/useGeolocation'
import { ERROR, getJsonResponse, ggAlert, headerBearer, INFO, url } from '../../service/Service'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react'
import { GOOGLE_MAP } from '../../const/ApiKey'
import { MdClose } from 'react-icons/md'
import BuyConfirmationModal from '../../components/buyconfirmationmodal/BuyConfirmationModal'

const MerchantDetail = (props) => {

    const {type} = useParams()
    const {state} = props.location
    const location = useGeolocation()
    const [data, setData] = useState({})
    const [menus, setMenus] = useState([])
    const [galleries, setGalleries] = useState([])
    const [promos, setPromos] = useState([])
    const [schedule, setSchedule] = useState([])
    const [selectedImage, setSelectedImage] = useState("")
    const [display, setDisplay] = useState("none")
    
    const openGMapDirection = () => {
        if (location.loaded) {
            console.log(JSON.stringify(location))
            if (location.coordinates) {
                window.open("http://maps.google.com/maps?saddr="+location.coordinates.lat+","+ location.coordinates.lng +"&daddr=" + data.latitude + ","+ data.longitude)
            } else {
                ggAlert("Info", "Tidak dapat menemukan lokasi anda, mohon menyalakan GPS dan memberikan akses untuk layanan ini.", INFO)
            }
        } else {
            ggAlert("Info", "Tidak dapat menemukan lokasi anda, mohon menyalakan GPS dan memberikan akses untuk layanan ini.", INFO)
        }
    }

    const openGMap = () => {
        window.open("https://maps.google.com?q="+data.latitude+","+data.longitude );    
    }

    async function getDetail() {
        if (!state && !state.id) return
        await fetch(url + "OutletData/detail/" + state.id, {headers: headerBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setData(json['data'])
                setGalleries([])
                setSchedule([])
                setMenus([])
                setPromos([])
                
                json['data']['galleryhoreca'].map(function(d) {
                    setGalleries(arr => [...arr, {
                        id: d.id,
                        image: d.image,
                        content: d.content,
                        name: d.title,
                        start: d.start_program,
                        end: null
                    }])
                })

                json['data']['horecaschedule'].map(function(d) {
                    setSchedule(arr => [...arr, {
                        haribuka: d.haribuka,
                        jambuka: d.jambuka,
                        jamtutup: d.jamtutup
                    }])
                })

                json['data']['menuhorecas'].map(function(d) {
                    setMenus(arr => [...arr, {
                        id: d.id,
                        image: d.image,
                        content: d.content,
                        name: d.title,
                        start: d.start_program,
                        end: null
                    }])
                })

                json['data']['promomerchants'].map(function(d) {
                    setPromos(arr => [...arr, {
                        id: d.id,
                        image: d.image,
                        content: d.content,
                        name: d.title,
                        start: d.start_program,
                        end: d.berlaku_hingga
                    }])
                })
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        getDetail()
    }, [])

    const onPreview = (image) => {
        setSelectedImage(image)
        setDisplay("flex")
    }

    const onClose = () => {
        setDisplay("none")
    }

    return (
        <>
            <div className="merchant-detail-container">

                <NavbarDetail
                    title="RINCIAN MERCHANT"/>

                <div className="merchant-detail-container-2">
                    {/* <div className="merchant-detail-point-community-container">
                        <p className="merchant-detail-point-community-label">Poin Komunitas</p>
                        <FaAward className="merchant-detail-point-community-icon"/>
                        <p className="merchant-detail-point-community">0 poin</p>
                    </div> */}

                    {/* <Map google={props.google} zoom={14}
                        containerStyle={{height: "70vw", position: 'relative'}}
                        style={{height: "70vw", position: 'relative'}}
                        center={{lat: parseFloat(data.latitude), lng: parseFloat(data.longitude)}}
                        >
                        <Marker
                            title={''}
                            name={data.outlet_name}
                            position={{lat: parseFloat(data.latitude), lng: parseFloat(data.longitude)}} />
                    </Map> */}

                    <div className="merchant-detail-banner"/>

                    <div className="merchant-detail-location-container">
                        <div className="merchant-detail-location-container-2">
                            <p className="merchant-detail-location-name">{data.outlet_name}</p>
                            <p className="merchant-detail-location">{data.address + ", " + data.kecamatan + ", " + data.kabupaten_kota}</p>
                            {/* <p className="merchant-detail-location-distance">250 m</p> */}
                        </div>
                        <FaDirections className="merchant-detail-location-icon"
                            onClick={() => openGMapDirection()}/>
                    </div>

                    <div className="merchant-detail-line"/>

                    <div className="merchant-detail-point-container">
                        <p className="merchant-detail-point-label">Poin Loyalty</p>
                        <p className="merchant-detail-point">{data.point} poin</p>
                    </div>

                    <div className="merchant-detail-line"/>

                    <div className="merchant-detail-info-container">
                        <p className="merchant-detail-info-label">Info Merchant</p>
                        <div className="merchant-detail-info-container-2">
                            {/* <p className="merchant-detail-info-label-2">Tipe</p>
                            <p className="merchant-detail-info">Outlet</p> */}
                        </div>
                        {
                            type === 'restaurant' &&
                            <div className="merchant-detail-info-container-2">
                                <p className="merchant-detail-info-label-2">Jam Buka</p>
                                <div className="merchant-detail-info-operation-time-container">
                                    {
                                        schedule && schedule.map(function(d) {
                                            return <p className="merchant-detail-info">{d.jambuka} - {d.jamtutup} ({d.haribuka})</p>
                                        })
                                    }
                                </div>
                            </div>
                            ||
                            type === 'restaurant-qr' &&
                            <div className="merchant-detail-info-container-2">
                                <div className="merchant-detail-info-operation-time-container">
                                    {
                                        schedule && schedule.map(function(d) {
                                            return <p className="merchant-detail-info">{d.jambuka} - {d.jamtutup} ({d.haribuka})</p>
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>

                    <div className="merchant-detail-line"/>
                    
                    {
                        type === 'restaurant' &&
                        <>
                            {
                                promos && promos.length > 0 &&
                                <>
                                    <HorizontalList
                                        title="Promo"
                                        type="detail"
                                        data={promos}/>

                                    <div className="merchant-detail-line"/>
                                </>
                            }

                            {
                                galleries && galleries.length > 0 &&
                                <>
                                    <HorizontalList
                                        title="Galeri"
                                        data={galleries}
                                        onPreview={onPreview}/>

                                    <div className="merchant-detail-line"/>
                                </>
                            }
                            
                            {
                                menus && menus.length > 0&&
                                <>
                                    <HorizontalList
                                        title="Menu"
                                        data={menus}
                                        onPreview={onPreview}/>

                                    <div className="merchant-detail-line"/>
                                </>
                            }
                        </>
                        ||
                        type === 'restaurant-qr' &&
                        <>
                            {
                                promos && promos.length > 0 &&
                                <>
                                    <HorizontalList
                                        title="Promo"
                                        type="detail"
                                        data={promos}/>

                                    <div className="merchant-detail-line"/>
                                </>
                            }

                            {
                                galleries && galleries.length > 0 &&
                                <>
                                    <HorizontalList
                                        title="Galeri"
                                        data={galleries}
                                        onPreview={onPreview}/>

                                    <div className="merchant-detail-line"/>
                                </>
                            }
                            
                            {
                                menus && menus.length > 0&&
                                <>
                                    <HorizontalList
                                        title="Menu"
                                        data={menus}
                                        onPreview={onPreview}/>

                                    <div className="merchant-detail-line"/>
                                </>
                            }
                        </>
                    }
                    
                </div>

                <MerchantMenu
                    type={type}
                    id={data.id}
                    data={data}/>

                
            </div>
            <div className="merchant-detail-preview-container" style={{display: display}}>
                <img src={selectedImage} className="merchant-detail-preview-image"/>
                <MdClose className="merchant-detail-image-preview-close" onClick={() => onClose()}/>
            </div>
        </>
    )
}

export default GoogleApiWrapper({
    apiKey: (GOOGLE_MAP)
  })(MerchantDetail)
  