import React from 'react'
import ButtonPrimary from '../../components/ButtonPrimary'
import InputPrimary from '../../components/input/InputPrimary'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import './fill-data-merchant.css'
import { Link } from 'react-router-dom'

const FillDataMerchant = () => {
    return (
        <div className="parent">
            <NavbarDetail
                title="FILL YOUR DATA (1/2)"/>
            <div className="container">
                
                <div className="fillDataMerchantContainer">
                    <InputPrimary
                        placeholder="Merchant Category"
                        type="Text"
                        gap={20}/>
                    
                    <InputPrimary
                        placeholder="Merchant Name"
                        type="Text"
                        gap={20}/>

                    <InputPrimary
                        placeholder="Merchant Address"
                        type="Text"
                        gap={20}/>

                    <InputPrimary
                        placeholder="Owner's Full Name"
                        type="Text"
                        gap={20}/>
                    
                    <InputPrimary
                        placeholder="Email"
                        type="Text"
                        gap={20}/>

                    <InputPrimary
                        placeholder="Password"
                        type="Password"
                        gap={20}/>
                    
                    <InputPrimary
                        placeholder="Phone Number"
                        type="Number"
                        gap={20}/>
                    
                    <InputPrimary
                        placeholder="Date of Birth"
                        type="Date"
                        gap={20}/>
                </div>

                <div className="fillDataMerchantButtonContainer">
                    <div className="fillDataMerchantButtonContainer2">
                        <Link to="/fill-data-2">
                            <ButtonPrimary
                                text="Next"/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FillDataMerchant
