import React from 'react'
import ButtonPrimary from '../../components/ButtonPrimary'
import './congratulation.css'
import { Link } from 'react-router-dom'

const Congratulation = () => {
    return (
        <div className="congratulationContainer">
            <image className="congratulationBanner"/>
            <p className="congratulationTitle">Congratulation!</p>
            <p className="congratulationContent1">You have created your Digital Touchpoint account.</p>
            <p className="congratulationContent2">You can proceed to enjoy our fantastic promo offers and earn points for your every Gudang Garam purchases.</p>
            <div className="fillSpaceContainer" 
                style={{height: 'calc(100% - 520px)'}}>
                <Link to="/home">
                    <ButtonPrimary
                        text="Proceed to Touchpoint"/>
                </Link>
            </div>
        </div>
    )
}

export default Congratulation
