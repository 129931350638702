import React, { useState } from 'react'
import ButtonPrimary from '../../components/ButtonPrimary'
import InputPrimary from '../../components/input/InputPrimary'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import './reset-password.css'
import { useHistory } from 'react-router-dom'
import { ERROR, getJsonResponse, ggAlert, SUCCESS, url } from '../../service/Service'
import { Spin } from 'antd'

const ResetPassword = (props) => {

    const {state} = props.location
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [loading, setLoading] = useState(false)

    let history = useHistory()

    async function onSaveButtonClicked() {
        if (password === "" || confirmPassword === "") {
            ggAlert("Error", "Password atau konfirmasi password tidak boleh kosong!", ERROR)
            return
        }

        if (password != confirmPassword) {
            ggAlert("Error", "Password dan konfirmasi password tidak sama!", ERROR)
            return
        }


        const body = {
            emailOrPhone: state.phone,
            password: password,
            otp_code: state.otp
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        }

        setLoading(true)
        await fetch(url + "createNewPassword", requestOptions)
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                ggAlert("Success", json['message'], SUCCESS).then(() => history.push("/login"))
            })
            .catch(error => {
                ggAlert("Error", error, ERROR)
            })

        setLoading(false)
    }

    return (
        <div className="resetPasswordContainer">
            <NavbarDetail
                title="RESET PASSWORD"/>
            <div className="resetPasswordContainer2">
                <div className="resetPasswordSeparator"/>
                <div className="resetPasswordSeparator"/>
                <InputPrimary
                    type="password"
                    placeholder="Password Baru"
                    onChange={(e) => setPassword(e)}/>
                <div className="resetPasswordSeparator"/>
                <InputPrimary
                    type="password"
                    placeholder="Konfirmasi Password Baru"
                    onChange={(e) => setConfirmPassword(e)}/>
                <div className="resetPasswordSpace"/>
                <div className="resetPasswordButtonContainer">
                    {
                        loading && <Spin/>
                        ||
                        <ButtonPrimary
                            text="Simpan dan Kembali"
                            onClick={() => onSaveButtonClicked()}/>
                    }
                </div>
                <div className="resetPasswordSeparator"/>
            </div>
        </div>
    )
}

export default ResetPassword
