import { Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import NavbarDetail from '../../components/navbardetail/NavbarDetail'
import NavbarSearchWithMenu from '../../components/navbarsearch/NavbarSearchWithMenu'
import PromoRow from '../../components/promorow/PromoRow'
import { useHistory } from 'react-router-dom'
import { getJsonResponse, headerNoUrlEncodedBearer, url } from '../../service/Service'
import moment from 'moment'
import { TYPE_EDIT } from '../../const/ParamType'

const PromoManagement = () => {
    
    const { TabPane } = Tabs;
    const history = useHistory()
    const [promos, setPromos] = useState([])
    const [promosHistory, setPromosHistory] = useState([])

    async function getPromoHoreca() {
        var outlet = JSON.parse(localStorage.getItem("outlet"))
        await fetch(url + "homehoreca/promo/" + outlet.id, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setPromos([])
                json['data'].map(function(d, index) {
                    var data = {
                        title: d.title, 
                        time: "Berlaku hingga " + moment(d.start_program).format("DD MMM yyyy"), 
                        image: d.image,
                        content: d.content
                    }
                    setPromos(arr => [...arr, data])
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    async function getPromoHorecaInactive() {
        var outlet = JSON.parse(localStorage.getItem("outlet"))
        await fetch(url + "horeca/inactivepromo/" + outlet.id, {headers: headerNoUrlEncodedBearer})
            .then(response => {
                return getJsonResponse(response)
            })
            .then(json => {
                setPromosHistory([])
                json['data'].map(function(d, index) {
                    var data = {
                        title: d.title, 
                        time: "Berlaku hingga " + moment(d.start_program).format("DD MMM yyyy"), 
                        image: d.image,
                        content: d.content
                    }
                    setPromosHistory(arr => [...arr, data])
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        getPromoHoreca()
        getPromoHorecaInactive()
    }, [])

    return (
        <div className="gg-parent-container">
            <NavbarDetail
                title="MANAJEMEN PROMO"
                shadow={false}/>

            <Tabs defaultActiveKey="1">
                <TabPane tab="Promo Aktif" key="1">
                    <div className="gg-tab-content-container">
                        {
                            promos && 
                            promos.map(function(data, i){
                                return <PromoRow 
                                        data={data} 
                                        isViewOnly={false}
                                        type={TYPE_EDIT}/>
                            })
                        }
                    </div>
                </TabPane>
                <TabPane tab="Pending" key="2">
                    <div className="gg-tab-content-container">
                        {
                            promosHistory && 
                            promosHistory.map(function(data, i){
                                return <PromoRow data={data} 
                                        isViewOnly={false}
                                        pending={true}
                                        type={TYPE_EDIT}/>
                            })
                        }
                    </div>
                </TabPane>
            </Tabs>
            
            <FaPlus
                className="gg-floating-add-button"
                onClick={() => history.push("/promo-create")}/>

        </div>
    )
}

export default PromoManagement
