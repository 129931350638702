import React from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import './previous-button.css'

const PreviousButton = ( { onClick, active } ) => {
    return (
        <div className="previousButtonContainer" onClick={() => onClick(1)}>
            <FaChevronLeft className={active && "previousButtonIcon" || "previousButtonIconNotActive"}/>
            <p className={active && "previousButtonText" || "previousButtonTextNotActive"}>Kembali</p>
        </div>
    )
}

export default PreviousButton

