import React, { useEffect, useState } from 'react'
import NavHome from '../../components/navhome/NavHome'
import '@brainhubeu/react-carousel/lib/style.css'
import './home.css'
import BottomMenu from '../../components/bottommenu/BottomMenu'
import 'antd/dist/antd.css'
import { Carousel, Spin } from 'antd'
import HomeMerchantMenu from '../../components/homemerchantmenu/HomeMerchantMenu'
import HomeVideo from '../../components/homevideo/HomeVideo'
import HomeArticle from '../../components/homearticle/HomeArticle'
import { getJsonResponse, headerBearer, headerNoUrlEncodedBearer, url } from '../../service/Service'
import HomeMerchant from './HomeMerchant'
import ProductWarning from '../../components/productwarning/ProductWarning'

const HomeUser = () => {

    const [news, setNews] = useState([])
    const [banners, setBanners] = useState([])
    const [videoGallery, setVideoGallery] = useState([])
    const [notificationCount, setNotificationCount] = useState(0)

    function onChange(a, b, c) {
        console.log(a, b, c);
    }

    async function getNotificationCounter() {
        fetch(url + "user/notification/unread/counter", {headers: headerBearer})
        .then(response => getJsonResponse(response))
        .then(json => setNotificationCount(json['data']))
        .catch(error => console.log(error))
    }

    async function getNews() {
        fetch(url + "News", {headers: headerBearer})
        .then(response => getJsonResponse(response))
        .then(json => setNews(json['data']))
        .catch(error => console.log(error))
    }

    async function getBanners() {
        fetch(url + "banners", {headers: headerBearer})
        .then(response => getJsonResponse(response))
        .then(json => {
            setBanners(json['data'])
        })
        .catch(error => {
            console.log(error)
        })
    }

    async function getVideoGallery() {
        await fetch(url + "galleryvideogg", {headers: headerNoUrlEncodedBearer})
            .then(response => getJsonResponse(response))
            .then(json => {
                setVideoGallery(json['data'])
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        // getNews()
        getBanners()
        getNotificationCounter()
        getVideoGallery()
    }, []);

    return (
        <>
            <div className="parent">
                    <NavHome
                        notificationCount={notificationCount}/>
                    <div className="homeContainer">
                        <div className="homeCorouselContainer">
                            <Carousel afterChange={onChange} autoplay autoplaySpeed={3000}>
                                {
                                    banners.map(function(banner, i){
                                        return  <div key={banner.id} onClick={() => window.open(banner.url)}>
                                                    <img className="homeCorouselItem" src={banner.image}/>
                                                </div>
                                    })
                                }
                            </Carousel>
                        </div>
                        <HomeMerchantMenu/>
                        {
                            videoGallery && videoGallery.length > 0 && <HomeVideo data={videoGallery}/>
                        }
                        {
                            news && news.length > 0 && <HomeArticle datas={news}/> 
                        }
                    </div>
                    <BottomMenu/>
                </div>
        </>
    )
}

export default HomeUser
