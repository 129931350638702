import React from 'react'
import { FaStore } from 'react-icons/fa'
import { ImSpoonKnife } from 'react-icons/im'
import './home-merchant-menu.css'
import { Link } from 'react-router-dom'

const HomeMerchantMenu = () => {
    return (
        <div className="home-merchant-menu-container">
            <p className="home-merchant-menu-title">Cari Merchant</p>
            <div className="home-merchant-menu-content-container">
                <Link className="home-merchant-menu-item-container" to="/grocery-store-list">
                    <FaStore
                        className="home-merchant-menu-icon"/>
                    <p className="home-merchant-menu-text">
                        Warung/Toko
                    </p>
                </Link>
                <div className="home-merchant-menu-item-separator"/>
                <Link className="home-merchant-menu-item-container" to="/restaurant-list/view">
                    <ImSpoonKnife
                        className="home-merchant-menu-icon"/>
                    <p className="home-merchant-menu-text">
                        Restoran/Cafe
                    </p>
                </Link>
            </div>
        </div>
    )
}

export default HomeMerchantMenu
