import React from 'react'

const EmptyPage = ({message, subMessage}) => {
    return (
        <div className="gg-content-empty-container">
            <p className="gg-empty-text">{message}</p>
            {
                subMessage &&
                <p className="gg-empty-sub-text">{subMessage}</p>
            }
        </div>
    )
}

EmptyPage.defaultProps = {
    message: "Data tidak ditemukan"
}

export default EmptyPage
